<template>
  <div class="container-listado-socios">
    <!-- <BaseLoader
      v-if="cargando"
    /> -->
    <div class="contenedor_filtro">
      <div class="contenedor_filto_item">
        <h3>Perfil</h3>
        <div
          v-for="perfil in perfiles"
          :key="'perfil_'+perfil.perfil_id"
        >
          <input
            type="checkbox"
            :value="perfil.perfil_id"
            :id="'perfil_'+perfil.perfil_id"
            v-model="selected.perfil_id"
            @change="traerSociosPorFiltro()"
          >
          <label :for="'perfil_'+perfil.perfil_id">{{ perfil.nombre }}</label>
        </div>
      </div>
      <div class="contenedor_filto_item">
        <h3>Certificación</h3>
        <div
          v-for="certificacion in certificaciones"
          :key="'certificacion_'+certificacion.certificacion_id"
        >
          <input
            type="checkbox"
            :value="certificacion.certificacion_id"
            :id="'certificacion_'+certificacion.certificacion_id"
            v-model="selected.certificacion_id"
            @change="traerSociosPorFiltro()"
          >
          <label :for="'certificacion_'+certificacion.certificacion_id">{{ certificacion.nombre }}</label>
        </div>
      </div>
      <!-- <div class="contenedor_filto_item">
        <h3>Tipo</h3>
        <div
          v-for="tipo in tipos"
          :key="'tipo_'+tipo.tipo_id"
        >
          <input
            type="checkbox"
            :value="tipo.tipo_id"
            :id="'tipo_'+tipo.tipo_id"
            v-model="selected.tipo_id"
            @change="traerSociosPorFiltro()"
          >
          <label :for="'tipo_'+tipo.tipo_id">{{ tipo.nombre }}</label>
        </div>
      </div> -->
      <div class="contenedor_filto_item">
        <h3>Estado</h3>
        <div
         v-for="estado in estados"
         :key="'estado_'+estado.socio_id"
        >
          <input
            type="checkbox"
            :value="estado.socio_id"
            :id="'estado_'+estado.socio_id"
            v-model="selected.socio_id"
            @change="traerSociosPorFiltro()"
          >
          <label :for="'estado_'+estado.socio_id">{{ estado.nombre }}</label>
        </div>
      </div>
      <div class="contenedor_filto_item">
        <h3>Activo en Web</h3>
        <div
          v-for="estado in web"
          :key="'web_'+estado.web_id"
        >
          <input
            type="checkbox"
            :value="estado.web_id"
            :id="'web_'+estado.web_id"
            v-model="selected.web_id"
            @change="traerSociosPorFiltro()"
          >
          <label :for="'web_'+estado.web_id">{{ estado.nombre }}</label>
        </div>
      </div>
      <div class="contenedor_filto_item">
        <h3>Condición</h3>
        <div
          v-for="condicion in condiciones"
          :key="'condicion_'+condicion.condicion_id"
        >
          <input
            type="checkbox"
            :value="condicion.condicion_id"
            :id="'condicion_'+condicion.condicion_id"
            v-model="selected.condicion_id"
            @change="traerSociosPorFiltro()"
          >
          <label :for="'condicion_'+condicion.condicion_id">{{ condicion.nombre }}</label>
        </div>
      </div>
      <div class="contenedor_filto_item">
        <h3>Estudio de Yoga</h3>
        <div>
          <input
            type="checkbox"
            value="0"
            id="estudio_nombre_vacio"
            v-model="selected.estudio_nombre_1"
            @change="traerSociosPorFiltro()"
          >
          <label for="estudio_nombre_vacio">Sin Nombre</label>
        </div>
        <div>
          <input
            type="checkbox"
            value="1"
            id="estudio_nombre_lleno"
            v-model="selected.estudio_nombre_1"
            @change="traerSociosPorFiltro()"
          >
          <label for="estudio_nombre_lleno">Con Nombre</label>
        </div>
      </div>
      <div class="contenedor_filto_item">
        <h3>DNI</h3>
        <div>
          <input
            type="checkbox"
            value="0"
            id="dni_vacio"
            v-model="selected.dni"
            @change="traerSociosPorFiltro()"
          >
          <label for="dni_vacio">Sin DNI</label>
        </div>
        <div>
          <input
            type="checkbox"
            value="1"
            id="dni_lleno"
            v-model="selected.dni"
            @change="traerSociosPorFiltro()"
          >
          <label for="dni_lleno">Con DNI</label>
        </div>
      </div>
      <div class="contenedor_filto_item">
        <h3>Domicilio</h3>
        <div>
          <input
            type="checkbox"
            value="0"
            id="domicilio_vacio"
            v-model="selected.provincia_id_1"
            @change="traerSociosPorFiltro()"
          >
          <label for="domicilio_vacio">Sin Domicilio</label>
        </div>
        <div>
          <input
            type="checkbox"
            value="1"
            id="domicilio_lleno"
            v-model="selected.provincia_id_1"
            @change="traerSociosPorFiltro()"
          >
          <label for="domicilio_lleno">Con Domicilio</label>
        </div>
      </div>
      <div class="contenedor_filto_item">
        <h3>¿Toma clases?</h3>
        <div>
          <input
            type="checkbox"
            value="0"
            id="toma_clase_vacio"
            v-model="selected.toma_clase"
            @change="traerSociosPorFiltro()"
          >
          <label for="toma_clase_vacio">No</label>
        </div>
        <div>
          <input
            type="checkbox"
            value="1"
            id="toma_clase_lleno"
            v-model="selected.toma_clase"
            @change="traerSociosPorFiltro()"
          >
          <label for="toma_clase_lleno">Si</label>
        </div>
      </div>
    </div>
    <div class="contenedor-buscador-tabla-botonera">
      <div class="contenedor_buscador">
        <input type="text" placeholder="Buscar por Nombre, Apellido o DNI" v-model="busqueda" @keyup="buscador(busqueda)" class="buscador" />
      </div>
      <p v-if="this.socios.length == 0" class="no-resultado">No se encontraron resultados</p>
      <div v-else>
        <BaseLoader
          v-if="cargando"
        />
        <div v-else>
          <div class="contenedor_socios_lista" >
            <button
              class="boton_exportar"
              @click="exportData"
            >
              Exportar a Excel
            </button>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_5">Perfil</th>
                <th class="column_5">Certificación</th>
                <!-- <th>Fecha de Ingreso</th>
                <th>Tipo</th> -->
                <th class="column_5">DNI</th>
                <!-- <th class="column_2">Email</th> -->
                <th class="column_1">Celular</th>
                <th class="column_2">Domicilio</th>
                <th class="column_1">Toma clases con</th>
                <th class="column_4">Estado</th>
                <th class="column_1">Estudio</th>
                <!-- <th class="column_3">En Mentoría</th> -->
                <th class="column_4"></th>
              </thead>
              <tbody>
                <SociosListaItem
                  v-for="socio in sociosPaginado"
                  :socio="socio"
                  :key="socio.persona_id"
                ></SociosListaItem>
              </tbody>
            </table>
          </div>
          <!-- <div class="contenedor_paginado" v-if="this.socios.length > 20">
            <button
              :disabled="currentPage === 1"
              @click="prevPage"
              :class="{ disabled: currentPage === 1 }"
              class="boton_paginado boton_paginado_anterior"
            >
              Anterior
            </button>
            <div class="contenedor_botones_paginado">
              <button
                v-for="pageNumber in pageCount"
                :key="pageNumber"
                :class="{ pagina_activa: currentPage === pageNumber }"
                class="boton_paginado_numerico"
                @click="goToPage(pageNumber)"
              >
                {{ pageNumber }}
              </button>
            </div>
            <button
              :disabled="currentPage === pageCount"
              :class="{ disabled: currentPage === pageCount }"
              @click="nextPage"
              class="boton_paginado boton_paginado_siguiente"
            >
              Siguiente
            </button>
          </div> -->
          <div class="contenedor_paginado" v-if="this.socios.length > 20">
            <button
              @click="firstPage"
              class="boton_paginado_numerico salto_fin_principio"
            >
              <span>&#8739;</span>
              <span>&#60;</span>
            </button>
            <button
              :disabled="currentPage === 1"
              @click="prevPage"
              :class="{ disabled: currentPage === 1 }"
              class="boton_paginado_numerico boton_paginado_anterior"
            >
              <span>&#60;</span>
            </button>
            <button
              v-for="pageNumber in bulletsPaginado"
              :key="pageNumber"
              :class="{ pagina_activa: currentPage === pageNumber }"
              class="boton_paginado_numerico"
              @click="goToPage(pageNumber)"
            >
              {{ pageNumber }}
            </button>
            <div
              v-if="this.mostrarLastPageBullets"
              class="paginado_contenedor_puntitos"
            >
              <span> .... </span>
              <button
                class="boton_paginado_numerico"
                @click="goToPage(pageCount)"
              >
                {{ pageCount }}
              </button>
            </div>
            <button
              :disabled="currentPage === pageCount"
              :class="{ disabled: currentPage === pageCount }"
              @click="nextPage"
              class="boton_paginado_numerico boton_paginado_siguiente"
            >
              <span>&#62;</span>
            </button>
            <button
              @click="lastPage"
              class="boton_paginado_numerico boton_paginado_anterior"
            >
              <span>&#62;</span>
              <span>&#8739;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SociosListaItem from '../components/SociosListaItem.vue'
import asociadoService from '../services/asociado'
import personasService from '../services/personas'
import BaseLoader from '../components/BaseLoader.vue'
const XLSX = require('xlsx')
export default {
  name: 'SociosLista',
  components: {
    SociosListaItem,
    BaseLoader
  },
  data: function () {
    return {
      selected: {
        perfil_id: [],
        certificacion_id: [],
        socio_id: [],
        condicion_id: [],
        tipo_id: [],
        web_id: [],
        estudio_nombre_1: [],
        dni: [],
        provincia_id_1: [],
        toma_clase: []
      },
      archivo: [],
      socios: ['q'],
      perfiles: [],
      certificaciones: [],
      estados: [],
      condiciones: [],
      tipos: [],
      web: [],
      paginado: [],
      mostrarLastPageBullets: true,
      busqueda: null,
      cargando: false,
      pageNumber: 1,
      currentPage: 1,
      pageCount: 0,
      listData: {
        type: Array,
        required: true
      },
      sizePaginado: 20,
      sizeBulletPaginado: 5
    }
  },
  methods: {
    exportData () {
      const data = this.archivo
      // const data = [{ foo: 'foo' }, { bar: 'bar' }]
      // const fileName = 'Listado_de_Pagos'
      // const exportType = 'csv'
      // exportFromJSON({ data, fileName, exportType })
      // exportFromJSON({ data, exportType })
      const workSheet = XLSX.utils.json_to_sheet(data)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Listado_de_Socios')
      XLSX.writeFile(workBook, 'Listado_de_Socios.xlsx')
    },
    traerSociosPorFiltro () {
      console.log('ewerwerewrew')
      console.log(this.selected)
      this.busqueda = null
      this.cargando = true
      personasService
        .traerSociosPorFiltro(JSON.stringify(this.selected))
        .then(respuesta => {
          this.cargando = false
          this.currentPage = 1
          this.socios = []
          // this.socios = respuesta
          console.log('INFO POR FILTRO', respuesta)
          this.socios = respuesta
          this.pageCount = Math.ceil(this.socios.length / this.sizePaginado)
          this.exportarExcel(respuesta)
        })
    },
    nextPage () {
      this.currentPage++
      console.log(this.currentPage)
      this.sosUltimaPagina()
      // this.sociosPaginado()
    },
    prevPage () {
      this.currentPage--
      console.log(this.currentPage)
      this.sosUltimaPagina()
      // this.sociosPaginado()
    },
    goToPage (page) {
      this.currentPage = page
      console.log(page)
      this.sosUltimaPagina()
    },
    firstPage () {
      this.currentPage = 1
      this.sosUltimaPagina()
    },
    lastPage () {
      this.currentPage = this.pageCount
      this.sosUltimaPagina()
    },
    sosUltimaPagina () {
      console.log('2222222')
      const start = Math.floor((this.currentPage - 1) / this.sizeBulletPaginado) * this.sizeBulletPaginado
      // const end = start + this.sizeBulletPaginado
      if (this.pageCount - start < this.sizeBulletPaginado) {
        this.mostrarLastPageBullets = false
      } else {
        this.mostrarLastPageBullets = true
      }
    },
    exportarExcel (data) {
      console.log('dsdsddddddd')
      this.archivo = []
      for (var j = 0; j < data.length; j++) {
        const obj = {}
        // obj.Nombre = this.fechaFormato(this.socios[j].nombre)
        // obj.Apellido = this.socios[j].persona.nombre + ' ' + this.socios[j].persona.apellido
        // obj.Monto = this.socios[j].precio_total
        obj.Nombre = data[j].nombre
        obj.Apellido = data[j].apellido
        obj.Perfil = data[j].perfil
        obj.Certificacion = data[j].certificacion
        obj.DNI = data[j].dni
        obj.Email = data[j].email
        obj.Celular = data[j].celular
        obj.Domicilio = data[j].direccion
        obj.Direccion_Completa = data[j].direccion_completa
        obj.Fecha_De_Ingreso = data[j].fecha_ingreso
        obj.Toma_Clase = data[j].toma_clase
        obj.Estado = data[j].condicion
        obj.Condicion_Alta = data[j].condicion_alta
        obj.Nombre_Estudio_1 = data[j].estudio_nombre_1
        obj.Nombre_Estudio_2 = data[j].estudio_nombre_2
        obj.Mentorias = this.socios[j].mentorias[0]
        // obj.Concepto = this.socios[j].concepto.nombre + ' ' + this.socios[j].years[0].year
        // obj.Descripción = this.socios[j].descripcion
        this.archivo.push(obj)
      }
    },
    buscador (busqueda) {
      this.cargando = true
      this.selected.perfil_id = []
      this.selected.certificacion_id = []
      this.selected.socio_id = []
      this.selected.tipo_id = []
      this.selected.web_id = []
      this.selected.estudio_nombre_1 = []
      this.selected.dni = []
      this.selected.provincia_id_1 = []
      this.selected.toma_clase = []
      if (busqueda === '') {
        asociadoService
          .traerSociosTodos()
          .then(respuesta => {
            this.cargando = false
            this.currentPage = 1
            this.socios = []
            this.socios = respuesta
            console.log('Todas los socios por buscador', respuesta)
            console.log(this.socios)
            this.pageCount = Math.ceil(this.socios.length / this.sizePaginado)
            console.log(this.pageCount)
            this.exportarExcel(respuesta)
          })
        return
      }
      asociadoService
        .traerSociosPorBuscador(busqueda)
        .then(respuesta => {
          this.cargando = false
          this.currentPage = 1
          this.socios = []
          this.socios = respuesta
          console.log('Todas los socios 2222', respuesta)
          console.log(this.socios)
          console.log(this.socios.length)
          this.pageCount = Math.ceil(this.socios.length / this.sizePaginado)
          console.log(this.pageCount)
          this.exportarExcel(respuesta)
        })
    }
  },
  computed: {
    sociosPaginado: function () {
      const start = (this.currentPage - 1) * this.sizePaginado
      const end = start + this.sizePaginado
      return this.socios.slice(start, end)
    },
    bulletsPaginado: function () {
      const start = Math.floor((this.currentPage - 1) / this.sizeBulletPaginado) * this.sizeBulletPaginado
      const end = start + this.sizeBulletPaginado
      if (this.pageCount - start < this.sizeBulletPaginado) {
        return this.paginado.slice(this.pageCount - this.sizeBulletPaginado, this.pageCount)
      } else {
        return this.paginado.slice(start, end)
      }
    }
  },
  mounted: function () {
    this.cargando = true
    asociadoService
      .traerSociosTodos()
      .then(respuesta => {
        this.cargando = false
        console.log('Socios Todos', respuesta)
        this.socios = respuesta
        this.pageCount = Math.ceil(this.socios.length / this.sizePaginado)
        this.exportarExcel(respuesta)
        for (var i = 1; i <= this.pageCount; i++) {
          this.paginado.push(i)
        }
        // for (var j = 0; j < respuesta.length; j++) {
        //   const obj = {}
        //   // obj.Nombre = this.fechaFormato(this.socios[j].nombre)
        //   // obj.Apellido = this.socios[j].persona.nombre + ' ' + this.socios[j].persona.apellido
        //   // obj.Monto = this.socios[j].precio_total
        //   obj.Nombre = this.socios[j].nombre
        //   obj.Apellido = this.socios[j].apellido
        //   obj.Perfil = this.socios[j].perfil
        //   obj.Certificacion = this.socios[j].certificacion
        //   obj.DNI = this.socios[j].dni
        //   obj.Email = this.socios[j].email
        //   obj.Celular = this.socios[j].celular
        //   obj.Domicilio = this.socios[j].direccion
        //   obj.Fecha_De_Ingreso = this.socios[j].fecha_ingreso
        //   obj.toma_clase = this.socios[j].toma_clase
        //   obj.Condicion = this.socios[j].condicion
        //   obj.estudio_nombre_1 = this.socios[j].estudio_nombre_1
        //   obj.estudio_nombre_2 = this.socios[j].estudio_nombre_2
        //   // obj.Mentorias = this.socios[j].mentorias
        //   // obj.Concepto = this.socios[j].concepto.nombre + ' ' + this.socios[j].years[0].year
        //   // obj.Descripción = this.socios[j].descripcion
        //   this.archivo.push(obj)
        // }
      })
    personasService
      .traerPerfilesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.perfiles = respuesta
      })
    personasService
      .traerCertificacionesTodas()
      .then(respuesta => {
        // this.cargando = false
        this.certificaciones = respuesta
      })
    personasService
      .traerEstadosTodos()
      .then(respuesta => {
        // this.cargando = false
        this.estados = respuesta
      })
    personasService
      .traerTiposTodos()
      .then(respuesta => {
        // this.cargando = false
        this.tipos = respuesta
      })
    personasService
      .traerEstadosWebTodos()
      .then(respuesta => {
        // this.cargando = false
        this.web = respuesta
      })
    personasService
      .traerCondicionesTodas()
      .then(respuesta => {
        // this.cargando = false
        this.condiciones = respuesta
      })
  }
}
</script>

<style>
.buscador {
/*  width: 500px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;*/
  background-image: url('../assets/lupa.png');
/*  background-repeat: no-repeat;
  background-position-y: center;
  text-indent: 40px;
  outline: none;
  margin: 20px 0;
  padding: 12px;*/
}

.buscador:focus {
/*  border-radius: 4px;
  border: 1px solid rgb(126,220,217);*/
  background-image: url('../assets/lupa_focus.png');
}
</style>
