<template>
  <div class="container">
    <p class="titulo-lista-carrito">Total Facturado: ${{ dotsCadaTres(parseFloat(this.totalFacturadoCarrito).toFixed(2).replace(/\.00$/, '')) }}</p>
    <p class="titulo-lista-carrito">Cantidad de productos vendidos: {{ this.cantidadProductosVendidos}}</p>
    <button
      class="boton_exportar"
      @click="exportData"
    >
      Exportar a Excel
    </button>
    <table id="tabla-stock">
      <thead>
        <!-- <th class="nofuente">IMAGEN</th> -->
        <th rowspan="2">Producto</th>
        <th rowspan="2">Cantidad vendidos</th>
        <th rowspan="2">Monto Facturado x Producto</th>
        <th colspan="4">Desglosado x Producto</th>
        <!-- <th>Condición</th> -->
        <!-- <th class="nofuente gradient_2">Entrega</th> -->
      </thead>
      <tbody>
        <tr>
          <td class="no-cell-info"></td>
          <td class="no-cell-info"></td>
          <td class="no-cell-info"></td>
          <td class="no-cell-info">Cantidad Descuento</td>
          <td class="no-cell-info">Facturado con Descuento</td>
          <td class="no-cell-info">Cantidad NO Descuento</td>
          <td class="no-cell-info">Facturado SIN Descuento</td>
        </tr>
        <VentasListaCarritoItem
          v-for="venta in ventas"
          :venta="venta"
          :key="venta.venta_id"
        ></VentasListaCarritoItem>
      </tbody>
    </table>
    <table id="tabla-stock">
      <thead>
        <th>Producto</th>
        <th>Stock</th>
      </thead>
      <tbody>
        <ProductoListaItem
          v-for="producto in productos"
          :producto="producto"
          :key="producto.producto_id"
        ></ProductoListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import VentasListaCarritoItem from '../components/VentasListaCarritoItem.vue'
import ProductoListaItem from '../components/ProductoListaItem.vue'
import ventasService from '../services/ventas'
import productosService from '../services/productos'
const XLSX = require('xlsx')
export default {
  name: 'VentasListaCarrito',
  components: {
    VentasListaCarritoItem,
    ProductoListaItem
  },
  data: function () {
    return {
      ventas: [],
      productos: [],
      archivo: [],
      cantidadProductosVendidos: null,
      totalFacturadoCarrito: null
    }
  },
  methods: {
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    exportData () {
      console.log(this.pagos)
      const data = this.archivo
      // const data = [{ foo: 'foo' }, { bar: 'bar' }]
      // const fileName = 'Listado_de_Pagos'
      // const exportType = 'csv'
      // exportFromJSON({ data, fileName, exportType })
      // exportFromJSON({ data, exportType })
      const workSheet = XLSX.utils.json_to_sheet(data)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Ventas Carrito')
      XLSX.writeFile(workBook, 'Ventas_Carrito.xlsx')
    },
    exportarExcel (data) {
      this.archivo = []
      for (var j = 0; j < data.length; j++) {
        const obj = {}
        obj.Producto = data[j].nombre
        obj.Cantidad_Productos_Vendidos = data[j].cantidad
        obj.Total_Facturado = data[j].precio_total
        obj.Cantidad_Productos_Con_Descuento = data[j].cantidad_socio
        obj.Facturado_Productos_Con_Descuento = data[j].facturado_socio
        obj.Cantidad_Productos_Sin_Descuento = data[j].cantidad_no_socio
        obj.Facturado_Productos_Sin_Descuento = data[j].facturado_no_socio
        this.archivo.push(obj)
      }
    }
  },
  mounted: function () {
    ventasService
      .traerProductosVendidos()
      .then(respuesta => {
        // this.cargando = false
        this.cantidadProductosVendidos = respuesta.cantidadProductosVendidos
        this.totalFacturadoCarrito = respuesta.totalFacturadoCarrito
        this.ventas = respuesta.descripcionVentas
        console.log('Estadistica Carrito: ', respuesta.cantidadProductosVendidos)
        this.exportarExcel(respuesta.descripcionVentas)
      })
    // ventasService
    //   .cantidadProductosVendidos()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.cantidadProductosVendidos = respuesta
    //     console.log('cantidadProductosVendidos: ', respuesta)
    //   })
    // ventasService
    //   .totalFacturadoCarrito()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.totalFacturadoCarrito = respuesta
    //     console.log('totalFacturadoCarrito: ', respuesta)
    //   })
    productosService
      .traerProductosTodos()
      .then(respuesta => {
        // this.cargando = false
        this.productos = respuesta
        console.log('Todos los productos', respuesta)
      })
  }
}
</script>

<style>

</style>
