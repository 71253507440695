<template>
  <div>
    <h1>Cargar Entrega</h1>
    <div>
      <form
        action="#"
        method="post"
        @submit.prevent="crearEntrega(entrega)"
        class="form_presupuesto_nuevo"
      >
        <div class="form_section">
          <label for="fecha_de_entrega">Fecha del entrega</label>
          <input
            type="date"
            name="fecha_de_entrega"
            id="fecha_de_entrega"
            v-model="entrega.fecha_de_entrega"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.fecha_de_entrega !== null"
          >
            {{ errores.fecha_de_entrega[0] }}
          </div>
        </div>
        <div class="contenor_producto_cantidad_persona" v-for="index in count" :key="index">
          <div class="form_section">
            <label :for="`servicio-${index}`">Producto {{ index }}</label>
            <select
              id="servicio_id"
              :name="`servicio-${index}`"
              class="form minimal"
              v-model.number="entrega.productos[ index  - 1].servicio_id"
              :disabled="cargando"
              @change="onChange($event, index)"
            >
              <option
                v-for="servicio in servicios"
                :value="servicio.servicio_id"
                :key="servicio.servicio_id"
              >
                {{ servicio.nombre }}
              </option>
            </select>
            <div
            class="form_mensaje_error"
              v-if="errores.servicio_id !== null"
            >
              {{ errores.servicio_id[0] }}
            </div>
          </div>
          <div class="form_section">
            <label for="cantidad">Cantidad</label>
            <input
              type="text"
              name="cantidad"
              id="cantidad"
              v-model="entrega.productos[index  - 1].cantidad"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.cantidad !== null"
            >
              {{ errores.cantidad[0] }}
            </div>
          </div>
          <div class="form_section">
            <label for="persona_id">Persona</label>
            <select
              id="persona_id"
              name="persona_id"
              class="form minimal"
              v-model.number="entrega.productos[index  - 1].persona_id"
              :disabled="cargando2"
            >
              <option value="" disabled v-if="!Array.isArray(personas[index - 1])">{{ defaultValue.default }}</option>
              <option
                v-for="entrega in personas[index - 1]"
                :value="entrega.persona_id"
                :key="entrega.persona_id"
              >
                {{ entrega.persona.nombre }} {{ entrega.persona.apellido }}
              </option>
            </select>
            <div
            class="form_mensaje_error"
              v-if="errores.persona_id !== null"
            >
              {{ errores.persona_id[0] }}
            </div>
          </div>
        </div>
        <div @click="addComponent" class="boton-agregar-producto">Agregar Producto</div>
        <div class="form_section">
          <label for="descripcion">Descripción</label>
          <textarea
            name="descripcion"
            id="descripcion"
            v-model.number="entrega.descripcion"
          ></textarea>
        </div>
        <button type="submit" class="boton_guardar">Cargar ENTREGA</button>
      </form>
    </div>
  </div>
</template>

<script>
import pagosService from '../services/pagos'
import serviciosService from '../services/servicios'
import entregasService from '../services/entregas'
// import Menu from '../components/Menu.vue'
// import Footer from '../components/Footer.vue'

export default {
  name: 'EntregaForm',
  components: {
  },
  data: function () {
    return {
      count: 1,
      conceptos: [],
      years: [],
      formasDePago: [],
      personas: [],
      defaultValue: {
        type: String,
        default: 'Primero selecciona el producto'
      },
      servicios: [],
      cargando: false,
      cargando2: false,
      // show: true,
      // seleccionado: 5,
      entrega: {
        fecha_de_entrega: null,
        productos: [
          {
            persona_id: null,
            cantidad: null,
            servicio_id: null
          }
        ],
        descripcion: null,
        venta_id: this.$route.params.id
      },
      errores: {
        fecha_de_entrega: null,
        persona_id: null,
        cantidad: null,
        servicio_id: null,
        descripcion: null
      }
    }
  },
  methods: {
    addComponent () {
      this.count += 1
      this.entrega.productos.push({
        persona_id: null,
        cantidad: null,
        servicio_id: null
      })
      // this.personas.persona1.push({
      //   pepe: []
      // })
    },
    onChange (event, numero) {
      // console.log(numero)

      console.log(event.target.value)

      // Tengo que traer las personas que tienen el producto elegido
      // y meterlas en el SELECT

      this.cargando2 = false

      entregasService
        .traerPersonasPorEntrega(event.target.value)
        .then(respuesta => {
          // this.cargando = false
          this.personas[numero - 1] = respuesta
          console.log('Personas: ', respuesta)
          this.entrega.productos[numero - 1].persona_id = respuesta[0].persona_id
        })

      console.log('eeee')
      // console.log(document.getElementsByName('precio-' + numero)[0].value)

      // console.log(this.productos[event.target.value - 1].precio)
      // // document.getElementsByName('precio-' + numero)[0].value = this.productos[event.target.value - 1].precio
      // this.servicio.productos[numero - 1].precio = this.productos[event.target.value - 1].precio
    },
    crearEntrega (entrega) {
      console.log('qqqq')
      console.log(entrega)
      if (this.cargando) {
        return null
      }
      this.errores = {
        fecha_de_entrega: null,
        persona_id: null,
        cantidad: null,
        servicio_id: null,
        descripcion: null
      }
      this.cargando = true
      entregasService
        .crearEntrega(entrega)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            // this.pago = {
            //   precio_total: null,
            //   fecha_de_pago: null,
            //   concepto_id: null,
            //   forma_de_pago_id: null,
            //   persona_id: null,
            //   year_id: null,
            //   moneda_id: null,
            //   descripcion: null
            // }
            // this.errores = {
            //   nombre: null,
            //   precio_total: null,
            //   fecha_de_pago: null,
            //   year_id: null,
            //   concepto_id: null,
            //   forma_de_pago_id: null,
            //   persona_id: null,
            //   moneda_id: null,
            //   descripcion: null
            // }
            // this.$router.push('/presupuesto')
          } else {
            // this.errores = {
            //   nombre: null,
            //   precio_total: null,
            //   fecha_de_pago: null,
            //   year_id: null,
            //   concepto_id: null,
            //   forma_de_pago_id: null,
            //   persona_id: null,
            //   moneda_id: null,
            //   descripcion: null,
            //   ...respuesta.errors
            // }
          }
        })
    }
  },
  mounted: function () {
    pagosService
      .traerConceptosDePago()
      .then(respuesta => {
        // this.cargando = false
        this.conceptos = respuesta
      })
    pagosService
      .traerYearsDePago()
      .then(respuesta => {
        // this.cargando = false
        this.years = respuesta
      })
    pagosService
      .traerFormasDePago()
      .then(respuesta => {
        // this.cargando = false
        this.formasDePago = respuesta
      })
    serviciosService
      .traerProductosTodos(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.servicios = respuesta
      })
    // pagosService
    //   .traerPersonas()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.personas = respuesta
    //     console.log('Personas: ', respuesta)
    //   })
  },
  computed: {
    // mostrarCampoYear: function () {
    //   // if (this.pago.concepto_id === 1 ) {
    //   //   return true
    //   // }
    //   // return false
    //   switch (this.pago.concepto_id) {
    //     case 1:
    //       return true
    //     case 2:
    //       return true
    //     case 3:
    //       return true
    //     default:
    //       return false
    //   }
    // }
  }
}
</script>
<style>
.contenor_producto_cantidad_persona {
  display: flex;
  justify-content: center;
}
</style>
