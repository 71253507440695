<template>
  <div class="login_form">
    <h1>GENERAR <span>CONTRASEÑA</span></h1>
    <!-- <router-link :to="`/login`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link> -->
    <form
      action="#"
      @submit.prevent="recuperarPassword()"
    >
      <!-- <BaseNotification
        v-if="status.msg != null"
        :type="status.type"
        :text="status.msg"
        :closable="status.closable"
        @close="borrarNotificacion()"
      /> -->
      <p class="token_texto">Ingresa el email con el que se genero tu usuario de la AAYI y revisa tu casilla de correo.</p>
      <div class="form_section">
        <label for="email">Email<sup>*</sup></label>
        <input
          type="email"
          name="email"
          id="email"
          v-model="credenciales.email"
        ><div
        class="form_mensaje_error"
          v-if="errores.email !== null"
        >
          {{ errores.email[0] }}
        </div>
      </div>
      <button type="submit">Generar Contraseña</button>
    </form>
  </div>
</template>

<script>
// import store from './../store'
import personasService from '../services/personas'
// import BaseNotification from './../components/BaseNotification.vue'

export default {
  name: 'SocioNuevoPassword',
  // components: { BaseNotification },
  props: {},
  data: function () {
    return {
      credenciales: {
        email: null
      },
      // status: {
      //   msg: null,
      //   type: 'success',
      //   closable: true
      // },
      errores: {
        email: null
      }
      // store
    }
  },
  computed: {},
  methods: {
    // borrarNotificacion () {
    //   this.status = {
    //     msg: null,
    //     type: 'success',
    //     closable: true
    //   }
    // },
    recuperarPassword () {
      personasService
        .recuperarPassword(this.credenciales)
        .then(res => {
          // this.borrarNotificacion()
          if (res.errors) {
            this.errores = {
              email: null,
              ...res.errors
            }
          } else if (res.success !== false) {
            this.$router.push('/resetear-password/' + res.user)
          } else {
            // this.status.msg = 'El email ingresado no figura en nuestros registros'
            // this.status.type = 'error'
            // this.errores = {
            //   email: null,
            //   ...res.errores
            // }
          }
        })
    }
  }
}
</script>

<style>
/*.boton_volver_login {
  text-decoration: none;
  font-size: 14px;
  margin-left: 40px;
  position: absolute;
  left: 0;
  top: 0;
  color: #FF9933;
}

.boton_volver_login span {
  font-size: 18px;
}*/
</style>
