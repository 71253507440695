<template>
  <div class="container">
   <!--  <p class="titulo-lista-carrito">Total Facturado: ${{ dotsCadaTres(parseFloat(this.totalFacturadoCarrito).toFixed(2).replace(/\.00$/, '')) }}</p>
    <p class="titulo-lista-carrito">Cantidad de productos vendidos: {{ this.cantidadProductosVendidos}}</p>
    <button
      class="boton_exportar"
      @click="exportData"
    >
      Exportar a Excel
    </button> -->
    <table id="tabla-stock">
      <thead>
        <!-- <th class="nofuente">IMAGEN</th> -->
        <th rowspan="2">Fecha</th>
        <th rowspan="2">Nombre y Apellido</th>
        <th rowspan="2">Email</th>
        <th rowspan="2">Descripción</th>
        <th rowspan="2">Mercado Pago Id</th>
        <th rowspan="2">Monto</th>
        <!-- <th>Condición</th> -->
        <!-- <th class="nofuente gradient_2">Entrega</th> -->
      </thead>
      <tbody>
        <VentasMercadoPagoNoIngresadasItem
          v-for="venta in ventas"
          :venta="venta"
          :key="venta.venta_id"
        ></VentasMercadoPagoNoIngresadasItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import VentasMercadoPagoNoIngresadasItem from '../components/VentasMercadoPagoNoIngresadasItem.vue'
// import ProductoListaItem from '../components/ProductoListaItem.vue'
import ventasService from '../services/ventas'
// import productosService from '../services/productos'
const XLSX = require('xlsx')
export default {
  name: 'VentasMercadoPagoNoIngresadas',
  components: {
    VentasMercadoPagoNoIngresadasItem
  },
  data: function () {
    return {
      ventas: [],
      productos: [],
      archivo: [],
      cantidadProductosVendidos: null,
      totalFacturadoCarrito: null
    }
  },
  methods: {
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    exportData () {
      console.log(this.pagos)
      const data = this.archivo
      // const data = [{ foo: 'foo' }, { bar: 'bar' }]
      // const fileName = 'Listado_de_Pagos'
      // const exportType = 'csv'
      // exportFromJSON({ data, fileName, exportType })
      // exportFromJSON({ data, exportType })
      const workSheet = XLSX.utils.json_to_sheet(data)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Ventas Carrito')
      XLSX.writeFile(workBook, 'Ventas_Carrito.xlsx')
    },
    exportarExcel (data) {
      this.archivo = []
      for (var j = 0; j < data.length; j++) {
        const obj = {}
        obj.Producto = data[j].nombre
        obj.Cantidad_Productos_Vendidos = data[j].cantidad
        obj.Total_Facturado = data[j].precio_total
        obj.Cantidad_Productos_Con_Descuento = data[j].cantidad_socio
        obj.Facturado_Productos_Con_Descuento = data[j].facturado_socio
        obj.Cantidad_Productos_Sin_Descuento = data[j].cantidad_no_socio
        obj.Facturado_Productos_Sin_Descuento = data[j].facturado_no_socio
        this.archivo.push(obj)
      }
    }
  },
  mounted: function () {
    ventasService
      .traerVentasMercadoPago()
      .then(respuesta => {
        // this.cargando = false
        // this.cantidadProductosVendidos = respuesta.cantidadProductosVendidos
        // this.totalFacturadoCarrito = respuesta.totalFacturadoCarrito
        this.ventas = respuesta
        console.log('Estadistica Carrito: ', respuesta)
        // this.exportarExcel(respuesta.descripcionVentas)
      })
    // ventasService
    //   .cantidadProductosVendidos()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.cantidadProductosVendidos = respuesta
    //     console.log('cantidadProductosVendidos: ', respuesta)
    //   })
    // ventasService
    //   .totalFacturadoCarrito()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.totalFacturadoCarrito = respuesta
    //     console.log('totalFacturadoCarrito: ', respuesta)
    //   })
    // productosService
    //   .traerProductosTodos()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.productos = respuesta
    //     console.log('Todos los productos', respuesta)
    //   })
  }
}
</script>

<style>

</style>
