<template>
  <li>{{ entrega.persona_nombre }} {{ entrega.persona_apellido }}<br>Cantidad: {{ entrega.cantidad }} unidades<br>Descripción: {{ entrega.entrega_descripcion }} <br> Producto: {{ entrega.producto_nombre }}</li>
</template>

<script>
// import StockListaItemPersona from '../components/StockListaItemPersona.vue'
// import store from './../store'
// import { PATH_IMG } from '../constants'
// console.log(persona)
export default {
  name: 'VentasListaItemEntregas',
  components: {
  },
  props: ['entrega'],
  data: function () {
    return {
      // store
    }
  }
}
</script>

<style>

</style>
