import { API } from './../constants'
import authService from './auth.js'

let servicios = null
let servicio = null
let categorias = null

const serviciosService = {

  async traerServiciosTodosConStock () {
    const fetchResponse = await fetch(`${API}/servicios-stock`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    servicios = respuesta.data
    return [...servicios]
  },

  async traerServiciosTodosConCuota () {
    const fetchResponse = await fetch(`${API}/servicios-cuotas`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    servicios = respuesta.data
    return [...servicios]
  },

  async traerServiciosTodos () {
    const fetchResponse = await fetch(`${API}/listar-servicios`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    servicios = respuesta.data
    return [...servicios]
  },

  async traerProductosTodos (id) {
    const fetchResponse = await fetch(`${API}/listar-productos/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    servicios = respuesta.data
    return [...servicios]
  },
  async traerServicioConCuotasPorId (id) {
    const fetchResponse = await fetch(`${API}/listar-servicio/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    servicio = respuesta.data
    return [...servicio]
  },
  async listarServicioConCuotasPorId (id) {
    const fetchResponse = await fetch(`${API}/listar-servicio-cuotas-crear-pago/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    servicio = respuesta.data
    return [...servicio]
  },

  async traerServicioConStockPorId (id) {
    const fetchResponse = await fetch(`${API}/listar-stock-servicio/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    servicio = respuesta.data
    return { ...servicio }
  },
  async traerCategoriasTodas () {
    const fetchResponse = await fetch(`${API}/categorias`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    categorias = respuesta.data
    return [...categorias]
  },
  borrarServicio (id) {
    return fetch(`${API}/eliminar-servicio/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(res => {
        return { ...res }
      })
  },
  borrarPersonaConStockPorId (id) {
    return fetch(`${API}/eliminar-persona/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(res => {
        return { ...res }
      })
  },
  editarServicio (data, id) {
    return fetch(`${API}/editar-servicio/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  agregarPersona (data) {
    return fetch(`${API}/agregar-persona`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  crearServicio (data) {
    return fetch(`${API}/crear-servicio`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  }

}

export default serviciosService
