import { API } from './../constants'
import authService from './auth.js'

let paises = null
let paisId = null

const paisesService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerPaisesTodos () {
    const fetchResponse = await fetch(`${API}/paises`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    paises = respuesta.data
    return [...paises]
  },
  async traerPaisId (id) {
    const fetchResponse = await fetch(`${API}/pais-id/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    paisId = respuesta.data
    return { ...paisId }
  }
}

export default paisesService
