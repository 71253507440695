<template>
  <div class="container">
    <table id="tabla-stock">
      <thead>
        <!-- <th class="nofuente">IMAGEN</th> -->
        <th>Fecha de Venta</th>
        <th>Descripción</th>
        <th>Productos</th>
        <!-- <th>Certificación</th> -->
        <!-- <th>Condición</th> -->
        <th class="nofuente gradient_2">Entrega</th>
      </thead>
      <tbody>
        <VentasListaItem
          v-for="venta in ventas"
          :venta="venta"
          :key="venta.venta_id"
        ></VentasListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import VentasListaItem from '../components/VentasListaItem.vue'
// import Header2 from '../components/Header2.vue'
import ventasService from '../services/ventas'
export default {
  name: 'VentasLista',
  components: {
    VentasListaItem
    // Header2
  },
  data: function () {
    return {
      ventas: []
    }
  },
  methods: {
    refrescarProfesores () {
      // comerciosService
      //   .traerComerciosTodos()
      //   .then(respuesta => {
      //     this.cargando = false
      //     this.comercios = respuesta
      //     console.log('Todas los comercios', respuesta)
      //   })
    }
  },
  mounted: function () {
    // this.cargando = true
    ventasService
      .traerVentasTodas()
      .then(respuesta => {
        // this.cargando = false
        this.ventas = respuesta
        console.log('Todas los ventas', respuesta)
      })
  }
}
</script>

<style>

</style>
