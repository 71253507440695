<template>
  <tr>
    <td>{{ venta.nombre }}</td>
    <td>{{ venta.cantidad }}</td>
    <td>${{ dotsCadaTres(parseFloat(venta.precio_total).toFixed(2).replace(/\.00$/, '')) }}</td>
    <td>{{ venta.cantidad_socio }}</td>
    <td>${{ dotsCadaTres(parseFloat(venta.facturado_socio).toFixed(2).replace(/\.00$/, '')) }}</td>
    <td>{{ venta.cantidad_no_socio }}</td>
    <td>${{ dotsCadaTres(parseFloat(venta.facturado_no_socio).toFixed(2).replace(/\.00$/, '')) }}</td>
  </tr>
</template>

<script>
// import ServiciosListaItemCuota from '../components/ServiciosListaItemCuota.vue'
// import serviciossService from '../services/servicios'
export default {
  name: 'VentasListaCarritoItem',
  components: {
  },
  props: ['venta'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    }
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  }
}
</script>
