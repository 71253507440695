<template>
  <tr>
    <td>{{ producto.nombre }}</td>
    <td>{{ producto.stock }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ProductoListaItem',
  components: {
    // StockListaItemPersona
  },
  props: ['producto'],
  data: function () {
    return {
      // store
    }
  }
}
</script>

<style>

</style>
