<template>
  <div class="about">
    <h1>Cargar Entrega</h1>
    <EntregaLista></EntregaLista>
    <EntregaForm></EntregaForm>
  </div>
</template>
<script>
// import ventasService from '../services/ventas'
// import Menu from '../components/Menu.vue'
import EntregaLista from '../components/EntregaLista.vue'
import EntregaForm from '../components/EntregaForm.vue'

export default {
  name: 'CargarEntrega',
  components: {
    EntregaLista,
    EntregaForm
  },
  data: function () {
    return {
      entrega: [],
      cargando: false
    }
  },
  methods: {
    editarPago (pago, id) {
      // console.log(id)
      // console.log(pago)
      // //   if (this.cargando) {
      // //     return null
      // //   }
      // //   this.errores = {
      // //     nombre: null,
      // //     monto: null,
      // //     fecha_inicio: null,
      // //     fecha_finalizacion: null
      // //   }
      // //   this.cargando = true
      // pagosService
      //   .editarPagoPorId(pago, id)
      //   .then(respuesta => {
      //     this.cargando = false
      //     if (respuesta.success) {
      //       console.log('SIIIIII')
      //       this.pago = {
      //         precio_total: null,
      //         fecha_de_pago: null,
      //         concepto_id: null,
      //         forma_de_pago_id: null,
      //         persona_id: null,
      //         year_id: null,
      //         moneda_id: null,
      //         descripcion: null
      //       }
      //       this.errores = {
      //         nombre: null,
      //         precio_total: null,
      //         fecha_de_pago: null,
      //         year_id: null,
      //         concepto_id: null,
      //         forma_de_pago_id: null,
      //         persona_id: null,
      //         moneda_id: null,
      //         descripcion: null
      //       }
      //       // this.$router.push('/presupuesto')
      //     } else {
      //       this.errores = {
      //         nombre: null,
      //         precio_total: null,
      //         fecha_de_pago: null,
      //         year_id: null,
      //         concepto_id: null,
      //         forma_de_pago_id: null,
      //         persona_id: null,
      //         moneda_id: null,
      //         descripcion: null,
      //         ...respuesta.errors
      //       }
      //     }
      //   })
    }
  },
  mounted: function () {
  }
}
</script>
