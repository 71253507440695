<template>
  <div>
    <form
      action="#"
      method="post"
      @submit.prevent="crearVenta(servicio)"
      class="form_presupuesto_nuevo"
    >
      <div class="form_section">
        <label for="servicio-id">Producto</label>
        <select
          id="servicio-id"
          name="servicio-id"
          class="form minimal"
          v-model.number="servicio.servicio_id"
          :disabled="cargando"
          @change="onChange($event)"
        >
        <option
          v-for="producto in productos"
          :value="producto.servicio_id"
          :key="producto.servicio_id"
         >
            {{ producto.nombre }}
          </option>
        </select>
       </div>
    </form>
    <table id="tabla-stock">
      <thead>
        <th>Fecha</th>
        <th>Descripción</th>
        <th>Ingreso</th>
        <th>Egreso</th>
      </thead>
      <tbody>
        <MovimientoListaItem
          v-for="movimiento in movimientos"
          :movimiento="movimiento"
          :key="movimiento.historial_personas_tienen_productos_id"
        ></MovimientoListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import serviciosService from '../services/servicios'
import movimientosService from '../services/movimientos'
import MovimientoListaItem from '../components/MovimientoListaItem.vue'

export default {
  name: 'MovimientoStock',
  components: {
    MovimientoListaItem
  },
  data: function () {
    return {
      productos: [],
      movimientos: [],
      servicio: {
        servicio_id: null
      },
      cargando: false
    }
  },
  methods: {
    onChange (event) {
      // console.log(numero)

      console.log(event.target.value)
      movimientosService
        .traerMovimientosPorServicioId(event.target.value)
        .then(respuesta => {
          // this.cargando = false
          this.movimientos = respuesta
          // console.log('Personas: ', respuesta)
          // this.entrega.productos[numero - 1].persona_id = respuesta[0].persona_id
        })
    }
  },
  mounted: function () {
    serviciosService
      .traerServiciosTodos()
      .then(respuesta => {
        // this.cargando = false
        this.productos = respuesta
      })
  }
}
</script>

<style>

</style>
