<template>
  <tr>
    <td>{{ movimiento.fecha }}</td>
    <td>{{ movimiento.descripcion }}</td>
    <td>
      <span v-if="movimiento.contable_id == 2">
        {{ movimiento.stock }} unidades
      </span>
    </td>
    <td>
      <span v-if="movimiento.contable_id == 1">
        {{ movimiento.stock }} unidades
      </span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'MovimientoListaItem',
  components: {
    // StockListaItemPersona
  },
  props: ['movimiento'],
  data: function () {
    return {
      // store
    }
  }
}
</script>

<style>

</style>
