<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Listado de Pagos</h1>
        <div class="barra"></div>
      </div>
      <PagosLista
      ></PagosLista>
    </section>
  </div>
</template>

<script>
import PagosLista from '../components/PagosLista.vue'
// import Header2 from '../components/Header2.vue'
export default {
  name: 'ListarPagos',
  components: {
    PagosLista
    // Header2
  },
  data: function () {
    return {
    }
  }
}
</script>

<style>

</style>
