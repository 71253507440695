<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Mercado Pago Check</h1>
        <div class="barra"></div>
      </div>
      <VentasMercadoPagoNoIngresadas
      ></VentasMercadoPagoNoIngresadas>
    </section>
  </div>
</template>

<script>
import VentasMercadoPagoNoIngresadas from '../components/VentasMercadoPagoNoIngresadas.vue'
// import Header2 from '../components/Header2.vue'
export default {
  name: 'MercadoPagoCheck',
  components: {
    VentasMercadoPagoNoIngresadas
  },
  data: function () {
    return {
    }
  }
}
</script>

<style>

</style>
