<template>
  <tr>
    <td>{{ venta.fecha_de_venta }}</td>
    <td>${{ dotsCadaTres(parseFloat(venta.precio_total).toFixed(2).replace(/\.00$/, '')) }}</td>
    <td>{{ venta.persona.nombre }} {{ venta.persona.apellido }}</td>
    <td>{{ venta.estado.nombre }}<router-link :to="`/crear-entrega-venta/${venta.venta_id}`" class="icon icon-pencil-square-o boton_editar_pagos_lista boton_pagos_lista tabla-link"></router-link></td>
  </tr>
</template>

<script>
// import ServiciosListaItemCuota from '../components/ServiciosListaItemCuota.vue'
// import serviciossService from '../services/servicios'
export default {
  name: 'VentasListaCarritoNoEntregadasItem',
  components: {
  },
  props: ['venta'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    }
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  }
}
</script>
