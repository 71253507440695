import { API } from './../constants'
import authService from './auth.js'

let perfiles = null
let certificaciones = null
let estados = null
let condiciones = null
let tipos = null
let socios = null
let estadoWeb = null

const personasService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerPerfilesTodos () {
    const fetchResponse = await fetch(`${API}/perfiles`)
    const respuesta = await fetchResponse.json()
    perfiles = respuesta.data
    return [...perfiles]
  },
  async traerEstadosTodos () {
    const fetchResponse = await fetch(`${API}/estados`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    estados = respuesta.data
    return [...estados]
  },
  async traerCondicionesTodas () {
    const fetchResponse = await fetch(`${API}/condiciones`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    condiciones = respuesta.data
    return [...condiciones]
  },
  async traerTiposTodos () {
    const fetchResponse = await fetch(`${API}/tipos`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    tipos = respuesta.data
    return [...tipos]
  },
  async traerCertificacionesTodas () {
    const fetchResponse = await fetch(`${API}/certificaciones`)
    const respuesta = await fetchResponse.json()
    certificaciones = respuesta.data
    return [...certificaciones]
  },
  async traerEstadosWebTodos () {
    const fetchResponse = await fetch(`${API}/web`)
    const respuesta = await fetchResponse.json()
    estadoWeb = respuesta.data
    return [...estadoWeb]
  },
  async traerSociosPorFiltro (data) {
    const fetchResponse = await fetch(`${API}/socios-filtro/${data}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    socios = respuesta.data
    return [...socios]
  },
  crearSocio (data) {
    return fetch(`${API}/crear-socio`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  crearPuntoDeEntrega (data) {
    return fetch(`${API}/crear-punto-de-entrega`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  validarSocio (data) {
    return fetch(`${API}/validar-socio`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  validarSocioSeminario (data) {
    return fetch(`${API}/validar-socio-seminario`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  asociarse (data) {
    return fetch(`${API}/asociarse`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },

  editarSocioPorId (data, id) {
    return fetch(`${API}/editar-socio/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },

  borrarDireccion (id1, id2) {
    return fetch(`${API}/borrar-direccion/${id1}/${id2}`, {
      method: 'PUT',
      // body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },

  editarPassword (data, id) {
    return fetch(`${API}/editar-pasword/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  recuperarPassword (data) {
    return fetch(`${API}/recuperar-password`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  resetearPassword (data) {
    return fetch(`${API}/resetear-password`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  }
}

export default personasService
