<template>
  <div class="contenedor-cpanel">
    <BaseNotification2
      v-if="store.status.msg != null"
      :type="store.status.type"
      :text="store.status.msg"
      :closable="store.status.closable"
      @close="borrarNotificacion()"
    />
    <div class="contenedor-titulo">
      <h1>Listado de Socios</h1>
      <div class="barra"></div>
    </div>
    <section class="contenedor-c">
      <SociosLista
      ></SociosLista>
    </section>
  </div>
</template>

<script>
import store from '../store'
import BaseNotification2 from '../components/BaseNotification2'
import SociosLista from '../components/SociosLista.vue'
export default {
  name: 'Socios',
  components: {
    SociosLista,
    BaseNotification2
  },
  data: function () {
    return {
      store
    }
  },
  methods: {
    borrarNotificacion () {
      this.store.setStatus({
        msg: null,
        type: null,
        title: null
      })
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.borrarNotificacion()
    }, 4000)
  }
}
</script>

<style>

</style>
