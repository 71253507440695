<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <h1>Stock</h1>
      <StockLista
      ></StockLista>
    </section>
    <router-link to="/movimiento-stock">MOVIMIENTO STOCK</router-link>
  </div>
</template>

<script>
import StockLista from '../components/StockLista.vue'
export default {
  name: 'Stock',
  components: {
    StockLista
  },
  data: function () {
    return {
    }
  }
}
</script>

<style>

</style>
