<template>
  <li>{{ producto.nombre_producto }} <br>Cantidad: {{ producto.cantidad_producto }} unidades<br>Precio: ${{ producto.precio_producto }} </li>
</template>

<script>
// import StockListaItemPersona from '../components/StockListaItemPersona.vue'
// import store from './../store'
// import { PATH_IMG } from '../constants'
// console.log(persona)
export default {
  name: 'VentasListaItemProductos',
  components: {
  },
  props: ['producto'],
  data: function () {
    return {
      // store
    }
  }
}
</script>

<style>

</style>
