<template>
  <div class="login_form">
    <div class="contenedor-titulo">
      <h1>Generar Contraseña</h1>
      <div class="barra"></div>
    </div>
    <router-link :to="`/login`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    <section class="contenedor-login">
      <div class="contenedor-login-img">
        <img src="@/assets/guruji.jpg" alt="BKS Iyengar">
      </div>
      <div class="contenedor-login-form">
        <form
          action="#"
          @submit.prevent="resetearPassword(registro)"
        >
          <p class="token_texto">Revisa tu casilla de correo para acceder al token que necesitas para generar la contraseña</p>
          <div class="form_section contenedor-ojo">
            <label for="password_nueva">Nueva Contraseña</label>
              <input
                type="password"
                name="password_nueva"
                id="password_nueva"
                v-model="registro.password_nueva"
                :disabled="cargando"
                class="campo-ojo"
              >
              <div
              class="form_mensaje_error_login"
                v-if="errores.password_nueva !== null"
              >
                {{ errores.password_nueva[0] }}
              </div>
              <span class="icon icon-eye-slash ojo"  id="ojo_login_4" @click="mostrarPassword(0)"></span>
          </div>
          <div class="form_section contenedor-ojo">
            <label for="password_nueva_confirmacion">Repetir Contraseña</label>
            <input
              type="password"
              name="password_nueva_confirmacion"
              id="password_nueva_confirmacion"
              v-model="registro.password_nueva_confirmacion"
              :disabled="cargando"
              class="campo-ojo"
            >
            <div
            class="form_mensaje_error_login"
              v-if="errores.password_nueva_confirmacion !== null"
            >
              {{ errores.password_nueva_confirmacion[0] }}
            </div>
            <span class="icon icon-eye-slash ojo"  id="ojo_login_5" @click="mostrarPassword(1)"></span>
          </div>
          <div class="form_section">
            <label for="token">Token</label>
            <input
              type="text"
              name="token"
              id="token"
              v-model="registro.token"
              :disabled="cargando"
            ><div
            class="form_mensaje_error_login"
              v-if="errores.token !== null"
            >
              {{ errores.token[0] }}
            </div>
          </div>
          <button type="submit">
            <span
              v-if="!cargando"
            >
              Generar Contraseña
            </span>
            <BaseLoader3 v-else
            />
          </button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import store from './../store'
import personasService from '../services/personas'
import BaseLoader3 from '../components/BaseLoader3.vue'

export default {
  name: 'GenerarPassword',
  components: {
    BaseLoader3
  },
  props: {},
  data: function () {
    return {
      cargando: false,
      registro: {
        password_nueva: null,
        password_nueva_confirmacion: null,
        token: null,
        persona_id: this.$route.params.id
      },
      // status: {
      //   msg: null,
      //   type: 'success',
      //   closable: true
      // },
      errores: {
        password_nueva: null,
        password_nueva_confirmacion: null,
        token: null
      },
      store
    }
  },
  computed: {},
  methods: {
    // borrarNotificacion () {
    //   this.status = {
    //     msg: null,
    //     type: 'success',
    //     closable: true
    //   }
    // },
    resetearPassword (registro) {
      console.log(registro)
      if (this.cargando) {
        return null
      }
      this.errores = {
        password_nueva: null,
        password_nueva_confirmacion: null,
        token: null
      }
      this.cargando = true
      personasService
        .resetearPassword(registro)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.errors) {
            this.errores = {
              password_nueva: null,
              password_nueva_confirmacion: null,
              token: null,
              ...respuesta.errors
            }
          } else if (respuesta.success) {
            // Limpio el formulario
            this.registro = {
              password_nueva: null,
              password_nueva_confirmacion: null,
              token: null
            }
            this.store.setStatus({
              msg: 'La contraseña se creo exitosamente',
              type: 'success'
            })
            this.$router.push('/login')
          } else if (respuesta.token === false) {
            this.errores = {
              password_nueva: null,
              password_nueva_confirmacion: null,
              token: ['El token ingresado es inválido']
            }
          } else if (respuesta.email_confirmacion === false) {
            this.errores = {
              password_nueva: ['Las contraseñas ingresadas no coinciden'],
              password_nueva_confirmacion: null,
              token: null
            }
          }
        })
    },
    mostrarPassword (id) {
      const ojo = document.querySelectorAll('.ojo')
      const input = document.querySelectorAll('.campo-ojo')
      if (ojo[id].getAttribute('class') === 'icon icon-eye-slash ojo') {
        ojo[id].removeAttribute('class')
        ojo[id].setAttribute('class', 'icon icon-eye ojo ojo-naranja')
        input[id].setAttribute('type', 'text')
      } else {
        ojo[id].removeAttribute('class')
        ojo[id].setAttribute('class', 'icon icon-eye-slash ojo')
        input[id].setAttribute('type', 'password')
      }
    }
  }
}
</script>

<style>

</style>
