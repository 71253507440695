import { API } from './../constants'
import authService from './auth.js'

let cuotasPagas = null
let cuotasImpagas = null
let cuotasPorVencer = null
let regaliasImpagas = null
let conceptosPagos = null
let cuotaAsociarse = null
let yearsPagos = null
let formasPagos = null
let personas = null
let monedas = null
let pago = null
let pagos = null
let historial = null
let cuota = null

const pagosService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerCuotasPagasPorAsociadoId (id) {
    const fetchResponse = await fetch(`${API}/cuotas-pagas/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    cuotasPagas = respuesta.data
    return [...cuotasPagas]
  },
  async traerCuotaPorId (id) {
    const fetchResponse = await fetch(`${API}/cuota/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    cuota = respuesta.data
    return [...cuota]
  },
  async traerPago (id) {
    const fetchResponse = await fetch(`${API}/pago/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    pago = respuesta.data
    return { ...pago }
  },

  async traerHistorialPagosPorAsociadoId (id) {
    const fetchResponse = await fetch(`${API}/historial-pagos-usuario/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    historial = respuesta.data
    return [...historial]
  },

  async traerCuotasPagasPorVencer () {
    const fetchResponse = await fetch(`${API}/cuotas-por-vencer`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    cuotasPorVencer = respuesta.data
    return [...cuotasPorVencer]
  },

  async traerCuotasImpagasPorAsociadoId (id) {
    const fetchResponse = await fetch(`${API}/cuotas-impagas/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    cuotasImpagas = respuesta.data
    return [...cuotasImpagas]
  },

  async validarSocioAlDia (id) {
    const fetchResponse = await fetch(`${API}/validar-socio-al-dia/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    pago = respuesta
    return { ...pago }
  },

  async traerRegaliasImpagasPorAsociadoId (id) {
    const fetchResponse = await fetch(`${API}/regalias-impagas/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    regaliasImpagas = respuesta.data
    return [...regaliasImpagas]
  },

  async traerConceptosDePago () {
    const fetchResponse = await fetch(`${API}/conceptos-de-pago`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    conceptosPagos = respuesta.data
    return [...conceptosPagos]
  },
  async traerCuotaAsociarse () {
    const fetchResponse = await fetch(`${API}/cuotas-impagas-asociarse`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    cuotaAsociarse = respuesta.data
    return [...cuotaAsociarse]
  },
  async traerYearsDePago () {
    const fetchResponse = await fetch(`${API}/years-de-pago`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    yearsPagos = respuesta.data
    return [...yearsPagos]
  },

  async traerFormasDePago () {
    const fetchResponse = await fetch(`${API}/formas-de-pago`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    formasPagos = respuesta.data
    return [...formasPagos]
  },

  async traerMonedas () {
    const fetchResponse = await fetch(`${API}/monedas`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    monedas = respuesta.data
    return [...monedas]
  },

  async traerPersonas () {
    const fetchResponse = await fetch(`${API}/personas`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    personas = respuesta.data
    return [...personas]
  },

  async traerPagosTodos () {
    const fetchResponse = await fetch(`${API}/pagos`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    pagos = respuesta.data
    return [...pagos]
  },
  borrarPago (id) {
    return fetch(`${API}/eliminar-pago/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(res => {
        return { ...res }
      })
  },
  buscarPagos (data) {
    return fetch(`${API}/buscar-pagos`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  crearPago (data) {
    return fetch(`${API}/crear-pago`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  editarPagoPorId (data, id) {
    return fetch(`${API}/editar-pago/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  async checkPago (id) {
    const fetchResponse = await fetch(`${API}/check-pago/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    return { ...respuesta }
  }
}

export default pagosService
