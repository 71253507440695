<template>
  <li>
    <div class="contenedor_precio_socio">
      <div class="cuota_cantidad"> Cuota # {{ cuota.cuota_numero }} / {{ cuota.cuotas_cantidad }}</div>
      <div class="contenedor_cuota_descripcion">
        <div class="cuota_precio"> $ {{ cuota.precio }}</div>
        <div class="contenedor_cuota_fechas">
          <div class="cuota_fechas_item"> Desde: {{ fecha(cuota.fecha_inicio) }}</div>
          <div class="cuota_fechas_item"> Hasta: {{ fecha(cuota.fecha_finalizacion) }}</div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'ServiciosListaItemCuota',
  components: {
  },
  props: ['cuota'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    }
  }
}
</script>

<style>

</style>
