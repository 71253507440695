<template>
  <div id="app">
    <h3>Vue Dropdown Multiple Select with Search</h3>
    <input type="text" v-model="search" v-on:input="filterOptions" placeholder="Search"><br>
    <select multiple="true" v-model="selectedOption" :size="options.length">
      <option disabled value="">Please select one</option>
      <option
        v-for="option in filteredOptions"
        :value="option"
        :key="option"
        >{{ option }}</option>
    </select>
    <pre>Selected language: {{  selectedOption }}</pre>
    <p>Note: Select multiple items from a drop-down list by holding down the Ctrl (Windows) or Command (Mac) button
      to select multiple</p>
  </div>
</template>
<script>
export default {
  name: 'Ejemplo',
  data: function () {
    return {
      options: ['React', 'Vue', 'Angular', 'Node', 'Express', 'Bootstrap', 'MongoDb', 'Javascript', 'Vuetify'],
      search: '',
      selectedOption: [],
      filteredOptions: []
    }
  },
  mounted () {
    this.filterOptions()
  },
  methods: {
    filterOptions () {
      this.filteredOptions = this.options.filter(option => {
        return option.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
}
</script>
<style scoped>
  /* Style for the dropdown container */
  #app {
    margin: 20px auto;
    width: 300px;
    text-align: center;
  }

  /* Style for the input search */
  input[type="text"] {
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }

  /* Style for the select options */
  select {
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }

  /* Style for the selected option preview */
  pre {
    font-size: 14px;
    margin-top: 10px;
  }

  /* Style for the note below */
  p {
    font-size: 12px;
    color: #666;
  }
</style>
