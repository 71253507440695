<template>
  <div class="container">
    <!-- <BaseLoader
      v-if="cargando"
    /> -->
    <!-- <table v-else> -->
    <table>
      <thead>
        <!-- <th class="nofuente">IMAGEN</th> -->
        <th>Producto</th>
        <th>Cantidad</th>
      </thead>
      <tbody>
        <EntregaListaItem
          v-for="producto in venta"
          :producto="producto"
          :key="producto.servicio_id"
        ></EntregaListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import ventasService from '../services/ventas'
import EntregaListaItem from '../components/EntregaListaItem.vue'
// // import Header2 from '../components/Header2.vue'
// import serviciossService from '../services/servicios'
export default {
  name: 'EntregaLista',
  components: {
    EntregaListaItem
    // Header2
  },
  data: function () {
    return {
      venta: []
    }
  },
  methods: {
  },
  mounted: function () {
    ventasService
      .traerVentaPorId(this.$route.params.id)
      .then(respuesta => {
        // console.log('La Venta es: ' + respuesta)
        this.venta = respuesta
      })
  }
}
</script>

<style>

</style>
