import { API } from '../constants'

let userData = {
  persona_id: null,
  socio_id: null,
  email: null,
  nombre: null,
  apellido: null,
  tipo_id: null,
  socio_numero: null,
  imagen: null,
  dni: null,
  direccion_1: null,
  provincia_id_1: null
}

let token = null

const authService = {
  login (dni, password, perfil) {
    return fetch(`${API}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({
        dni: dni,
        password: password,
        perfil: perfil
      }),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      },
      // Agregamos que la petición de fetch envie y reciba las cookies, usando la propiedad:
      credentials: 'include'
    })
      .then(rta => rta.json())
      .then(res => {
        if (!res.success) {
          return { ...res }
        }
        userData = {
          ...res.data.user
        }
        token = res.data.token
        console.log('El token es: ', res.data.token)
        console.log('El token es: ', token)
        console.log('El ID del perfil: ', res.data.user.perfil_id)
        console.log('El user registrado es: ', res.data.user)
        // Guardamos en localStorage que el usuario se autenticó.
        localStorage.setItem('userData', JSON.stringify(userData))
        localStorage.setItem('token', JSON.stringify(token))
        return { ...res }
      })
  },

  /**
   * Desloguea al usuario.
   *
   * @return {Promise<Response | never>}
   */
  logout () {
    return fetch(`${API}/auth/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...this.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(rta => {
        userData = {
          persona_id: null,
          socio_id: null,
          email: null,
          nombre: null,
          apellido: null,
          tipo_id: null,
          perfil_id: null,
          socio_numero: null,
          imagen: null,
          dni: null,
          direccion_1: null,
          provincia_id_1: null
        }
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        token = null
        return rta.success
      })
  },
  tokenHeader () {
    return {
      Authorization: `Bearer ${token}`
    }
  },
  /**
   * Retorna true si el usuario está autenticado, false de lo contrario.
   *
   * @return {boolean}
   */
  isLogged () {
    return userData.tipo_id !== null
  },
  isLogged2 () {
    if (userData.tipo_id === 1) {
      return true
    }
  },
  /**
   * Marca como autenticado al usuario provisto por parámetro.
   *
   * @param {{}} user
   * @param {Number} user.id
   * @param {String} user.email
   * @param {String} user.nombre
   * @param {String} user.apellido
   */
  setLoggedUser (user) {
    userData = { ...user }
  },
  setToken (token) {
    token = { ...token }
  },
  getLoggedUser () {
    return { ...userData }
  },
  getToken () {
    return { ...token }
  }
}

// Verifico si el usuario esta autenticado

if (localStorage.getItem('userData') !== null) {
  userData = JSON.parse(localStorage.getItem('userData'))
  authService.setLoggedUser(userData)
  token = JSON.parse(localStorage.getItem('token'))
  authService.setToken(token)
}

export default authService
