<template>
  <div class="login_form">
    <div class="contenedor-titulo">
      <h1>Asociate</h1>
      <div class="barra"></div>
    </div>
    <router-link :to="`/login`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    <section class="contenedor-login">
      <div class="contenedor-login-img">
        <img src="@/assets/geetaji.jpg" alt="Geeta Iyengar">
      </div>
      <BaseLoader
        v-if="cargando"
      />
      <div class="contenedor-asociarse-form" v-else>
        <p class="contenedor-asociarse-form-text">Este formulario es para ser completado por los socios de la AAYI</p>
        <form
          action="#"
          method="post"
          @submit.prevent="crearSocio(socio)"
          class="form_presupuesto_nuevo"
        >
          <div class="form_section">
            <label for="nombre">Nombre</label>
            <input
              type="text"
              name="nombre"
              id="nombre"
              v-model="socio.nombre"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.nombre !== null"
            >
              {{ errores.nombre[0] }}
            </div>
          </div>
          <div class="form_section">
            <label for="apellido">Apellido</label>
            <input
              type="text"
              name="apellido"
              id="apellido"
              v-model="socio.apellido"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.apellido !== null"
            >
              {{ errores.apellido[0] }}
            </div>
          </div>
          <div class="form_section">
            <label for="email">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              v-model="socio.email"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.email !== null"
            >
              {{ errores.email[0] }}
            </div>
          </div>

          <div class="form_section">
            <label for="perfil_id">Perfil</label>
            <select
              id="perfil_id"
              name="perfil_id"
              class="form minimal"
              v-model.number="socio.perfil_id"
              :disabled="cargando"
            >
              <option
                v-for="perfil in perfiles"
                :value="perfil.perfil_id"
                :key="perfil.perfil_id"
              >
                {{ perfil.nombre }}
              </option>
            </select>
            <div
            class="form_mensaje_error"
              v-if="errores.perfil_id !== null"
            >
              {{ errores.perfil_id[0] }}
            </div>
          </div>
          <div
            class="form_section"
            v-if="mostrarCampoCertificacion"
          >
            <label for="certificacion_id">Certificación</label>
            <select
              id="certificacion_id"
              name="certificacion_id"
              class="form minimal"
              v-model.number="socio.certificacion_id"
              :disabled="cargando"
            >
              <option
                v-for="certificacion in certificaciones"
                :value="certificacion.certificacion_id"
                :key="certificacion.certificacion_id"
              >
                {{ certificacion.nombre }}
              </option>
            </select>
            <div
            class="form_mensaje_error"
              v-if="errores.certificacion_id !== null"
            >
              {{ errores.certificacion_id[0] }}
            </div>
          </div>
          <div class="form_section">
            <label for="dni">DNI</label>
            <input
              type="text"
              name="dni"
              id="dni"
              v-model="socio.dni"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.dni !== null"
            >
              {{ errores.dni[0] }}
            </div>
          </div>
          <div class="form_section"  v-if="mostrarAutocompleteDireccion1">
            <label for="autocomplete">Ingrese su dirección</label>
            <GmapAutocomplete
              @place_changed="setPlace"
              id="autocomplete"
              class="autocomplete"
              :options="autocompleteOptions"
              placeholder=""
            />
            <div
              class="form_asociarse_mensaje_error"
              v-if="errores.ingrese_direccion !== null"
            >
              {{ errores.ingrese_direccion[0] }}
            </div>
          </div>
          <div class="texto_confirmacion_direccion texto_confirmacion_direccion_asociarse">
            <p v-if="mostrarConfirmacionDireccion1">Por favor revisa el mapa y en caso de OK presionar CONTINUAR, caso contrarrio CANCELAR</p>
            <div class="contenedor_mapa_confirmacion_direccion"  v-if="mostrarConfirmacionDireccionMapa1">
              <gmap-map
                :center="center3"
                :zoom="mapZoom"
                map-type-id="roadmap"
                :options="{
                  mapTypeControl: false,
                  keyboardShortcuts: false,
                  // mapData: false,
                  streetViewControl: false,
                  disableDefaultUI: true,
                  styles: styles['hide'] // Saco todos los iconos de los comercios
                }"
              >
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in markers3"
                  :position="m.position"
                  :clickable="false"
                  :draggable="false"
                  :icon="require('@/assets/pin.png')"
                ></gmap-marker>
              </gmap-map>
            </div>
            <div v-if="mostrarConfirmacionDireccion1" class="confirmacion_direccion_contenedor_botones">
              <button type="button" @click="direccionIncorrecta(1)" class="boton_texto_confirmacion boton_texto_confirmacion_cancelar">CANCELAR</button>
              <button type="button" @click="direccionCorrecta(1)" class="boton_texto_confirmacion boton_texto_confirmacion_continuar">CONTINUAR</button>
            </div>
          </div>
          <div v-if="mostrarCamposDireccion1" class="campos_nueva_direccion">
            <p class="texto_confirmacion_direccion_campos_asociarse">Por favor revisar y editar (en caso de que sea necesario) los campos correspondientes a la dirección</p>
            <div class="form_section">
              <label for="direccion_1">Dirección</label>
              <input
                type="text"
                name="direccion_1"
                id="direccion_1"
                v-model="socio.direccion_1"
                :disabled="cargando"
              >
              <div
              class="form_asociarse_mensaje_error"
                v-if="errores.direccion_1 !== null"
              >
                {{ errores.direccion_1[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="ciudad_1">Ciudad / Localidad / Barrio</label>
              <input
                type="text"
                name="ciudad_1"
                id="ciudad_1"
                v-model="socio.ciudad_1"
                :disabled="cargando"
              >
              <div
              class="form_asociarse_mensaje_error"
                v-if="errores.ciudad_1 !== null"
              >
                {{ errores.ciudad_1[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="provincia_id_1">Provincia</label>
              <select
                id="provincia_id_1"
                name="provincia_id_1"
                class="form minimal"
                v-model.number="socio.provincia_id_1"
                :disabled="cargando"
              >
                <option
                  v-for="provincia in provincias"
                  :value="provincia.provincia_id"
                  :key="provincia.provincia_id"
                >
                  {{ provincia.nombre }}
                </option>
              </select>
              <div
              class="form_asociarse_mensaje_error"
                v-if="errores.provincia_id_1 !== null"
              >
                {{ errores.provincia_id_1[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="pais_id_1">País</label>
              <select
                id="pais_id_1"
                name="pais_id_1"
                class="form minimal"
                v-model.number="socio.pais_id_1"
                :disabled="cargando"
              >
                <option
                  v-for="pais in paises"
                  :value="pais.pais_id"
                  :key="pais.pais_id"
                >
                  {{ pais.nombre }}
                </option>
              </select>
              <div
              class="form_asociarse_mensaje_error"
                v-if="errores.pais_id_1 !== null"
              >
                {{ errores.pais_id_1[0] }}
              </div>
            </div>
            <button type="button" @click="borrarNuevaDireccion(1)" class="boton_borrar_direccion_asociarse">Borrar Dirección</button>
          </div>
          <button type="submit" class="boton_guardar">Cargar Datos</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import BaseLoader from '../components/BaseLoader.vue'
import personasService from '../services/personas'
import paisesService from '../services/paises'
import provinciasService from '../services/provincias'
import pagosService from '../services/pagos'
import store from './../store'
const mercadopago = require('mercadopago')
mercadopago.configure({
  access_token: 'APP_USR-2339860730955398-032713-925884898e35a8f3be76c84328e45b4f-1339687465'
})
export default {
  name: 'SoySocio',
  components: {
    BaseLoader
  },
  data: function () {
    return {
      perfiles: [],
      certificaciones: [],
      years: [],
      formasDePago: [],
      personas: [],
      monedas: [],
      cuotaSocial: {},
      linkMercadoPago: {
        link: null
      },
      store,
      cargando: false,
      mostrarConfirmacionDireccion1: false,
      mostrarConfirmacionDireccionMapa1: false,
      mostrarCamposDireccion1: false,
      mostrarAutocompleteDireccion1: true,
      // show: true,
      // seleccionado: 5,
      socio: {
        nombre: null,
        apellido: null,
        email: null,
        perfil_id: null,
        dni: null,
        ciudad_1: null,
        direccion_1: null,
        latitude_1: null,
        longitude_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        socio_id: 1, // Activo
        tipo_id: 2, // Visitante
        certificacion_id: 1, // No informado
        nuevo: 'no'
      },
      provisorioDireccion1: {
        lat: null,
        lng: null,
        direccion_1: null,
        ciudad_1: null
      },
      center3: {
        lat: null,
        lng: null
      },
      markers3: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      mapZoom: 15,
      errores: {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        perfil_id: null,
        certificacion_id: null,
        ciudad_1: null,
        direccion_1: null,
        latitude_1: null,
        longitude_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        ingrese_direccion: null
        // socio_numero: null
      },
      autocompleteOptions: {
        componentRestrictions: {
          country: [
            'ar', 'uy'
          ]
        }
      },
      styles: {
        hide: [
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ],
        retro: [
          { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#c9b2a6' }]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#dcd2be' }]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#ae9e90' }]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#93817c' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#a5b076' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#447530' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#f5f1e6' }]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#fdfcf8' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#f8c967' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#e9bc62' }]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{ color: '#e98d58' }]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#db8555' }]
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#806b63' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8f7d77' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#ebe3cd' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ color: '#b9d3c2' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#92998d' }]
          }
        ]
      }
    }
  },
  methods: {
    direccionIncorrecta (id) {
      if (id === 1) {
        this.mostrarConfirmacionDireccion1 = false
        this.mostrarConfirmacionDireccionMapa1 = false
        // // Borro el input de la dirección
        document.getElementById('autocomplete').value = ''
        // Borro el error de dirección
        this.errores.ingrese_direccion = null
        this.errores.ingrese_direccion[0] = ''
      } else {
        this.mostrarConfirmacionDireccion2 = false
        this.mostrarConfirmacionDireccionMapa2 = false
        document.getElementById('autocomplete2').value = ''
      }
    },
    direccionCorrecta (id) {
      this.errores = {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        perfil_id: null,
        certificacion_id: null,
        ciudad_1: null,
        direccion_1: null,
        latitude_1: null,
        longitude_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        ingrese_direccion: null
      }
      if (id === 1) {
        console.log('wuuuuuuuu')
        this.mostrarAutocompleteDireccion1 = false
        this.mostrarCamposDireccion1 = true
        this.mostrarConfirmacionDireccion1 = false
        this.socio.latitude_1 = this.provisorioDireccion1.lat
        this.socio.longitude_1 = this.provisorioDireccion1.lng
        if (this.socio.direccion_2 == null) {
          console.log('qqqwwweeeerrrr')
          this.mostrarBotonDireccion2 = true
        }
      } else {
        this.mostrarCamposDireccion2 = true
        this.mostrarConfirmacionDireccion2 = false
        this.socio.latitude_2 = this.provisorioDireccion2.lat
        this.socio.longitude_2 = this.provisorioDireccion2.lng
      }
      console.log('fffftttt')
      this.mostrarBotonEditarDatosPersonales = true
      // // Borro el input de la dirección
      if (document.getElementById('autocomplete2') != null) {
        document.getElementById('autocomplete2').value = ''
      }
      if (document.getElementById('autocomplete') != null) {
        document.getElementById('autocomplete').value = ''
      }
    },
    borrarNuevaDireccion (id) {
      if (id === 1) {
        console.log(id)
        this.mostrarCamposDireccion1 = false
        this.mostrarConfirmacionDireccionMapa1 = false
        this.mostrarConfirmacionDireccion1 = false
        this.mostrarAutocompleteDireccion1 = false
        // Borro los campos
        document.getElementById('direccion_1').value = ''
        document.getElementById('ciudad_1').value = ''
        document.getElementById('provincia_id_1').value = ''
        document.getElementById('pais_id_1').value = ''
        // Vuelvo a poner los datos en null
        this.socio.latitude_1 = null
        this.socio.longitude_1 = null
        this.socio.direccion_1 = null
        this.socio.ciudad_1 = null
        this.socio.provincia_id_1 = null
        this.socio.pais_id_1 = null
      } else {
        this.mostrarCamposDireccion2 = false
        this.mostrarConfirmacionDireccionMapa2 = false
        this.mostrarConfirmacionDireccion2 = false
        this.mostrarAutocompleteDireccion2 = false
        // Borro los campos
        document.getElementById('direccion_2').value = ''
        document.getElementById('ciudad_2').value = ''
        document.getElementById('provincia_id_2').value = ''
        document.getElementById('pais_id_2').value = ''
        // Vuelvo a poner los datos originales
        this.socio.latitude_2 = this.direccionSinEditar_2.latitude_2
        this.socio.longitude_2 = this.direccionSinEditar_2.longitude_2
        this.socio.direccion_2 = this.direccionSinEditar_2.direccion_2
        this.socio.ciudad_2 = this.direccionSinEditar_2.ciudad_2
        this.socio.provincia_id_2 = this.direccionSinEditar_2.provincia_id_2
        this.socio.pais_id_2 = this.direccionSinEditar_2.pais_id_2
      }
      this.mostrarAutocompleteDireccion1 = true
    },
    crearSocio (socio) {
      this.cargando = true
      // Reafirmo que el perfil visitante tenga certificacion 1
      if (this.socio.perfil_id === 2) {
        this.socio.certificacion_id = 1
      }
      this.errores = {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        perfil_id: null,
        certificacion_id: null,
        ciudad_1: null,
        direccion_1: null,
        latitude_1: null,
        longitude_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        ingrese_direccion: null
      }
      personasService
        .crearSocio(socio)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('siiiii')
            this.store.setStatus({
              msg: 'Los datos personales se cargaron exitosamente. Ya podes generar tu password para poder loguearte',
              type: 'success'
            })
            this.$router.push('/')
            // const preference = {
            //   // trackingId: 77,
            //   back_urls: {
            //     failure: 'http://localhost:8080/#/recuperar-password',
            //     success: 'http://localhost:8080/#/pago-exitoso-asociarse'
            //     // success: this.refrescarProfesores
            //   },
            //   items: [
            //     {
            //       id: this.cuotaSocial.concepto_id,
            //       title: this.cuotaSocial.nombre,
            //       unit_price: 3,
            //       // unit_price: Number(this.cuotaSocial.precio),
            //       quantity: 1
            //     }
            //   ],
            //   metadata: {
            //     nombre: this.socio.nombre,
            //     apellido: this.socio.apellido,
            //     email: this.socio.email,
            //     perfil_id: this.socio.perfil_id,
            //     certificacion_id: this.socio.certificacion_id,
            //     latitude_1: this.socio.latitude_1,
            //     longitude_1: this.socio.longitude_1,
            //     dni: this.socio.dni,
            //     direccion_1: this.socio.direccion_1,
            //     ciudad_1: this.socio.ciudad_1,
            //     provincia_id_1: this.socio.provincia_id_1,
            //     pais_id_1: this.socio.pais_id_1,
            //     concepto_id: 1,
            //     forma_de_pago_id: 3,
            //     moneda_id: 1,
            //     pago_concepto: 'asociarse',
            //     descripcion: 'Pago Cuota Social ' + new Date().getFullYear(),
            //     year: new Date().getFullYear()
            //   },
            //   payer: {
            //     name: this.socio.nombre,
            //     surname: this.socio.apellido
            //   },
            //   notification_url: 'https://abc2-181-165-81-31.ngrok-free.app/AAYI_API/api/public/api/webhooks',
            //   binary_mode: true,
            //   auto_return: 'approved',
            //   external_reference: this.socio.nombre + ' ' + this.socio.apellido + ' - ' + new Date().getTime().toString(),
            //   statement_descriptor: 'Asociación Argentina de Yoga Iyengar'
            // }
            // mercadopago.preferences
            //   .create(preference)
            //   .then(response => {
            //     console.log('mercadopago: ', response.body)
            //     console.log(new Date().getTime())
            //     // console.log(this.linkMercadoPago.link)
            //     this.linkMercadoPago.link = response.body.init_point
            //     // En esta instancia deberás asignar el valor dentro de response.body.id por el ID de preferencia solicitado en el siguiente paso
            //     window.location.href = this.linkMercadoPago.link
            //   })
            //   .catch(function (error) {
            //     console.log('MP ERROR!!!!!')
            //     console.log(error)
            //   })
          } else {
            console.log('noooo')
            const element = document.getElementById('autocomplete')
            if (element !== null) {
              if (element.value === '') {
                respuesta.errors.ingrese_direccion = ['El campo dirección no puede estar vacío']
              } else {
                respuesta.errors.ingrese_direccion = ['Para continuar tenes que aceptar o cancelar la dirección seleccionada']
              }
            }
            this.errores = {
              nombre: null,
              apellido: null,
              email: null,
              dni: null,
              perfil_id: null,
              certificacion_id: null,
              ciudad_1: null,
              direccion_1: null,
              latitude_1: null,
              longitude_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              ingrese_direccion: null,
              ...respuesta.errors
            }
          }
        })
      // window.location.href = 'https://www.promiedos.com.ar/libertadores'
      // window.location.href = this.linkMercadoPago.link
      // this.errores = {
      //   nombre: null,
      //   apellido: null,
      //   email: null,
      //   dni: null,
      //   perfil_id: null,
      //   certificacion_id: null,
      //   ciudad_1: null,
      //   direccion_1: null,
      //   latitude_1: null,
      //   longitude_1: null,
      //   provincia_id_1: null,
      //   pais_id_1: null,
      //   ingrese_direccion: null
      // }
      // this.cargando = true
      // personasService
      //   .crearSocio(socio)
      //   .then(respuesta => {
      //     this.cargando = false
      //     if (respuesta.success) {
      //       console.log('SIIIIII')
      //       this.socio = {
      //         nombre: null,
      //         apellido: null,
      //         email: null,
      //         dni: null,
      //         perfil_id: null,
      //         socio_id: 1,
      //         tipo_id: 2,
      //         certificacion_id: 1
      //         // fecha_ingreso: null
      //         // socio_numero: null
      //       }
      //       this.errores = {
      //         nombre: null,
      //         apellido: null,
      //         email: null,
      //         dni: null,
      //         perfil_id: null,
      //         certificacion_id: 1,
      //         fecha_ingreso: null
      //         // socio_numero: null
      //       }
      //       // this.$router.push('/presupuesto')
      //     } else {
      //       console.log('qqq22')
      //       console.log(respuesta)
      //       console.log(respuesta.errors)
      //       console.log(respuesta.errors.nombre)
      //       respuesta.errors.ingrese_direccion = ['El campo dirección no puede estar vacío'] // Agrego el texto al autocomplete
      //       this.errores = {
      //         nombre: null,
      //         apellido: null,
      //         email: null,
      //         dni: null,
      //         perfil_id: null,
      //         certificacion_id: null,
      //         ciudad_1: null,
      //         direccion_1: null,
      //         latitude_1: null,
      //         longitude_1: null,
      //         provincia_id_1: null,
      //         pais_id_1: null,
      //         ingrese_direccion: null,
      //         ...respuesta.errors
      //       }
      //     }
      //   })
    },
    setPlace (place) {
      // this.errores = {
      //   nombre: null,
      //   apellido: null,
      //   estudio_nombre_1: null,
      //   estudio_nombre_2: null,
      //   email: null,
      //   dni: null,
      //   perfil_id: null,
      //   website: null,
      //   telefono: null,
      //   celular: null,
      //   direccion_1: null,
      //   ciudad_1: null,
      //   latitude_1: null,
      //   longitude_1: null,
      //   provincia_id_1: null,
      //   pais_id_1: null,
      //   ciudad_2: null,
      //   direccion_2: null,
      //   latitude_2: null,
      //   longitude_2: null,
      //   provincia_id_2: null,
      //   pais_id_2: null,
      //   facebook: null,
      //   twitter: null,
      //   instagram: null,
      //   ingrese_direccion: null,
      //   certificacion_id: null
      // }
      this.currentPlace = place
      this.mostrarAutocompleteDireccion1 = true
      this.mostrarCamposDireccion1 = false
      // this.mostrarDireccionTexto = false
      this.mostrarConfirmacionDireccion1 = true
      this.mostrarConfirmacionDireccionMapa1 = true
      this.mostrarBotonEditarDatosPersonales = false
      console.log('Esto es lo que trae: ' + this.currentPlace.formatted_address)
      // console.log('Numero: ' + this.currentPlace.address_components[0].long_name)
      // console.log('Calle: ' + this.currentPlace.address_components[1].long_name)
      // console.log('Localidad: ' + this.currentPlace.address_components[2].long_name)
      // console.log('Localidad 2: ' + this.currentPlace.address_components[3].long_name)
      // console.log('Comuna: ' + this.currentPlace.address_components[4].long_name)
      // console.log('Provincia: ' + this.currentPlace.address_components[5].long_name)
      // console.log('Pais: ' + this.currentPlace.address_components[6].long_name)
      if (this.currentPlace.address_components[6] !== undefined) {
        const pais = this.currentPlace.address_components[6].long_name
        paisesService
          .traerPaisId(pais)
          .then(respuesta => {
            // this.cargando = true
            // this.socio = respuesta
            // this.center.lat = parseFloat(respuesta.latitude_1)
            // this.center.lng = parseFloat(respuesta.longitude_1)
            // this.markers[0].position.lat = parseFloat(respuesta.latitude_1)
            // this.markers[0].position.lng = parseFloat(respuesta.longitude_1)
            console.log('IdPais:', respuesta[0].pais_id)
            this.socio.pais_id_1 = respuesta[0].pais_id
            // console.log('LAT:', respuesta.latitude_1
          })
      }
      this.socio.direccion_1 = this.currentPlace.address_components[1].long_name + ' ' + this.currentPlace.address_components[0].long_name
      this.socio.ciudad_1 = this.currentPlace.address_components[2].long_name
      this.socio.provincia_id = null
      // this.socio.latitude_1 = this.currentPlace.geometry.location.lat()
      // this.socio.longitude_1 = this.currentPlace.geometry.location.lng()
      // Agrego lat y lng al provisorio
      this.provisorioDireccion1.lat = this.currentPlace.geometry.location.lat()
      this.provisorioDireccion1.lng = this.currentPlace.geometry.location.lng()
      // Centro el mapa
      this.center3.lat = this.currentPlace.geometry.location.lat()
      this.center3.lng = this.currentPlace.geometry.location.lng()
      // Marker
      this.markers3[0].position.lat = this.currentPlace.geometry.location.lat()
      this.markers3[0].position.lng = this.currentPlace.geometry.location.lng()
    }
  },
  mounted: function () {
    paisesService
      .traerPaisesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.paises = respuesta
        console.log('Paises:', respuesta)
      })
    provinciasService
      .traerProvinciasTodas()
      .then(respuesta => {
        // this.cargando = false
        this.provincias = respuesta
        console.log('Provincias:', respuesta)
      })
    personasService
      .traerPerfilesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.perfiles = respuesta
      })
    personasService
      .traerCertificacionesTodas()
      .then(respuesta => {
        // this.cargando = false
        this.certificaciones = respuesta
      })
    pagosService
      .traerConceptoCuotaSocial()
      .then(respuesta => {
        // this.cargando = false
        this.cuotaSocial = respuesta
        console.log('Cuota Social:', respuesta)
        // Crea un objeto de preferencia
      })
  },
  computed: {
    mostrarCampoCertificacion: function () {
      // if (this.pago.concepto_id === 1 ) {
      //   return true
      // }
      // return false
      switch (this.socio.perfil_id) {
        case 1:
          return true
        default:
          return false
      }
    }
  }
}
</script>
