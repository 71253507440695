<template>
  <tr>
    <td>{{ producto.nombre }}</td>
    <td>{{ producto.cantidad }}</td>
    <td></td>
  </tr>
</template>

<script>
// import StockListaItemPersona from '../components/StockListaItemPersona.vue'
// import serviciossService from '../services/servicios'
export default {
  name: 'EntregaListaItem',
  components: {
    // StockListaItemPersona
  },
  props: ['producto'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  }
}
</script>

<style>

</style>
