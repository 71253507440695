<template>
  <div>
    <h1>Crear Venta</h1>
    <div>
      <form
        action="#"
        method="post"
        @submit.prevent="crearVenta(servicio)"
        class="form_presupuesto_nuevo"
      >
        <div class="form_section">
          <label for="fecha_de_venta">Fecha del venta</label>
          <input
            type="date"
            name="fecha_de_venta"
            id="fecha_de_venta"
            v-model="servicio.fecha_de_venta"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.fecha_de_venta !== null"
          >
            {{ errores.fecha_de_venta[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="persona_id">Persona</label>
          <select
            id="persona_id"
            name="persona_id"
            class="form minimal"
            v-model.number="servicio.persona_id"
            :disabled="cargando"
          >
            <option
              v-for="persona in personas"
              :value="persona.persona_id"
              :key="persona.persona_id"
            >
              {{ persona.nombre }} {{ persona.apellido }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.persona_id !== null"
          >
            {{ errores.persona_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="descripcion">Descripción</label>
          <textarea
            name="descripcion"
            id="descripcion"
            v-model.number="servicio.descripcion"
          ></textarea>
        </div>

        <!-- <div class="form_section">
          <label for="stock">Stock</label>
          <input
            type="text"
            name="stock"
            id="stock"
            v-model="servicio.stock"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.stock !== null"
          >
            {{ errores.stock[0] }}
          </div>
        </div> -->
        <!-- <div class="form_section">
          <label for="pago_id">Producto</label>
          <select
            id="categoria_id"
            name="categoria_id"
            class="form minimal"
            v-model.number="servicio.servicio_id"
            :disabled="cargando"
            @change="onChange($event)"
          >
            <option
              v-for="producto in productos"
              :value="producto.servicio_id"
              :key="producto.servicio_id"
            >
              {{ producto.nombre }}
            </option>
          </select>

          <label for="cantidad">Cantidad</label>
          <input
            type="text"
            name="cantidad"
            id="cantidad"
            v-model="servicio.cantidad"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.servicio_id !== null"
          >
            {{ errores.servicio_id[0] }}
          </div>
          <label for="precio">Precio</label>
          <input
            type="text"
            name="precio"
            id="precio"
            v-model="servicio.precio"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.precio !== null"
          >
            {{ errores.precio[0] }}
          </div>
        </div> -->
        <div>
          <!-- <p v-for="index in count" :key="index">
            {% raw %}{{ index }}{% endraw %}
          </p> -->
          <div class="form_section" v-for="index in count" :key="index">
            <label for="pago_id">Producto {{ index }}</label>
            <!-- <v-select :options="categorias" label="title"></v-select> -->
            <select
              id="categoria_id"
              :name="`servicio-${index}`"
              class="form minimal"
              v-model.number="servicio.productos[ index  - 1].servicio_id"
              :disabled="cargando"
              @change="onChange($event, index)"
            >
              <option
                v-for="producto in productos"
                :value="producto.servicio_id"
                :key="producto.servicio_id"
              >
                {{ producto.nombre }}
              </option>
            </select>

            <label for="cantidad">Cantidad</label>
            <input
              type="text"
              name="cantidad"
              id="cantidad"
              v-model="servicio.productos[ index  - 1].cantidad"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.servicio_id !== null"
            >
              {{ errores.servicio_id[0] }}
            </div>
            <label for="precio">Precio</label>
            <input
              type="text"
              :name="`precio-${index}`"
              id="precio"
              v-model="servicio.productos[ index  - 1].precio"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.precio !== null"
            >
              {{ errores.precio[0] }}
            </div>
          </div>
          <div @click="addComponent" class="boton-agregar-producto">Agregar Producto</div>
        </div>
        <button type="submit" class="boton_guardar">Crear Venta</button>
      </form>
    </div>
  </div>
</template>

<script>
import serviciosService from '../services/servicios'
import pagosService from '../services/pagos'
import ventasService from '../services/ventas'
// import Menu from '../components/Menu.vue'
// import Footer from '../components/Footer.vue'

export default {
  name: 'CrearVenta',
  components: {
  },
  data: function () {
    return {
      count: 1,
      productos: [],
      personas: [],
      cargando: false,
      servicio: {
        // nombre: null,
        fecha_de_venta: null,
        persona_id: null,
        descripcion: null,
        productos: [
          {
            servicio_id: null,
            precio: null,
            cantidad: null
          }
          // {
          //   servicio_id: null,
          //   precio: null,
          //   cantidad: null
          // },
          // {
          //   servicio_id: null,
          //   precio: null,
          //   cantidad: null
          // },
          // {
          //   servicio_id: null,
          //   precio: null,
          //   cantidad: null
          // }
        ]
      },
      errores: {
        descripcion: null,
        precio: null,
        persona_id: null,
        categoria_id: null,
        fecha_de_venta: null,
        servicio_id: null,
        cantidad: null
      }
    }
  },
  methods: {
    addComponent () {
      this.count += 1
      console.log(this.servicio.productos.push({
        servicio_id: null,
        precio: null,
        cantidad: null
      }))
    },
    onChange (event, numero) {
      console.log(numero)

      console.log(event.target.value)
      console.log(event.target.name)
      console.log(document.getElementsByName('precio-' + numero)[0].value)

      console.log(this.productos[event.target.value - 1].precio)
      // document.getElementsByName('precio-' + numero)[0].value = this.productos[event.target.value - 1].precio
      this.servicio.productos[numero - 1].precio = this.productos[event.target.value - 1].precio
    },
    crearVenta (servicio) {
      console.log('qqqq')
      console.log(servicio)
      if (this.cargando) {
        return null
      }
      // this.errores = {
      //   nombre: null,
      //   descripcion: null,
      //   precio: null,
      //   stock: null,
      //   categoria_id: null
      // }
      this.cargando = true
      ventasService
        .crearVenta(servicio)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.socio = {
              nombre: null,
              descripcion: null,
              precio: null,
              stock: null,
              categoria_id: null
            }
            this.errores = {
              descripcion: null,
              precio: null,
              persona_id: null,
              categoria_id: null,
              fecha_de_venta: null,
              servicio_id: null,
              cantidad: null
            }
            // this.$router.push('/presupuesto')
          } else {
            this.errores = {
              descripcion: null,
              precio: null,
              persona_id: null,
              categoria_id: null,
              fecha_de_venta: null,
              servicio_id: null,
              cantidad: null,
              ...respuesta.errors
            }
          }
        })
    }
  },
  mounted: function () {
    serviciosService
      .traerServiciosTodos()
      .then(respuesta => {
        // this.cargando = false
        this.productos = respuesta
      })
    pagosService
      .traerPersonas()
      .then(respuesta => {
        // this.cargando = false
        this.personas = respuesta
        console.log('Personas: ', respuesta)
      })
  }
}
</script>
<style>
.boton-agregar-producto {
  padding: 10px;
  color: whitesmoke;
  background-color: blue;
  display: inline-block;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
}
</style>
