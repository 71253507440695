<template>
  <tr>
    <!-- <td>
      <img v-if="comercio.imagen != null" :src="imagenRuta" :alt="`${comercio.nombre}`">
      <img v-else src="@/assets/image-not-available.jpg" :alt="`${comercio.nombre}`">
    </td> -->
    <td>{{ punto.persona_nombre }} {{ punto.persona_apellido }}</td>
    <td>{{ punto.ubicacion }}</td>
    <!-- <td>{{ punto.stock_total }} unidades</td> -->
    <td class="no-padding">
      <ul class="puntos_entrega_productos_lista">
        <StockListaItemProducto
          v-for="item in punto.stock"
          :item="item"
          :key="item.producto_id"
        ></StockListaItemProducto>
      </ul>
    </td>
    <td>
      <div class="contenor_botones_form_stock">
        <router-link :to="`/editar-stock/${punto.persona_id}`" class="boton-editar">Editar</router-link>
        <button @click="borrarServicio(punto.persona_id)" class="boton-borrar">Borrar</button>
      </div>
    </td>
    <!-- <td class="botones-acciones"> -->
      <!-- <div class="botones-acciones-contenedor"> -->
        <!-- <button @click="abrirModal(profesor.persona_id)" class="icon icon-trash-o boton-acciones">Dar de Baja</button> -->
      <!-- </div> -->
      <!-- <div :class="`confirmation-${profesor.persona_id}`" class="confirmation">
        <div class="contenor_modal">
          <p>¿Estas seguro que deseas dar de baja a {{ profesor.nombre }} {{ profesor.apellido }}</p>
          <div class="contenor_modal_botones">
            <button @click="cerrarModal(profesor.persona_id)" class="boton_cancelar boton_modal">CANCELAR</button>
            <button @click="bajaProfesor(profesor.persona_id)" :data-id="`${profesor.persona_id}`" class="boton_aceptar boton_modal">ACEPTAR</button>
          </div>
        </div>
      </div> -->
    <!-- </td> -->
  </tr>
</template>

<script>
import StockListaItemProducto from '../components/StockListaItemProducto.vue'
import serviciossService from '../services/servicios'
export default {
  name: 'StockListaItem',
  components: {
    StockListaItemProducto
  },
  props: ['punto'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    borrarServicio (id) {
      console.log(id)
      serviciossService
        .borrarServicio(id)
        .then(respuesta => {
          this.$emit('refrescar')
        })
    },
    abrirModal (id) {
      console.log(id)
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'flex'
    },
    cerrarModal (id) {
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'none'
    }
    // tipo (tipo) {
    //   switch (tipo) {
    //     case 1:
    //       return 'comercio_tipo_restaurant'
    //     case 2:
    //       return 'comercio_tipo_hotel'
    //     case 3:
    //       return 'comercio_tipo_bar'
    //   }
    //   return 'comercio_tipo_restaurant'
    // }
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  }
}
</script>

<style>

.no-padding {
  padding: 0 !important;
}

.tabla-distribucion td, .tabla-distribucion th {
  border: 0 !important;
}

.tabla-distribucion th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: #0984e3 !important;
}

#tabla-stock ul {
  list-style-type: none;
}

#tabla-stock ul li {
/*  margin: 12px 0;*/
}

.error-stock {
  background-color: red;
  color: white;
}

.boton-editar {
  padding: 28px;
  background-color: rgb(126,220,217);
  color: #2c3e50;
  border: 2px solid rgb(126,220,217);
  font-size: 12px;
  margin: 5px 0;
}

.boton-borrar {
  padding: 8px;
  color: white;
  background-color: red;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid red;
  cursor: pointer;
  margin: 5px 0;
}

.contenor_botones_form_stock {
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
