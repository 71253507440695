<template>
  <div class="ancho">
    <div class="snippet" data-title=".dot-pulse">
      <div class="stage">
        <div class="dot-pulse"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoader3'
}
</script>

<style scoped>
.ancho {
  width: 100%;
}

.snippet {
  position: relative;
}

.examples .snippet:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  content: attr(data-title);
  font-size: .75rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: #EDF0EF;
  background-color: rgb(255, 25, 100);
  border-radius: .25rem 0 .25rem 0;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-pulse {
  position: relative;
  left: -10000px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #EDF0EF;
  color: #EDF0EF;
  box-shadow: 10000px 0 0 -5px #EDF0EF;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #EDF0EF;
  color: #EDF0EF;
}

.dot-pulse::before {
  box-shadow: 9980px 0 0 -5px #EDF0EF;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10010px 0 0 -5px #EDF0EF;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9980px 0 0 -5px #EDF0EF;
  }
  30% {
    box-shadow: 9980px 0 0 2px #EDF0EF;
  }
  60%,
  100% {
    box-shadow: 9980px 0 0 -5px #EDF0EF;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 10000px 0 0 -5px #EDF0EF;
  }
  30% {
    box-shadow: 10000px 0 0 2px #EDF0EF;
  }
  60%,
  100% {
    box-shadow: 10000px 0 0 -5px #EDF0EF;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10010px 0 0 -5px #EDF0EF;
  }
  30% {
    box-shadow: 10010px 0 0 2px #EDF0EF;
  }
  60%,
  100% {
    box-shadow: 10010px 0 0 -5px #EDF0EF;
  }
}
</style>
