import { API } from './../constants'
import authService from './auth.js'

let socios = null
let socio = null
let profesores = null

const asociadoService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerSociosTodos () {
    const fetchResponse = await fetch(`${API}/socios`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    socios = respuesta.data
    return [...socios]
  },
  async listadoSociosSelect () {
    const fetchResponse = await fetch(`${API}/socio-todos`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    socios = respuesta.data
    return [...socios]
  },
  async listadoPersonasNoExtranjeros () {
    const fetchResponse = await fetch(`${API}/persona-no-extranjeros`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    socios = respuesta.data
    return [...socios]
  },
  async traerSociosPorBuscador (data) {
    const fetchResponse = await fetch(`${API}/socios-buscador/${data}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    socios = respuesta.data
    return [...socios]
  },
  async traerSocioPorId (id) {
    const fetchResponse = await fetch(`${API}/socio/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    socio = respuesta.data
    return { ...socio }
  },
  async traerProfesoresFormadores (id) {
    const fetchResponse = await fetch(`${API}/profesores-formadores/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    profesores = respuesta.data
    return [...profesores]
  },
  async traerProfesoresFormadoresTodos () {
    const fetchResponse = await fetch(`${API}/profesores-formadores-todos`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    profesores = respuesta.data
    return [...profesores]
  },
  async traerPosiblesPuntosDeEntrega () {
    const fetchResponse = await fetch(`${API}/posibles_puntos_de_entrega`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    profesores = respuesta.data
    return [...profesores]
  }
}

export default asociadoService
