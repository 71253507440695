<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <BaseNotification2
        v-if="store.status.msg != null"
        :type="store.status.type"
        :text="store.status.msg"
        :closable="store.status.closable"
        @close="borrarNotificacion()"
      />
      <div class="contenedor-titulo">
        <h1>Listado de Servicios</h1>
        <div class="barra"></div>
      </div>
      <ServiciosLista
      ></ServiciosLista>
    </section>
  </div>
</template>

<script>
import ServiciosLista from '../components/ServiciosLista.vue'
import BaseNotification2 from '../components/BaseNotification2'
import store from '../store'
export default {
  name: 'ListarServicios',
  components: {
    ServiciosLista,
    BaseNotification2
  },
  data: function () {
    return {
      store
    }
  },
  methods: {
    borrarNotificacion () {
      this.store.setStatus({
        msg: null,
        type: null,
        title: null
      })
    }
  }
}
</script>

<style>

</style>
