import Vue from 'vue'
import vSelect from 'vue-select'
import App from './App.vue'
import router from './router'
// import MercadoPago from 'mercadopago'
// import { Cropper } from 'vue-advanced-cropper'
// import 'vue-advanced-cropper/dist/style.css'
import VueGeolocation from 'vue-browser-geolocation'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.config.productionTip = false
// Vue.use(MercadoPago)
// Vue.use(Cropper)
Vue.use(VueGeolocation)
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyCp-I9sSo8XUUAiQFkI-aiTBOiik6RO8Hw', // Cuenta Juan
    key: 'AIzaSyBVwSSa9fXZiavCOVQnuPvgY4vzDwAuuKU', // Cuenta AAYI
    libraries: 'places'
  }
})
Vue.component('v-select', vSelect)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
