<template>
  <div
    class="banner3"
    :class="type"
  >
    <p>{{ text }}</p>
    <button
      type="button"
      class="close"
      aria-label="Cerrar"
      v-if="closable"
      @click="$emit('close')"
    >
      <span aria-hidden="true">X</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseNotification2',
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String
    },
    closable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.banner3 {
  border-radius: 4px;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 850px;
  margin: 0 auto 20px;
}

.banner3 p {
  color: white;
  text-align: center !important;
  width: 100%;
  font-size: 17px;
}

</style>
