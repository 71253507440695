<template>
  <div
    class="banner"
    :class="type"
  >
    <p>{{ text }}</p>
    <button
      type="button"
      class="close"
      aria-label="Cerrar"
      v-if="closable"
      @click="$emit('close')"
    >
      <span aria-hidden="true">X</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseNotification',
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String
    },
    closable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

.banner {
  border-radius: 4px;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
/*  margin-left: calc(27% + 12px);*/
}

.banner p {
  color: white;
  text-align: center !important;
  width: 100%;
}

.success {
  background-color: #27ae60;
}

.error {
  background-color: #c0392b;
}

.close {
  width: 30px;
  height: 30px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}

.close span {
  color: white;
  font-size: 18px;
}

.success .close {
  background-color: #27ae60;
  transition: color .3s;
  transition: background-color .3s;
}

.success .close:hover {
  background-color: white;
}

.success .close:hover span {
  color: #27ae60;
}

.error .close {
  background-color: #c0392b;
  transition: color .3s;
  transition: background-color .3s;
}

.error .close:hover {
  background-color: white;
}

.error .close:hover span {
  color: #c0392b;
}
@media screen and (min-width: 600px) {

.banner2 {
  width: 50%;
  min-width: 600px;
  margin: 0 25% 32px;
}

}

@media screen and (min-width: 1100px) {

.banner {
  border-radius: 4px;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: calc(70% - 12px);
  margin-left: calc(30% + 12px);
}

}
</style>
