<template>
  <tr>
    <!-- <td>
      <img v-if="comercio.imagen != null" :src="imagenRuta" :alt="`${comercio.nombre}`">
      <img v-else src="@/assets/image-not-available.jpg" :alt="`${comercio.nombre}`">
    </td> -->
    <td>{{ fecha(pago.fecha) }}</td>
    <td>{{ pago.nombre }} {{ pago.apellido }}</td>
    <td>$ {{ dotsCadaTres(parseFloat(pago.monto).toFixed(2).replace(/\.00$/, '')) }}</td>
    <td>{{ pago.forma_de_pago }}</td>
    <td>{{ pago.moneda }}</td>
    <td>{{ pago.servicio }}</td>
    <td>{{ pago.cuota }}/{{ pago.cantidad_cuotas }} </td>
    <td>{{ pago.concepto }}</td>
    <td>{{ pago.descripcion }}</td>
    <td class="botones-acciones">
      <div class="botones-acciones-contenedor">
        <button @click="abrirModal(pago.pago_id)" class="icon icon-trash-o boton_borrar_pagos_lista boton_pagos_lista tabla-link"></button>
        <!-- <router-link :to="`/editar-pago/${pago.pago_id}`" class="icon icon-pencil-square-o boton_editar_pagos_lista boton_pagos_lista tabla-link"></router-link> -->
      </div>
      <div :class="`confirmation-${pago.pago_id}`"
        class="confirmation"
      >
        <div class="contenor_modal"
          ref="modalRef"
        >
          <p>¿Estas seguro que deseas eliminar el pago de {{ pago.nombre }} {{ pago.apellido }} por el monto de ${{ pago.monto }}?</p>
          <div class="contenor_modal_botones">
            <button @click="cerrarModal(pago.pago_id)" class="boton_cancelar boton_modal">CANCELAR</button>
            <button @click="borrarPago(pago.pago_id)" :data-id="`${pago.pago_id}`" class="boton_aceptar boton_modal">ACEPTAR</button>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
// import comerciosService from '../services/comercios'
import store from './../store'
// import { PATH_IMG } from '../constants'
import pagosService from '../services/pagos'
export default {
  name: 'PagoListaItem',
  props: ['pago'],
  data: function () {
    return {
      store
      // modalSeleccionada: null
    }
  },
  methods: {
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    },
    borrarPago (id) {
      console.log(id)
      pagosService
        .borrarPago(id)
        .then(respuesta => {
          this.$emit('refrescar')
          this.store.setStatus({
            msg: 'El pago se borro exitosamente',
            type: 'success banner2'
          })
          document.removeEventListener('mouseup', this.closeModalOnClickOutside)
        })
    },
    abrirModal (id) {
      this.$emit('refrescar2')
      console.log(id)
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'flex'
      document.addEventListener('mouseup', this.closeModalOnClickOutside)
      // this.modalSeleccionada = id
      // Pongo el body sin scroll
      const body = document.body
      body.style.overflow = 'hidden'
    },
    cerrarModal (id) {
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'none'
      document.removeEventListener('mouseup', this.closeModalOnClickOutside)
      // saco el overflow del body
      const body = document.body
      body.style.overflow = 'auto'
    },
    closeModalOnClickOutside (id) {
      console.log(id)
      const modal = this.$refs.modalRef
      console.log('click')
      if (!modal.contains(event.target)) {
        console.log('click afuera')
        document.removeEventListener('mouseup', this.closeModalOnClickOutside)
        this.cerrarModal(this.pago.pago_id)
      } else {
        console.log('click adentro')
      }
      // saco el overflow del body
      const body = document.body
      body.style.overflow = 'auto'
      // this.modalSeleccionada = null
    }
    // tipo (tipo) {
    //   switch (tipo) {
    //     case 1:
    //       return 'comercio_tipo_restaurant'
    //     case 2:
    //       return 'comercio_tipo_hotel'
    //     case 3:
    //       return 'comercio_tipo_bar'
    //   }
    //   return 'comercio_tipo_restaurant'
    // }
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        console.log('uuu')
        const confirmation = document.querySelector('.confirmation-' + this.pago.pago_id)
        confirmation.style.display = 'none'
      }
    })
  }
}
</script>

<style>

</style>
