<template>
  <div class="home">
    <BaseNotification2
      v-if="store.status.msg != null"
      :type="store.status.type"
      :text="store.status.msg"
      :closable="store.status.closable"
      @close="borrarNotificacion()"
    />
    <LoginForm/>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue'
import BaseNotification2 from '../components/BaseNotification2'
import store from './../store'
export default {
  name: 'Home',
  components: {
    LoginForm,
    BaseNotification2
  },
  data: function () {
    return {
      store
    }
  },
  methods: {
    borrarNotificacion () {
      this.store.setStatus({
        msg: null,
        type: null,
        title: null
      })
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.borrarNotificacion()
    }, 4000)
  }
}
</script>
