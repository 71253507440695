<template>
  <div class="form_section">
    <label :for="`stock-${persona.persona_id}`">{{ persona.nombre }} {{ persona.apellido }}</label>
    <input
      type="number"
      :id="`stock-${persona.persona_id}`"
      name="stock"
      v-model="persona.stock_individual"
      :disabled="cargando"
    >
    <div class="boton-borrar-persona" @click="borrarPersona(persona.personas_tienen_productos_id)" >X</div>
    <!-- <div
    class="form_mensaje_error"
      v-if="errores.stock !== null"
    >
      {{ errores.stock[0] }}
    </div> -->
  </div>
</template>

<script>
import serviciossService from '../services/servicios'
// import StockListaItemPersona from '../components/StockListaItemPersona.vue'
// import store from './../store'
// import { PATH_IMG } from '../constants'
// console.log('servicio', this.servicio)
export default {
  name: 'EditarStockFormItem',
  components: {
    // StockListaItemPersona
  },
  props: ['persona'],
  data: function () {
    return {
      pago: {
        stock: null
      },
      cargando: false
    }
  },
  methods: {
    borrarPersona (id) {
      console.log('qqqqq')
      console.log(id)
      serviciossService
        .borrarPersonaConStockPorId(id)
        .then(respuesta => {
          this.$emit('refrescar')
          // this.cargando = false
          // this.servicio = respuesta
          // console.log('Servicio', respuesta)
        })
    }
  }
}
</script>

<style>

.boton-borrar-persona {
  padding: 8px;
  border-radius: 50%;
  background-color: red;
  color: white;
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  text-align: center;
  line-height: 10px;
  border: 1px solid red;
}

.boton-borrar-persona:hover {
  background-color: white;
  color: red;
}

</style>
