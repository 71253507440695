<template>
  <div class="contenedor-editar-datos-socio">
    <div class="contenedor-titulo">
      <h1>Editar Datos del Socio</h1>
      <div class="barra"></div>
    </div>
    <router-link :to="`/socios`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    <section>
      <div class="contenedor-editar-datos-socio-izquierda">
        <img src="@/assets/patanjali.jpg" alt="Patanjali">
      </div>
      <div class="contenedor-editar-datos-socio-derecha">
        <div class="contenedor-editar-datos-socio-nombre">
          <div class="imagen-editar-datos-socio">
            <img v-if="socio.imagen_perfil != null" :src="imagenRuta" :alt="`${socio.nombre}`">
            <img v-else src="@/assets/image-not-available.jpg" alt="Imagen no disponible">
          </div>
          <h2>{{socio.nombre}} {{socio.apellido}}</h2>
        </div>
        <form
          action="#"
          method="post"
          @submit.prevent="editarSocio(socio, socio.persona_id)"
          class="form_editar_socio"
        >
        <div class="form_section_editar_socio">
          <label for="fecha_ingreso">Fecha de Ingreso</label>
          <input
            type="date"
            name="fecha_ingreso"
            id="fecha_ingreso"
            v-model="socio.fecha_ingreso"
            :disabled="cargando"
          >
          <!-- <div
          class="form_editar_mensaje_error"
            v-if="errores.nombre !== null"
          >
            {{ errores.nombre[0] }}
          </div> -->
        </div>
        <div class="form_section_editar_socio">
          <label for="socio_id">Estado</label>
          <select
            id="socio_id"
            name="socio_id"
            class="form minimal"
            v-model.number="socio.socio_id"
            :disabled="cargando"
          >
            <option
              v-for="estado in estados"
              :value="estado.socio_id"
              :key="estado.socio_id"
            >
              {{ estado.nombre }}
            </option>
          </select>
          <!-- <div
          class="form_mensaje_error"
            v-if="errores.perfil_id !== null"
          >
            {{ errores.perfil_id[0] }}
          </div> -->
        </div>
        <div class="form_section_editar_socio">
          <label for="socio_id">Condición</label>
          <select
            id="condicion_id"
            name="condicion_id"
            class="form minimal"
            v-model.number="socio.condicion_id"
            :disabled="cargando"
          >
            <option
              v-for="condicion in condiciones"
              :value="condicion.condicion_id"
              :key="condicion.condicion_id"
            >
              {{ condicion.nombre }}
            </option>
          </select>
          <!-- <div
          class="form_mensaje_error"
            v-if="errores.perfil_id !== null"
          >
            {{ errores.perfil_id[0] }}
          </div> -->
        </div>
        <div class="form_section_editar_socio">
          <label for="perfil_id">Perfil</label>
          <select
            id="perfil_id"
            name="perfil_id"
            class="form minimal"
            v-model.number="socio.perfil_id"
            :disabled="cargando"
          >
            <option
              v-for="perfil in perfiles"
              :value="perfil.perfil_id"
              :key="perfil.perfil_id"
            >
              {{ perfil.nombre }}
            </option>
          </select>
          <!-- <div
          class="form_mensaje_error"
            v-if="errores.perfil_id !== null"
          >
            {{ errores.perfil_id[0] }}
          </div> -->
        </div>
        <div
          class="form_section_editar_socio"
          v-if="mostrarCampo"
        >
          <label for="certificacion_id">Certificación</label>
          <select
            id="certificacion_id"
            name="certificacion_id"
            class="form minimal"
            v-model.number="socio.certificacion_id"
            :disabled="cargando"
          >
            <option
              v-for="certificacion in certificaciones"
              :value="certificacion.certificacion_id"
              :key="certificacion.certificacion_id"
            >
              {{ certificacion.nombre }}
            </option>
          </select>
          <!-- <div
          class="form_mensaje_error"
            v-if="errores.certificacion_id !== null"
          >
            {{ errores.certificacion_id[0] }}
          </div> -->
        </div>
        <div
          class="form_section_editar_socio"
          v-if="mostrarCampo"
        >
          <label for="certificacion_id">Activo en Web</label>
          <select
            id="web_id"
            name="web_id"
            class="form minimal"
            v-model.number="socio.web_id"
            :disabled="cargando"
          >
            <option
              v-for="estado in web"
              :value="estado.web_id"
              :key="estado.web_id"
            >
              {{ estado.nombre }}
            </option>
          </select>
          <!-- <div
          class="form_mensaje_error"
            v-if="errores.certificacion_id !== null"
          >
            {{ errores.certificacion_id[0] }}
          </div> -->
        </div>
        <div class="form_section_editar_socio">
          <label for="tipo_id">Tipo</label>
          <select
            id="tipo_id"
            name="tipo_id"
            class="form minimal"
            v-model.number="socio.tipo_id"
            :disabled="cargando"
          >
            <option
              v-for="tipo in tipos"
              :value="tipo.tipo_id"
              :key="tipo.tipo_id"
            >
              {{ tipo.nombre }}
            </option>
          </select>
        </div>
        <div class="form_section_editar_socio">
          <label for="dni">DNI</label>
          <input
            type="text"
            name="dni"
            id="dni"
            v-model="socio.dni"
            :disabled="cargando"
          >
          <div
            class="form_editar_mensaje_error"
            v-if="errores.dni !== null"
          >
            {{ errores.dni[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            v-model="socio.email"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.email !== null"
          >
            {{ errores.email[0] }}
          </div>
        </div>
        <button type="submit">Editar Socio</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import asociadoService from '../services/asociado'
import store from './../store'
import { PATH_IMG } from '../constants'
import personasService from '../services/personas'
export default {
  name: 'Socio',
  components: {
  },
  data: function () {
    return {
      socio: [],
      perfiles: [],
      certificaciones: [],
      estados: [],
      condiciones: [],
      tipos: [],
      web: [],
      cuotasPagas: [],
      cuotasImpagas: [],
      regaliasImpagas: [],
      cargando: false,
      center: {
        lat: null,
        lng: null
      },
      errores: {
        email: null,
        dni: null
      },
      mapZoom: 15,
      markers: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      store
    }
  },
  methods: {
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    },
    editarSocio (socio, id) {
      console.log(id)
      console.log('voy a editar: ', socio)
      this.socio.direccion = 'editarDatosSocio'
      personasService
        .editarSocioPorId(socio, id)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('oKKKKKK')
            // this.socio = {
            //   nombre: null,
            //   apellido: null,
            //   estudio_nombre_1: null,
            //   estudio_nombre_2: null,
            //   email: null,
            //   dni: null,
            //   website: null,
            //   telefono: null,
            //   celular: null,
            //   direccion_1: null,
            //   ciudad_1: null,
            //   latitude_1: null,
            //   longitude_1: null,
            //   provincia_id_1: null,
            //   pais_id_1: null,
            //   ciudad_2: null,
            //   direccion_2: null,
            //   latitude_2: null,
            //   longitude_2: null,
            //   provincia_id_2: null,
            //   pais_id_2: null,
            //   imagen_perfil: null,
            //   facebook: null,
            //   twitter: null,
            //   instagram: null
            // }
            this.store.setStatus({
              msg: 'El socio se editó exitosamente',
              type: 'success'
            })
            this.$router.push('/socios')
          } else {
            this.errores = {
              email: null,
              dni: null,
              ...respuesta.errors
            }
          }
        })
    },
    refrescarProfesores () {
      // comerciosService
      //   .traerComerciosTodos()
      //   .then(respuesta => {
      //     this.cargando = false
      //     this.comercios = respuesta
      //     console.log('Todas los comercios', respuesta)
      //   })
    }
  },
  computed: {
    imagenRuta: function () {
      return `${PATH_IMG}/${this.socio.imagen_perfil}`
    },
    mostrarCampo: function () { // Se musetra solo si es PROFESOR
      switch (this.socio.perfil_id) {
        case 1:
          return true
        default:
          return false
      }
    }
  },
  mounted: function () {
    this.cargando = true
    asociadoService
      .traerSocioPorId(this.$route.params.id)
      .then(respuesta => {
        this.cargando = false
        this.socio = respuesta
        this.center.lat = parseFloat(respuesta.latitude_1)
        this.center.lng = parseFloat(respuesta.longitude_1)
        this.markers[0].position.lat = parseFloat(respuesta.latitude_1)
        this.markers[0].position.lng = parseFloat(respuesta.longitude_1)
        console.log('Socio:', respuesta)
        // console.log('LAT:', respuesta.latitude_1
      })
    // pagosService
    //   .traerCuotasPagasPorAsociadoId(this.$route.params.id)
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.cuotasPagas = respuesta
    //     console.log('Cuotas Pagas:', respuesta)
    //   })
    // pagosService
    //   .traerCuotasImpagasPorAsociadoId(this.$route.params.id)
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.cuotasImpagas = respuesta
    //     console.log('Cuotas Impagas:', respuesta)
    //   })
    // pagosService
    //   .traerRegaliasImpagasPorAsociadoId(this.$route.params.id)
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.regaliasImpagas = respuesta
    //     console.log('Regalias Impagas:', respuesta)
    //   })
    personasService
      .traerPerfilesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.perfiles = respuesta
      })
    personasService
      .traerCertificacionesTodas()
      .then(respuesta => {
        // this.cargando = false
        this.certificaciones = respuesta
      })
    personasService
      .traerEstadosTodos()
      .then(respuesta => {
        // this.cargando = false
        this.estados = respuesta
      })
    personasService
      .traerCondicionesTodas()
      .then(respuesta => {
        // this.cargando = false
        this.condiciones = respuesta
      })
    personasService
      .traerTiposTodos()
      .then(respuesta => {
        // this.cargando = false
        this.tipos = respuesta
      })
    personasService
      .traerEstadosWebTodos()
      .then(respuesta => {
        // this.cargando = false
        this.web = respuesta
      })
  }
}
</script>
