<template>
  <div class="container">
    <!-- <BaseLoader
      v-if="cargando"
    /> -->
    <!-- <table v-else> -->
    <table id="tabla-stock">
      <thead>
        <!-- <th class="nofuente">IMAGEN</th> -->
        <th>Nombre</th>
        <th>Servicio</th>
        <th>Stock</th>
        <th class="no-borde">Distribución</th>
        <!-- <th>Certificación</th> -->
        <!-- <th>Condición</th> -->
        <th class="nofuente gradient_2">ACCIONES</th>
      </thead>
      <tbody>
        <StockListaItem
          v-for="servicio in servicios"
          :servicio="servicio"
          :key="servicio.servicio_id"
          @refrescar="refrescarStock"
        ></StockListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import StockListaItem from '../components/StockListaItem.vue'
// import Header2 from '../components/Header2.vue'
import serviciossService from '../services/servicios'
export default {
  name: 'StockLista',
  components: {
    StockListaItem
    // Header2
  },
  data: function () {
    return {
      servicios: []
    }
  },
  methods: {
    refrescarStock () {
      serviciossService
        .traerServiciosTodosConStock()
        .then(respuesta => {
          // this.cargando = false
          this.servicios = respuesta
          console.log('Todas los servicios', respuesta)
        })
    }
  },
  mounted: function () {
    // this.cargando = true
    serviciossService
      .traerServiciosTodosConStock()
      .then(respuesta => {
        // this.cargando = false
        this.servicios = respuesta
        console.log('Todas los serviciosee', respuesta)
      })
  }
}
</script>

<style>

#tabla-stock {
/*  font-family: Arial, Helvetica, sans-serif;*/
  border-collapse: collapse;
  width: 100%;
}

#tabla-stock td, #tabla-stock th {
  border: 1px solid #ddd;
  padding: 8px;
}

/*#tabla-stock tr:nth-child(even){background-color: #f2f2f2;}*/

/*#tabla-stock tr:hover {background-color: #ddd;}*/

#tabla-stock th {
  padding-top: 12px;
  padding-bottom: 12px;
/*  text-align: left;*/
  background-color: #04AA6D;
  color: white;
}

#tabla-stock td table {
  border-collapse: collapse;
  width: 100%;
}

.no-borde {
  border-bottom: 0 !important;
}
</style>
