<template>
  <div>
    <h1>Crear Servicio</h1>
    <div>
      <form
        action="#"
        method="post"
        @submit.prevent="crearServicio(servicio)"
        class="form_presupuesto_nuevo"
      >
        <div class="form_section">
          <label for="nombre">Nombre</label>
          <input
            type="text"
            name="nombre"
            id="nombre"
            v-model="servicio.nombre"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.nombre !== null"
          >
            {{ errores.nombre[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="descripcion">Descripción</label>
          <textarea
            name="descripcion"
            id="descripcion"
            v-model.number="servicio.descripcion"
          ></textarea>
        </div>
        <div class="form_section">
          <label for="stock">Stock</label>
          <input
            type="text"
            name="stock"
            id="stock"
            v-model="servicio.stock"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.stock !== null"
          >
            {{ errores.stock[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="categoria_id">Categoría</label>
          <!-- <v-select :options="categorias" label="title"></v-select> -->
          <select
            id="categoria_id"
            name="categoria_id"
            class="form minimal"
            v-model.number="servicio.categoria_id"
            :disabled="cargando"
            @change="onChangeCategoria($event)"
          >
            <option
              v-for="categoria in categorias"
              :value="categoria.categoria_id"
              :key="categoria.categoria_id"
            >
              {{ categoria.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.categoria_id !== null"
          >
            {{ errores.categoria_id[0] }}
          </div>
        </div>
        <div class="form_section" id="year">
          <label for="year_id">Año</label>
          <select
            id="year_id"
            name="year_id"
            class="form minimal"
            v-model.number="servicio.year_id"
            :disabled="cargando"
          >
            <option
              v-for="concepto in years"
              :value="concepto.year_id"
              :key="concepto.year_id"
            >
              {{ concepto.year }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.year_id !== null"
          >
            {{ errores.year_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="moneda_id">Moneda</label>
          <select
            id="moneda_id"
            name="moneda_id"
            class="form minimal"
            v-model.number="servicio.moneda_id"
            :disabled="cargando"
          >
            <option
              v-for="concepto in monedas"
              :value="concepto.moneda_id"
              :key="concepto.moneda_id"
            >
              {{ concepto.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.moneda_id !== null"
          >
            {{ errores.moneda_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="moneda_id">Precio No Socio</label>
          <input
            type="text"
            name="precio_no_socio"
            id="precio_no_socio"
            v-model="servicio.precio_no_socio"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.precio_no_socio !== null"
          >
            {{ errores.precio_no_socio[0] }}
          </div>
        </div>
        <p>Precio para los socios</p>
        <div class="form_section">
          <label for="cuotas">Cantidad de cuotas</label>
          <!-- <v-select :options="categorias" label="title"></v-select> -->
          <select
            id="cuotas_cantidad"
            name="cuotas_cantidad"
            class="form minimal"
            v-model.number="servicio.cuotas_cantidad"
            :disabled="cargando"
            @change="onChange($event)"
          >
            <option
              v-for="pago in pagos"
              :value="pago.pago_id"
              :key="pago.pago_id"
            >
              {{ pago.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.cuotas_cantidad !== null"
          >
            {{ errores.cuotas_cantidad[0] }}
          </div>
        </div>
        <div
          v-if="mostrarCuotas"
        >
          <div
            v-for="pago in pagos2"
            :key="`precio_${pago.pago_id}`">
            <div class="form_section">
              <h2>Cuota # {{ pago.pago_id }}</h2>
              <input
                type="hidden"
                :name="`precio_id_${pago.pago_id}`"
                :id="`precio_id_${pago.pago_id}`"
                :v-model="servicio.cuotas[ pago.pago_id  - 1].pago_id"
              >
              <label :for="`precio_${pago.pago_id}`">Precio</label>
              <input
                type="text"
                :name="`precio_${pago.pago_id}`"
                :id="`precio_${pago.pago_id}`"
                v-model="servicio.cuotas[ pago.pago_id  - 1].precio"
                :disabled="cargando"
              >
              <div
              class="form_mensaje_error"
              v-if="errores['cuotas.' + numeroCuota(pago.pago_id) + '.precio'] !== null"
              >
                {{ errorPrecioCuota(pago.pago_id) }}
              </div>
            </div>
            <div class="form_section">
              <label :for="`fecha_inicio_${pago.pago_id}`">Desde</label>
              <input
                type="date"
                :name="`fecha_inicio_${pago.pago_id}`"
                :id="`fecha_inicio_${pago.pago_id}`"
                v-bind:class = "(pago.pago_id != 1)?'class_no_1':'class_si_1'"
                v-model="servicio.cuotas[ pago.pago_id  - 1].fecha_inicio"
                :disabled="pago.pago_id != 1"
              >
              <div
              class="form_mensaje_error"
              v-if="errores['cuotas.' + numeroCuota(pago.pago_id) + '.fecha_inicio'] !== null"
              >
                {{ errorFechaInicioCuota(pago.pago_id) }}
              </div>
            </div>
            <div class="form_section">
              <label :for="`fecha_finalizacion_${pago.pago_id}`">Hasta</label>
              <input
                type="date"
                :name="`fecha_finalizacion_${pago.pago_id}`"
                :id="`fecha_finalizacion_${pago.pago_id}`"
                v-model="servicio.cuotas[ pago.pago_id  - 1].fecha_finalizacion"
                :disabled="cargando"
                @change="copiarFechaMasUnDia(pago.pago_id)"
              >
              <div
              class="form_mensaje_error"
              v-if="errores['cuotas.' + numeroCuota(pago.pago_id) + '.fecha_finalizacion'] !== null"
              >
                {{ errorFechaFinalizacionCuota(pago.pago_id) }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="form_mensaje_error"
            v-if="errores.cuotas_general !== null"
          >
            {{ errores.categoria_id[0] }}
          </div> -->
        <button type="submit" class="boton_guardar">Crear Servicio</button>
      </form>
    </div>
  </div>
</template>

<script>
import serviciosService from '../services/servicios'
import pagosService from '../services/pagos'

export default {
  name: 'crearServicio',
  components: {
  },
  data: function () {
    return {
      categorias: [],
      years: [],
      monedas: [],
      pagos2: [],
      pagos: [{
        pago_id: 1,
        nombre: 1
      }, {
        pago_id: 2,
        nombre: 2
      }, {
        pago_id: 3,
        nombre: 3
      }, {
        pago_id: 4,
        nombre: 4
      }, {
        pago_id: 5,
        nombre: 5
      }, {
        pago_id: 6,
        nombre: 6
      }, {
        pago_id: 7,
        nombre: 7
      }, {
        pago_id: 8,
        nombre: 8
      }, {
        pago_id: 9,
        nombre: 9
      }, {
        pago_id: 10,
        nombre: 10
      }, {
        pago_id: 11,
        nombre: 11
      }, {
        pago_id: 12,
        nombre: 12
      }],
      cargando: false,
      servicio: {
        nombre: null,
        precio_no_socio: null,
        descripcion: null,
        // precio: null,
        stock: null,
        categoria_id: null,
        cuotas_cantidad: null,
        year_id: null
      },
      errores: {
        nombre: null,
        precio_no_socio: null,
        descripcion: null,
        moneda_id: null,
        stock: null,
        categoria_id: null,
        cuotas_cantidad: null,
        year_id: null,
        cuotas: null,
        'cuotas.0.precio': null,
        'cuotas.1.precio': null,
        'cuotas.2.precio': null,
        'cuotas.3.precio': null,
        'cuotas.4.precio': null,
        'cuotas.5.precio': null,
        'cuotas.6.precio': null,
        'cuotas.7.precio': null,
        'cuotas.8.precio': null,
        'cuotas.9.precio': null,
        'cuotas.10.precio': null,
        'cuotas.11.precio': null,
        'cuotas.0.fecha_inicio': null,
        'cuotas.1.fecha_inicio': null,
        'cuotas.2.fecha_inicio': null,
        'cuotas.3.fecha_inicio': null,
        'cuotas.4.fecha_inicio': null,
        'cuotas.5.fecha_inicio': null,
        'cuotas.6.fecha_inicio': null,
        'cuotas.7.fecha_inicio': null,
        'cuotas.8.fecha_inicio': null,
        'cuotas.9.fecha_inicio': null,
        'cuotas.10.fecha_inicio': null,
        'cuotas.11.fecha_inicio': null,
        'cuotas.0.fecha_finalizacion': null,
        'cuotas.1.fecha_finalizacion': null,
        'cuotas.2.fecha_finalizacion': null,
        'cuotas.3.fecha_finalizacion': null,
        'cuotas.4.fecha_finalizacion': null,
        'cuotas.5.fecha_finalizacion': null,
        'cuotas.6.fecha_finalizacion': null,
        'cuotas.7.fecha_finalizacion': null,
        'cuotas.8.fecha_finalizacion': null,
        'cuotas.9.fecha_finalizacion': null,
        'cuotas.10.fecha_finalizacion': null,
        'cuotas.11.fecha_finalizacion': null
      }
    }
  },
  methods: {
    copiarFechaMasUnDia (id) {
      console.log(id)
      console.log(this.servicio)
      console.log(this.servicio.cuotas[id - 1].fecha_finalizacion)
      // var dateString = this.servicio.cuotas[id - 1].fecha_finalizacion
      // var startDate = new Date(dateString)
      // // seconds * minutes * hours * milliseconds = 1 day
      // // var day = 60 * 60 * 24 * 1000;
      // var endDate = new Date(startDate.getDate() + 1)
      // var dateString = 'Mon Jun 30 2014 00:00:00'
      // var startDate = new Date(dateString)
      // var date = this.servicio.cuotas[id - 1].fecha_finalizacion
      // console.log(date)
      // console.log(date.getDate())
      console.log(new Date(new Date().setDate(new Date().getDate() + 1)))
      // console.log(new Date(new Date().setDate(endDate)))
      var date = new Date(this.servicio.cuotas[id - 1].fecha_finalizacion)
      var nextDate = new Date(date.setDate(date.getDate() + 1))
      var formatted = nextDate.getUTCFullYear() + '-' + this.padNumber(nextDate.getUTCMonth() + 1) + '-' + this.padNumber(nextDate.getUTCDate())
      console.log(formatted)
      // this.servicio.cuotas[id].fecha_inicio = this.servicio.cuotas[id - 1].fecha_finalizacion
      this.servicio.cuotas[id].fecha_inicio = formatted
      // document.querySelectorAll('.class_no_1')[id - 1].value = this.servicio.cuotas[id - 1].fecha_finalizacion
      document.querySelectorAll('.class_no_1')[id - 1].value = formatted
    },
    padNumber (number) {
      var string = '' + number
      string = string.length < 2 ? '0' + string : string
      return string
    },
    onChange (id) {
      this.errores = {
        nombre: null,
        precio_no_socio: null,
        descripcion: null,
        moneda_id: null,
        stock: null,
        cuotas_cantidad: null,
        year_id: null,
        cuotas: null,
        categoria_id: null,
        'cuotas.0.precio': null,
        'cuotas.1.precio': null,
        'cuotas.2.precio': null,
        'cuotas.3.precio': null,
        'cuotas.4.precio': null,
        'cuotas.5.precio': null,
        'cuotas.6.precio': null,
        'cuotas.7.precio': null,
        'cuotas.8.precio': null,
        'cuotas.9.precio': null,
        'cuotas.10.precio': null,
        'cuotas.11.precio': null,
        'cuotas.0.fecha_inicio': null,
        'cuotas.1.fecha_inicio': null,
        'cuotas.2.fecha_inicio': null,
        'cuotas.3.fecha_inicio': null,
        'cuotas.4.fecha_inicio': null,
        'cuotas.5.fecha_inicio': null,
        'cuotas.6.fecha_inicio': null,
        'cuotas.7.fecha_inicio': null,
        'cuotas.8.fecha_inicio': null,
        'cuotas.9.fecha_inicio': null,
        'cuotas.10.fecha_inicio': null,
        'cuotas.11.fecha_inicio': null,
        'cuotas.0.fecha_finalizacion': null,
        'cuotas.1.fecha_finalizacion': null,
        'cuotas.2.fecha_finalizacion': null,
        'cuotas.3.fecha_finalizacion': null,
        'cuotas.4.fecha_finalizacion': null,
        'cuotas.5.fecha_finalizacion': null,
        'cuotas.6.fecha_finalizacion': null,
        'cuotas.7.fecha_finalizacion': null,
        'cuotas.8.fecha_finalizacion': null,
        'cuotas.9.fecha_finalizacion': null,
        'cuotas.10.fecha_finalizacion': null,
        'cuotas.11.fecha_finalizacion': null
      }
      console.log(id.target.value)
      this.pagos2 = []
      this.servicio.cuotas = []
      for (var i = 1; i <= id.target.value; i++) {
        console.log(i)
        const obj = {
          pago_id: i,
          nombre: i
        }
        this.pagos2.push(obj)
        this.servicio.cuotas.push({
          pago_id: i,
          precio: null,
          fecha_inicio: null,
          fecha_finalizacion: null
        })
      }
    },
    onChangeCategoria (event) {
      console.log('ewewewewe')
      console.log(event.target.value)
      // console.log(event.target.name)
      // console.log(document.getElementsByClassName('year'))
      if (event.target.value === '1' || event.target.value === '2') {
        console.log(event.target.value)
        document.getElementById('year').style.display = 'flex'
      } else {
        document.getElementById('year').style.display = 'none'
      }
      // console.log(this.productos[event.target.value - 1].precio)
      // // document.getElementsByName('precio-' + numero)[0].value = this.productos[event.target.value - 1].precio
      // this.servicio.productos[numero - 1].precio = this.productos[event.target.value - 1].precio
    },
    crearServicio (servicio) {
      console.log('qqqq')
      console.log(servicio)
      if (this.cargando) {
        return null
      }
      this.errores = {
        nombre: null,
        precio_no_socio: null,
        descripcion: null,
        moneda_id: null,
        stock: null,
        cuotas_cantidad: null,
        cuotas: null,
        year_id: null,
        categoria_id: null,
        'cuotas.0.precio': null,
        'cuotas.1.precio': null,
        'cuotas.2.precio': null,
        'cuotas.3.precio': null,
        'cuotas.4.precio': null,
        'cuotas.5.precio': null,
        'cuotas.6.precio': null,
        'cuotas.7.precio': null,
        'cuotas.8.precio': null,
        'cuotas.9.precio': null,
        'cuotas.10.precio': null,
        'cuotas.11.precio': null,
        'cuotas.0.fecha_inicio': null,
        'cuotas.1.fecha_inicio': null,
        'cuotas.2.fecha_inicio': null,
        'cuotas.3.fecha_inicio': null,
        'cuotas.4.fecha_inicio': null,
        'cuotas.5.fecha_inicio': null,
        'cuotas.6.fecha_inicio': null,
        'cuotas.7.fecha_inicio': null,
        'cuotas.8.fecha_inicio': null,
        'cuotas.9.fecha_inicio': null,
        'cuotas.10.fecha_inicio': null,
        'cuotas.11.fecha_inicio': null,
        'cuotas.0.fecha_finalizacion': null,
        'cuotas.1.fecha_finalizacion': null,
        'cuotas.2.fecha_finalizacion': null,
        'cuotas.3.fecha_finalizacion': null,
        'cuotas.4.fecha_finalizacion': null,
        'cuotas.5.fecha_finalizacion': null,
        'cuotas.6.fecha_finalizacion': null,
        'cuotas.7.fecha_finalizacion': null,
        'cuotas.8.fecha_finalizacion': null,
        'cuotas.9.fecha_finalizacion': null,
        'cuotas.10.fecha_finalizacion': null,
        'cuotas.11.fecha_finalizacion': null
      }
      this.cargando = true
      serviciosService
        .crearServicio(servicio)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.servicio = {
              nombre: null,
              descripcion: null,
              precio: null,
              stock: null,
              cuotas_cantidad: null,
              cuotas: null,
              year_id: null,
              categoria_id: null
            }
            this.errores = {
              nombre: null,
              precio_no_socio: null,
              descripcion: null,
              moneda_id: null,
              stock: null,
              cuotas_cantidad: null,
              cuotas: null,
              year_id: null,
              categoria_id: null,
              'cuotas.0.precio': null,
              'cuotas.1.precio': null,
              'cuotas.2.precio': null,
              'cuotas.3.precio': null,
              'cuotas.4.precio': null,
              'cuotas.5.precio': null,
              'cuotas.6.precio': null,
              'cuotas.7.precio': null,
              'cuotas.8.precio': null,
              'cuotas.9.precio': null,
              'cuotas.10.precio': null,
              'cuotas.11.precio': null,
              'cuotas.0.fecha_inicio': null,
              'cuotas.1.fecha_inicio': null,
              'cuotas.2.fecha_inicio': null,
              'cuotas.3.fecha_inicio': null,
              'cuotas.4.fecha_inicio': null,
              'cuotas.5.fecha_inicio': null,
              'cuotas.6.fecha_inicio': null,
              'cuotas.7.fecha_inicio': null,
              'cuotas.8.fecha_inicio': null,
              'cuotas.9.fecha_inicio': null,
              'cuotas.10.fecha_inicio': null,
              'cuotas.11.fecha_inicio': null,
              'cuotas.0.fecha_finalizacion': null,
              'cuotas.1.fecha_finalizacion': null,
              'cuotas.2.fecha_finalizacion': null,
              'cuotas.3.fecha_finalizacion': null,
              'cuotas.4.fecha_finalizacion': null,
              'cuotas.5.fecha_finalizacion': null,
              'cuotas.6.fecha_finalizacion': null,
              'cuotas.7.fecha_finalizacion': null,
              'cuotas.8.fecha_finalizacion': null,
              'cuotas.9.fecha_finalizacion': null,
              'cuotas.10.fecha_finalizacion': null,
              'cuotas.11.fecha_finalizacion': null
            }
          } else {
            this.errores = {
              nombre: null,
              precio_no_socio: null,
              descripcion: null,
              moneda_id: null,
              stock: null,
              cuotas_cantidad: null,
              cuotas: null,
              year_id: null,
              categoria_id: null,
              'cuotas.0.precio': null,
              'cuotas.1.precio': null,
              'cuotas.2.precio': null,
              'cuotas.3.precio': null,
              'cuotas.4.precio': null,
              'cuotas.5.precio': null,
              'cuotas.6.precio': null,
              'cuotas.7.precio': null,
              'cuotas.8.precio': null,
              'cuotas.9.precio': null,
              'cuotas.10.precio': null,
              'cuotas.11.precio': null,
              'cuotas.0.fecha_inicio': null,
              'cuotas.1.fecha_inicio': null,
              'cuotas.2.fecha_inicio': null,
              'cuotas.3.fecha_inicio': null,
              'cuotas.4.fecha_inicio': null,
              'cuotas.5.fecha_inicio': null,
              'cuotas.6.fecha_inicio': null,
              'cuotas.7.fecha_inicio': null,
              'cuotas.8.fecha_inicio': null,
              'cuotas.9.fecha_inicio': null,
              'cuotas.10.fecha_inicio': null,
              'cuotas.11.fecha_inicio': null,
              'cuotas.0.fecha_finalizacion': null,
              'cuotas.1.fecha_finalizacion': null,
              'cuotas.2.fecha_finalizacion': null,
              'cuotas.3.fecha_finalizacion': null,
              'cuotas.4.fecha_finalizacion': null,
              'cuotas.5.fecha_finalizacion': null,
              'cuotas.6.fecha_finalizacion': null,
              'cuotas.7.fecha_finalizacion': null,
              'cuotas.8.fecha_finalizacion': null,
              'cuotas.9.fecha_finalizacion': null,
              'cuotas.10.fecha_finalizacion': null,
              'cuotas.11.fecha_finalizacion': null,
              ...respuesta.errors
            }
          }
        })
    },
    errorPrecioCuota (id) {
      const x = id - 1
      return this.errores['cuotas.' + x + '.precio'][0]
    },
    errorFechaInicioCuota (id) {
      const x = id - 1
      return this.errores['cuotas.' + x + '.fecha_inicio'][0]
    },
    errorFechaFinalizacionCuota (id) {
      const x = id - 1
      return this.errores['cuotas.' + x + '.fecha_finalizacion'][0]
    },
    numeroCuota (id) {
      const x = id - 1
      console.log('Estoy en numeroCuota ' + x)
      return x
      // return this.errores['cuotas.' + x + '.precio'][0] !== null
    }
  },
  computed: {
    mostrarCuotas: function () {
      if (this.servicio.cuotas_cantidad != null) {
        return true
      }
      return false
    }
  },
  mounted: function () {
    pagosService
      .traerMonedas()
      .then(respuesta => {
        // this.cargando = false
        this.monedas = respuesta
        console.log('Monedas: ', respuesta)
      })
    serviciosService
      .traerCategoriasTodas()
      .then(respuesta => {
        // this.cargando = false
        this.categorias = respuesta
      })
    pagosService
      .traerYearsDePago()
      .then(respuesta => {
        // this.cargando = false
        this.years = respuesta
        console.log('Years: ', respuesta)
      })
  }
}
</script>
