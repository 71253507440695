<template>
  <tr>
    <!-- <td>
      <img v-if="comercio.imagen != null" :src="imagenRuta" :alt="`${comercio.nombre}`">
      <img v-else src="@/assets/image-not-available.jpg" :alt="`${comercio.nombre}`">
    </td> -->
    <td>{{ venta.fecha_de_venta }}</td>
    <td>{{ venta.descripcion }}</td>
    <td>
      <ul>
        <VentasListaItemProductos
          v-for="producto in venta.productos"
          :producto="producto"
          :key="producto.numero_producto"
        ></VentasListaItemProductos>
      </ul>
    </td>
    <td>
      <div v-if="venta.entregas.length == 0">
        <p>La venta todavía NO fue entregada</p>
        <router-link :to="`/cargar-entrega/${venta.venta_id}`" class="boton-editar">Cargar Entrega</router-link>
      </div>
      <ul  v-else >
        <VentasListaItemEntregas
          v-for="entrega in venta.entregas"
          :entrega="entrega"
          :key="entrega.numero_entrega"
        ></VentasListaItemEntregas>
      </ul>
    </td>
  </tr>
</template>

<script>
import VentasListaItemProductos from '../components/VentasListaItemProductos.vue'
import VentasListaItemEntregas from '../components/VentasListaItemEntregas.vue'
import serviciossService from '../services/servicios'
export default {
  name: 'VentasListaItem',
  components: {
    VentasListaItemProductos,
    VentasListaItemEntregas
  },
  props: ['venta'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    borrarServicio (id) {
      console.log(id)
      serviciossService
        .borrarServicio(id)
        .then(respuesta => {
          this.$emit('refrescar')
        })
    },
    abrirModal (id) {
      console.log(id)
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'flex'
    },
    cerrarModal (id) {
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'none'
    }
    // tipo (tipo) {
    //   switch (tipo) {
    //     case 1:
    //       return 'comercio_tipo_restaurant'
    //     case 2:
    //       return 'comercio_tipo_hotel'
    //     case 3:
    //       return 'comercio_tipo_bar'
    //   }
    //   return 'comercio_tipo_restaurant'
    // }
  }
}
</script>
