<template>
  <div class="contenedor-tabla-pagos">
    <!-- <BaseLoader
      v-if="cargando"
    /> -->
    <!-- <table v-else> -->
    <table>
      <thead>
        <th>Fecha</th>
        <th>Concepto</th>
        <th>Monto</th>
        <!-- <th>Factura</th> -->
      </thead>
      <tbody>
        <HistorialPagoListaItem
          v-for="pago in historialDePagos"
          :pago="pago"
          :key="pago.servicio_id"
        ></HistorialPagoListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import HistorialPagoListaItem from '../components/HistorialPagoListaItem.vue'
// import Header2 from '../components/Header2.vue'
import pagosService from '../services/pagos'
export default {
  name: 'HistorialPagoLista',
  components: {
    HistorialPagoListaItem
    // Header2
  },
  data: function () {
    return {
      historialDePagos: [],
      user: {
        persona_id: JSON.parse(localStorage.getItem('userData')).persona_id
      }
    }
  },
  mounted: function () {
    pagosService
      .traerHistorialPagosPorAsociadoId(this.user.persona_id)
      .then(respuesta => {
        this.historialDePagos = respuesta
        console.log('Historial de Pagos:', respuesta)
      })
  }
}
</script>
