<template>
  <div class="contenedor-cpanel">
    <div class="contenedor-titulo">
      <h1>Editar Pago</h1>
      <div class="barra"></div>
    </div>
    <div class="contenedor_editar_pago">>
      <router-link :to="`/listar-pagos`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
      <form
        action="#"
        method="post"
        @submit.prevent="editarPago(pago, pago.pago_id)"
        class="form_presupuesto_nuevo"
      >
        <div class="form_section">
          <label for="fecha_de_pago">Fecha del pago</label>
          <input
            type="date"
            name="fecha_de_pago"
            id="fecha_de_pago"
            v-model="pago.fecha_de_pago"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error_editar_pago"
            v-if="errores.fecha_de_pago !== null"
          >
            {{ errores.fecha_de_pago[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="precio_total">Monto del pago</label>
          <input
            type="text"
            name="precio_total"
            id="precio_total"
            v-model="pago.precio_total"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error_editar_pago"
            v-if="errores.precio_total !== null"
          >
            {{ errores.precio_total[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="persona_id">Persona</label>
          <select
            id="persona_id"
            name="persona_id"
            class="form minimal"
            v-model.number="pago.persona_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in personas"
              :value="pago.persona_id"
              :key="pago.persona_id"
            >
              {{ pago.nombre }} {{ pago.apellido }}
            </option>
          </select>
          <div
          class="form_mensaje_error_editar_pago"
            v-if="errores.persona_id !== null"
          >
            {{ errores.persona_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="pago_id">Forma de Pago</label>
          <select
            id="forma_de_pago_id"
            name="forma_de_pago_id"
            class="form minimal"
            v-model.number="pago.forma_de_pago_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in formasDePago"
              :value="pago.forma_de_pago_id"
              :key="pago.forma_de_pago_id"
            >
              {{ pago.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error_editar_pago"
            v-if="errores.forma_de_pago_id !== null"
          >
            {{ errores.forma_de_pago_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="pago_id">Moneda</label>
          <select
            id="moneda_id"
            name="moneda_id"
            class="form minimal"
            v-model.number="pago.moneda_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in monedas"
              :value="pago.moneda_id"
              :key="pago.moneda_id"
            >
              {{ pago.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error_editar_pago"
            v-if="errores.moneda_id !== null"
          >
            {{ errores.moneda_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="pago_id">Concepto de Pago</label>
          <select
            id="concepto_id"
            name="concepto_id"
            class="form minimal"
            v-model.number="pago.concepto_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in conceptos"
              :value="pago.concepto_id"
              :key="pago.concepto_id"
            >
              {{ pago.nombre }}
            </option>
          </select>
          <div
          class="form_mensaje_error_editar_pago"
            v-if="errores.concepto_id !== null"
          >
            {{ errores.concepto_id[0] }}
          </div>
        </div>
        <div
          class="form_section"
          v-if="mostrarCampoYear"
        >
          <label for="pago_id">Año del Pago</label>
          <select
            id="year_id"
            name="year_id"
            class="form minimal"
            v-model.number="pago.year_id"
            :disabled="cargando"
          >
            <option
              v-for="pago in years"
              :value="pago.year_id"
              :key="pago.year_id"
            >
              {{ pago.year }}
            </option>
          </select>
          <div
          class="form_mensaje_error_editar_pago"
            v-if="errores.year_id !== null"
          >
            {{ errores.year_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="descripcion">Descripción</label>
          <textarea
            name="descripcion"
            id="descripcion"
            v-model.number="pago.descripcion"
          ></textarea>
        </div>
        <button type="submit" class="boton_guardar">EDITAR PAGO</button>
      </form>
    </div>
  </div>
</template>

<script>
import pagosService from '../services/pagos'
// import Menu from '../components/Menu.vue'
// import Footer from '../components/Footer.vue'
import store from '../store'
export default {
  name: 'EditarPago',
  components: {
  },
  data: function () {
    return {
      conceptos: [],
      years: [],
      formasDePago: [],
      personas: [],
      monedas: [],
      cargando: false,
      // show: true,
      // seleccionado: 5,
      pago: {
        precio_total: null,
        fecha_de_pago: null,
        concepto_id: null,
        forma_de_pago_id: null,
        persona_id: null,
        year_id: null,
        moneda_id: null,
        descripcion: null
      },
      errores: {
        nombre: null,
        precio_total: null,
        fecha_de_pago: null,
        year_id: null,
        concepto_id: null,
        forma_de_pago_id: null,
        persona_id: null,
        moneda_id: null,
        descripcion: null
      },
      store
    }
  },
  methods: {
    editarPago (pago, id) {
      console.log(id)
      console.log(pago)
      //   if (this.cargando) {
      //     return null
      //   }
      //   this.errores = {
      //     nombre: null,
      //     monto: null,
      //     fecha_inicio: null,
      //     fecha_finalizacion: null
      //   }
      //   this.cargando = true
      pagosService
        .editarPagoPorId(pago, id)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.pago = {
              precio_total: null,
              fecha_de_pago: null,
              concepto_id: null,
              forma_de_pago_id: null,
              persona_id: null,
              year_id: null,
              moneda_id: null,
              descripcion: null
            }
            this.errores = {
              nombre: null,
              precio_total: null,
              fecha_de_pago: null,
              year_id: null,
              concepto_id: null,
              forma_de_pago_id: null,
              persona_id: null,
              moneda_id: null,
              descripcion: null
            }
            this.store.setStatus({
              msg: 'El pago se edito exitosamente',
              type: 'success banner2'
            })
            this.$router.push('/listar-pagos')
          } else {
            this.errores = {
              nombre: null,
              precio_total: null,
              fecha_de_pago: null,
              year_id: null,
              concepto_id: null,
              forma_de_pago_id: null,
              persona_id: null,
              moneda_id: null,
              descripcion: null,
              ...respuesta.errors
            }
          }
        })
    }
  },
  mounted: function () {
    pagosService
      .traerPago(this.$route.params.id)
      .then(respuesta => {
        console.log(respuesta)
        console.log(respuesta.years.length)
        // this.cargando = false
        this.pago = respuesta
        if (respuesta.years.length !== 0) {
          this.pago.year_id = respuesta.years[0].year_id
        }
      })
    pagosService
      .traerConceptosDePago()
      .then(respuesta => {
        // this.cargando = false
        this.conceptos = respuesta
      })
    pagosService
      .traerYearsDePago()
      .then(respuesta => {
        // this.cargando = false
        this.years = respuesta
      })
    pagosService
      .traerFormasDePago()
      .then(respuesta => {
        // this.cargando = false
        this.formasDePago = respuesta
      })
    pagosService
      .traerMonedas()
      .then(respuesta => {
        // this.cargando = false
        this.monedas = respuesta
      })
    pagosService
      .traerPersonas()
      .then(respuesta => {
        // this.cargando = false
        this.personas = respuesta
        console.log('Personas: ', respuesta)
      })
  },
  computed: {
    mostrarCampoYear: function () {
      // if (this.pago.concepto_id === 1 ) {
      //   return true
      // }
      // return false
      switch (this.pago.concepto_id) {
        case 1:
          return true
        case 2:
          return true
        case 3:
          return true
        default:
          return false
      }
    }
  }
}
</script>
