<template>
  <div class="container">
    <!-- <BaseLoader
      v-if="cargando"
    /> -->
    <!-- <table v-else> -->
    <table id="tabla-servicios">
      <thead>
        <!-- <th class="nofuente">IMAGEN</th> -->
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Categoría</th>
        <th>Precio NO Socio</th>
        <th>Moneda</th>
        <th>Precio Socio</th>
        <th class="nofuente gradient_2">ACCIONES</th>
      </thead>
      <tbody>
        <ServiciosListaItem
          v-for="servicio in servicios"
          :servicio="servicio"
          :key="servicio.servicio_id"
        ></ServiciosListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import ServiciosListaItem from '../components/ServiciosListaItem.vue'
// import Header2 from '../components/Header2.vue'
import serviciossService from '../services/servicios'
export default {
  name: 'ServiciosLista',
  components: {
    ServiciosListaItem
    // Header2
  },
  data: function () {
    return {
      servicios: []
    }
  },
  // methods: {
  //   refrescarStock () {
  //     serviciossService
  //       .traerServiciosTodosConStock()
  //       .then(respuesta => {
  //         // this.cargando = false
  //         this.servicios = respuesta
  //         console.log('Todas los servicios', respuesta)
  //       })
  //   }
  // },
  mounted: function () {
    // this.cargando = true
    serviciossService
      .traerServiciosTodosConCuota()
      .then(respuesta => {
        // this.cargando = false
        this.servicios = respuesta
        console.log('Todas los serviciose', respuesta)
      })
  }
}
</script>
