<template>
  <section class="contenedor_editar_socio" id="contenedor">
    <div class="contenedor-titulo">
      <h1>Editar Socio</h1>
      <div class="barra"></div>
    </div>
    <BaseLoader
        v-if="loader"
      />
    <div class="contenedor_editar_socio_izquierda" v-if="!loader">
      <router-link :to="`/datos-personales`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
      <button @click="mostrarModalEdiraPassword()" class="boton_editar_password boton_editar_password_2">Editar Contraseña</button>
      <form
        action="#"
        method="post"
        @submit.prevent="editarSocio(socio, socio.persona_id)"
        class="form_editar_socio"
      >
        <div class="imagen_previsualizacion">
          <img v-if="socio.imagen_perfil != null && socio.imagen_perfil.length < 20" :src="imagenRuta" :alt="`${socio.nombre}`">
          <img v-else-if="socio.imagen_perfil != null && socio.imagen_perfil.length > 20" :src="socio.imagen_perfil" alt="Imagen seleccionada del usuario">
          <img v-else src="@/assets/image-not-available.jpg" :alt="`${socio.nombre}`">
        </div>
        <div class="contenedor-boton-file">
          <label for="imagen" class="boton_cambiar_foto">Cambiar Foto</label>
            <input
              type="file"
              accept=".png,.jpg,.jpeg,.PNG,.JPG,.JPEG"
              id="imagen"
              ref="imagen"
              @change="encodeImage"
            >
        </div>
        <div class="form_section_editar_socio">
          <label for="nombre">Nombre</label>
          <input
            type="text"
            name="nombre"
            id="nombre"
            v-model="socio.nombre"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.nombre !== null"
          >
            {{ errores.nombre[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="apellido">Apellido</label>
          <input
            type="text"
            name="apellido"
            id="apellido"
            v-model="socio.apellido"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.apellido !== null"
          >
            {{ errores.apellido[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            v-model="socio.email"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.email !== null"
          >
            {{ errores.email[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="telefono">Teléfono</label>
          <input
            type="text"
            name="telefono"
            id="telefono"
            v-model="socio.telefono"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.telefono !== null"
          >
            {{ errores.telefono[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="celular">Celular</label>
          <input
            type="text"
            name="celular"
            id="celular"
            v-model="socio.celular"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.celular !== null"
          >
            {{ errores.celular[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="dni">DNI</label>
          <input
            type="text"
            name="dni"
            id="dni"
            v-model="socio.dni"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.dni !== null"
          >
            {{ errores.dni[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="toma_clase">Mi profe es</label>
          <!-- <input
            type="text"
            name="toma_clase"
            id="toma_clase"
            v-model="socio.toma_clase"
            :disabled="cargando"
          > -->
          <!-- <select
            id="toma_clase"
            name="toma_clase"
            class="form minimal"
            v-model.number="socio.toma_clase"
            :disabled="cargando"
          >
            <option
              v-for="profesor in profesores"
              :value="profesor.persona_id"
              :key="profesor.persona_id"
            >
              {{ profesor.nombre }} {{ profesor.apellido }}
            </option>
          </select> -->
          <div class="contenedor_select_buscador">
            <v-select
              class="select_buscador minimal"
              v-model.number="socio.toma_clase"
              label="item_data"
              :reduce="profesores => profesores.persona_id"
              :options="profesores"
            >
              <span slot="no-options" @click="$refs.select.open = false" class="select_buscador_warning">
                <p>El profe ingresado no figura en nuestros registros. <br> Por favor selecccione la opción <span>* Otro *</span> y envianos un email a <br> <a href="mailto:consultas@asociacioniyengar.com.ar" target="_blank">consultas@asociacioniyengar.com.ar</a> <br> con el nombre del profe.</p>
              </span>
            </v-select>
          </div>
          <!-- <div
          class="form_editar_mensaje_error"
            v-if="errores.dni !== null"
          >
            {{ errores.dni[0] }}
          </div> -->
        </div>
        <div class="form_section_editar_socio">
          <label for="website" class="tooltip">
            <span class="tooltiptext color-naranja">Website</span>
            <!-- <span class="no_mostrar">Website</span> -->
            <div class="social-icon icon-web web"></div>
          </label>
          <input
            type="text"
            name="website"
            id="website"
            v-model="socio.website"
            placeholder="Ingresa toda la URL Ej:https://www.juanadiazyoga.com.ar"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.website !== null"
          >
            {{ errores.website[0] }}
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="facebook" class="tooltip">
            <span class="tooltiptext color-facebook">Facebook</span>
            <!-- <span class="no_mostrar">Facebook</span> -->
            <div class="social-icon icon-facebook facebook"></div>
          </label>
          <input
            type="text"
            id="facebook"
            name="facebook"
            v-model="socio.facebook"
            placeholder="Ingresa toda la URL Ej:https://www.facebook.com/juana.diaz"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.facebook !== null"
          >
            <span v-html="errores.facebook[0]"></span>
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="twitter" class="tooltip">
            <span class="tooltiptext color-twitter ">Twitter</span>
            <!-- <span class="no_mostrar">Twitter</span> -->
            <div class="social-icon icon-social-twitter twitter"></div>
          </label>
          <input
            type="text"
            id="twitter"
            name="twitter"
            v-model="socio.twitter"
            placeholder="Ingresa toda la URL Ej:https://twitter.com/juanadiaz"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.twitter !== null"
          >
            <span v-html="errores.twitter[0]"></span>
          </div>
        </div>
        <div class="form_section_editar_socio">
          <label for="instagram" class="tooltip">
            <span class="tooltiptext color-instagram ">Instagram</span>
            <!-- <span class="no_mostrar">Instagram</span> -->
            <div class="social-icon icon-instagrem instagram"></div>
          </label>
          <input
            type="text"
            id="instagram"
            name="instagram"
            v-model="socio.instagram"
            placeholder="Ingresa toda la URL Ej:https://www.instagram.com/juanadiaz"
            :disabled="cargando"
          >
          <div
          class="form_editar_mensaje_error"
            v-if="errores.instagram !== null"
          >
            <span v-html="errores.instagram[0]"></span>
          </div>
        </div>
        <div class="contenedor_editar_direccion" v-if="mostrarContenedorDireccion1">
          <div class="form_section_editar_socio form_section_editar_direccion" v-if="socio.perfil_id == 1">
            <label for="estudio_nombre_1" class="label_nombre_estudio">Nombre del estudio #1</label>
            <input
              type="text"
              name="estudio_nombre_1"
              id="estudio_nombre_1"
              v-model="socio.estudio_nombre_1"
              :disabled="cargando"
            >
            <div
            class="form_editar_mensaje_error"
              v-if="errores.estudio_nombre_1 !== null"
            >
              {{ errores.estudio_nombre_1[0] }}
            </div>
          </div>
          <div class="form_section_editar_socio_direccion form_section_editar_direccion" v-if="mostrarTextoDireccion1">
            <span v-if="socio.perfil_id == 1">Domicilio #1</span>
            <span v-else>Domicilio</span>
            <span>{{ direccion.direccion }}<br>{{ direccion.ciudad }}<br>{{ direccion.provincia }}<br>{{ direccion.pais }}</span>
            <div class="contenedor_botones_direccion">
              <div class="tooltip">
                <button type="button" @click="agregarDireccion(1)" class="boton_direccion boton_editar social-icon icon-pencil-square-o"></button>
                <span class="tooltiptext2 color-azul boton_editar_hover">Editar</span>
              </div>
              <div class="tooltip">
                <button type="button" @click="mostrarModalBorrarDireccion(1)" class="boton_direccion boton_borrar social-icon icon-trash-o" v-if="socio.direccion_1 !== null"></button>
                <span class="tooltiptext2 color-rojo boton_borrar_hover">Borrar</span>
              </div>
            </div>
          </div>
          <div v-if="mostrarAutocompleteDireccion1">
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="autocomplete" class="nueva_direccion" v-if="this.socio.perfil_id == 1">Ingrese el nuevo domicilio #1</label>
              <label for="autocomplete" class="nueva_direccion" v-else>Ingrese su domicilio</label>
              <GmapAutocomplete
                @place_changed="setPlace"
                id="autocomplete"
                class="autocomplete"
                :options="autocompleteOptions"
                placeholder=""
              />
            </div>
          </div>
          <div class="texto_confirmacion_direccion">
            <p v-if="mostrarConfirmacionDireccion1">Por favor revisa el mapa y en caso de OK presionar CONTINUAR, caso contrario CANCELAR</p>
            <div class="contenedor_mapa_confirmacion_direccion"  v-if="mostrarConfirmacionDireccionMapa1">
              <gmap-map
                :center="center3"
                :zoom="mapZoom"
                map-type-id="roadmap"
                :options="{
                  mapTypeControl: false,
                  keyboardShortcuts: false,
                  // mapData: false,
                  streetViewControl: false,
                  disableDefaultUI: true,
                  styles: styles['hide'] // Saco todos los iconos de los comercios
                }"
              >
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in markers3"
                  :position="m.position"
                  :clickable="false"
                  :draggable="false"
                  :icon="require('@/assets/pin.png')"
                ></gmap-marker>
              </gmap-map>
            </div>
            <div v-if="mostrarConfirmacionDireccion1" class="confirmacion_direccion_contenedor_botones">
              <button type="button" @click="direccionIncorrecta(1)" class="boton_texto_confirmacion boton_texto_confirmacion_cancelar">CANCELAR</button>
              <button type="button" @click="direccionCorrecta(1)" class="boton_texto_confirmacion boton_texto_confirmacion_continuar">CONTINUAR</button>
            </div>
          </div>
          <div v-if="mostrarCamposDireccion1" class="campos_nueva_direccion">
            <p class="texto_confirmacion_direccion_campos">Por favor revisar y editar (en caso de que sea necesario) los campos correspondientes a la dirección</p>
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="direccion_1">Dirección</label>
              <input
                type="text"
                name="direccion_1"
                id="direccion_1"
                v-model="socio.direccion_1"
                :disabled="cargando"
              >
              <div
              class="form_editar_mensaje_error"
                v-if="errores.direccion_1 !== null"
              >
                {{ errores.direccion_1[0] }}
              </div>
            </div>
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="ciudad_1">Ciudad / Localidad / Barrio</label>
              <input
                type="text"
                name="ciudad_1"
                id="ciudad_1"
                v-model="socio.ciudad_1"
                :disabled="cargando"
              >
              <div
              class="form_editar_mensaje_error"
                v-if="errores.ciudad_1 !== null"
              >
                {{ errores.ciudad_1[0] }}
              </div>
            </div>
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="provincia_id_1">Provincia</label>
              <select
                id="provincia_id_1"
                name="provincia_id_1"
                class="form minimal"
                v-model.number="socio.provincia_id_1"
                :disabled="cargando"
              >
                <option
                  v-for="provincia in provincias"
                  :value="provincia.provincia_id"
                  :key="provincia.provincia_id"
                >
                  {{ provincia.nombre }}
                </option>
              </select>
              <div
              class="form_editar_mensaje_error"
                v-if="errores.provincia_id_1 !== null"
              >
                {{ errores.provincia_id_1[0] }}
              </div>
            </div>
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="pais_id_1">País</label>
              <select
                id="pais_id_1"
                name="pais_id_1"
                class="form minimal"
                v-model.number="socio.pais_id_1"
                :disabled="cargando"
              >
                <option
                  v-for="pais in paises"
                  :value="pais.pais_id"
                  :key="pais.pais_id"
                >
                  {{ pais.nombre }}
                </option>
              </select>
              <div
              class="form_editar_mensaje_error"
                v-if="errores.pais_id_1 !== null"
              >
                {{ errores.pais_id_1[0] }}
              </div>
            </div>
            <button type="button" @click="borrarNuevaDireccion(1)" class="boton_borrar_nueva_direccion">Borrar nuevo domicilio #1</button>
          </div>
        </div>
        <button v-if="mostrarBotonDireccion2" class="boton_editar_password" @click="agregarDireccion2()">Agregar Domicilio #2</button>
        <div class="contenedor_editar_direccion" v-if="mostrarContenedorDireccion2">
          <div class="form_section_editar_socio form_section_editar_direccion">
            <label for="estudio_nombre_2" class="label_nombre_estudio">Nombre del estudio #2</label>
            <input
              type="text"
              name="estudio_nombre_2"
              id="estudio_nombre_2"
              v-model="socio.estudio_nombre_2"
              :disabled="cargando"
            >
            <div
            class="form_editar_mensaje_error"
              v-if="errores.estudio_nombre_2 !== null"
            >
              {{ errores.estudio_nombre_2[0] }}
            </div>
          </div>
          <div class="form_section_editar_socio_direccion form_section_editar_direccion" v-if="mostrarTextoDireccion2">
            <span>Domicilio #2</span>
            <span>{{ direccion_2.direccion }}<br>{{ direccion_2.ciudad }}<br>{{ direccion_2.provincia }}<br>{{ direccion_2.pais }}</span>
            <div class="contenedor_botones_direccion">
              <div class="tooltip">
                <button type="button" @click="agregarDireccion(2)" class="boton_direccion boton_editar social-icon icon-pencil-square-o"></button>
                <span class="tooltiptext2 color-azul boton_editar_hover">Editar</span>
              </div>
              <div class="tooltip">
                <button type="button" @click="mostrarModalBorrarDireccion(2)" class="boton_direccion boton_borrar social-icon icon-trash-o" v-if="socio.direccion_1 !== null"></button>
                <span class="tooltiptext2 color-rojo boton_borrar_hover">Borrar</span>
              </div>
            </div>
          </div>
          <div v-if="mostrarAutocompleteDireccion2">
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="autocomplete2" class="nueva_direccion">Ingrese el nuevo domicilio #2</label>
              <GmapAutocomplete
                @place_changed="setPlace2"
                id="autocomplete2"
                class="autocomplete"
                :options="autocompleteOptions"
                placeholder=""
              />
            </div>
          </div>
          <div class="texto_confirmacion_direccion">
            <p  v-if="mostrarConfirmacionDireccion2" >Por favor revisa el mapa y en caso de OK presionar CONTINUAR, caso contrario CANCELAR</p>
            <div class="contenedor_mapa_confirmacion_direccion"  v-if="mostrarConfirmacionDireccionMapa2">
              <gmap-map
                :center="center4"
                :zoom="mapZoom"
                map-type-id="roadmap"
                :options="{
                  mapTypeControl: false,
                  keyboardShortcuts: false,
                  // mapData: false,
                  streetViewControl: false,
                  disableDefaultUI: true,
                  styles: styles['hide'] // Saco todos los iconos de los comercios
                }"
              >
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in markers4"
                  :position="m.position"
                  :clickable="false"
                  :draggable="false"
                  :icon="require('@/assets/pin.png')"
                ></gmap-marker>
              </gmap-map>
            </div>
            <div v-if="mostrarConfirmacionDireccion2" class="confirmacion_direccion_contenedor_botones">
              <button type="button" @click="direccionIncorrecta(2)" class="boton_texto_confirmacion boton_texto_confirmacion_cancelar">CANCELAR</button>
              <button type="button" @click="direccionCorrecta(2)" class="boton_texto_confirmacion boton_texto_confirmacion_continuar">CONTINUAR</button>
            </div>
          </div>
          <div v-if="mostrarCamposDireccion2" class="campos_nueva_direccion">
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="direccion_2">Dirección</label>
              <input
                type="text"
                name="direccion_2"
                id="direccion_2"
                v-model="socio.direccion_2"
                :disabled="cargando"
              >
              <div
              class="form_editar_mensaje_error"
                v-if="errores.direccion_2 !== null"
              >
                {{ errores.direccion_2[0] }}
              </div>
            </div>
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="ciudad_2">Ciudad / Localidad / Barrio</label>
              <input
                type="text"
                name="ciudad_2"
                id="ciudad_2"
                v-model="socio.ciudad_2"
                :disabled="cargando"
              >
              <div
              class="form_editar_mensaje_error"
                v-if="errores.ciudad_2 !== null"
              >
                {{ errores.ciudad_2[0] }}
              </div>
            </div>
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="provincia_id_2">Provincia</label>
              <select
                id="provincia_id_2"
                name="provincia_id_2"
                class="form minimal"
                v-model.number="socio.provincia_id_2"
                :disabled="cargando"
              >
                <option
                  v-for="provincia in provincias"
                  :value="provincia.provincia_id"
                  :key="provincia.provincia_id"
                >
                  {{ provincia.nombre }}
                </option>
              </select>
              <div
              class="form_editar_mensaje_error"
                v-if="errores.provincia_id_2 !== null"
              >
                {{ errores.provincia_id_2[0] }}
              </div>
            </div>
            <div class="form_section_editar_socio form_section_editar_direccion">
              <label for="pais_id_2">País</label>
              <select
                id="pais_id_2"
                name="pais_id_2"
                class="form minimal"
                v-model.number="socio.pais_id_2"
                :disabled="cargando"
              >
                <option
                  v-for="pais in paises"
                  :value="pais.pais_id"
                  :key="pais.pais_id"
                >
                  {{ pais.nombre }}
                </option>
              </select>
              <div
              class="form_editar_mensaje_error"
                v-if="errores.pais_id_2 !== null"
              >
                {{ errores.pais_id_2[0] }}
              </div>
            </div>
            <button type="button" @click="borrarNuevaDireccion(2)" class="boton_borrar_nueva_direccion">Borrar el nuevo domicilio #2</button>
          </div>
        </div>
        <button type="submit" v-if="mostrarBotonEditarDatosPersonales">Grabar Cambios</button>
      </form>
      <div class="contenedor_editar_password"
        v-if="mostrarCambiarPassword"
      >
        <div class="contenedor_form_editar_password">
          <button class="cerrar_modal" @click="cerrarModalEdiraPassword()">X</button>
          <form
            action="#"
            @submit.prevent="editarPassword(password, user.persona_id)"
            class="form_editar_password"
            ref="modalRef"
          >
            <div class="form_section_editar_password contenedor-ojo">
              <label for="password_nueva">Nueva Contraseña</label>
              <input
                type="password"
                name="password_nueva"
                id="password_nueva"
                v-model="password.password_nueva"
                :disabled="cargando"
                v-on:keyup.esc="cerrarModalEdiraPassword"
                class="campo-ojo"
              >
              <div
              class="form_editar_password_mensaje_error"
                v-if="errores2.password_nueva !== null"
              >
                {{ errores2.password_nueva[0] }}
              </div>
              <span class="icon icon-eye-slash ojo" id="ojo_login_2" @click="mostrarPassword(0)"></span>
            </div>
            <div class="form_section_editar_password contenedor-ojo">
              <label for="password_nueva_confirmacion">Repetir Nueva Contraseña</label>
              <input
                type="password"
                name="password_nueva_confirmacion"
                id="password_nueva_confirmacion"
                v-model="password.password_nueva_confirmacion"
                :disabled="cargando"
                class="campo-ojo"
              >
              <div
              class="form_editar_password_mensaje_error"
                v-if="errores2.password_nueva_confirmacion !== null"
              >
                {{ errores2.password_nueva_confirmacion[0] }}
              </div>
              <span class="icon icon-eye-slash ojo" id="ojo_login_3" @click="mostrarPassword(1)"></span>
            </div>
            <button type="submit">Cambiar Contraseña</button>
          </form>
        </div>
      </div>
      <div class="contenedor_editar_imagen"
        v-if="mostrarEditarImagen"
      >
        <div class="contenedor_editar_imagen_contenido">
          <div class="contenedor_editar_imagen_contenido_header">
              Recortá tu foto
          </div>
          <div class="contenedor_editar_imagen_contenido_fotos">
            <div class="content-imagen-cropper">
              <img src="" alt="" class="img-cropper" id="img-cropper">
            </div>
            <div class="content-imagen-sample">
              <img :src="destination" alt="" class="img-sample" id="img-croppered">
            </div>
          </div>
          <div class="contenedor_editar_imagen_contenido_botones">
            <button type="button" class="boton_editar_imagen editar_imagen_cancelar" @click="cancelarFoto()">Cancelar</button>
            <button type="button" class="boton_editar_imagen editar_imagen_recortar" @click="recortar()">Recortar</button>
          </div>
        </div>
      </div>
      <div class="contenedor_editar_password"
        v-if="mostrarOversizeImagen"
      >
        <div class="contenedor_form_editar_password">
          <div ref="modalRef3" class="texto_oversize">
            <button class="cerrar_modal" @click="cerrarModalOversizeImagen()">X</button>
            <p>La imagen no puede pesar más de 1.2 MB</p>
          </div>
        </div>
      </div>
      <div class="contenedor_editar_password"
        v-if="mostrarBorrarDireccion"
      >
        <div class="contenedor_form_borrar_direccion">
          <button class="cerrar_modal" @click="cerrarModalBorrarDireccion()">X</button>
          <div
            class="contenedor_botones_modal_borrar_direccion"
            ref="modalRef2"
          >
            <div class="contenedor_botones_modal_borrar_direccion_texto">
              <P>¿Estas seguro que deseas borrar la siguiente dirección?</P>
              <div v-if="mostrarBorrarDireccionUno">
                 {{ direccion.direccion }}<br>{{ direccion.ciudad }}<br>{{ direccion.provincia }}<br>{{ direccion.pais }}
              </div>
              <div  v-if="mostrarBorrarDireccionDos">
                 {{ direccion_2.direccion }}<br>{{ direccion_2.ciudad }}<br>{{ direccion_2.provincia }}<br>{{ direccion_2.pais }}
              </div>
            </div>
            <button @click="cerrarModalBorrarDireccion()" class="boton_cancelar boton_borrar_direccion">CANCELAR</button>
            <button @click="borrarDireccion(direccionNumero, user.persona_id)" class="boton_aceptar boton_borrar_direccion">ACEPTAR</button>
          </div>
        </div>
      </div>
    </div>
    <div class="contenedor_editar_socio_derecha" v-if="!loader">
      <div
        class="mapa-datos-personales-1"
        :class="[socio.latitude_1 !== null && socio.latitude_2 == null ? 'mapa-datos-personales-3' : socio.latitude_1 !== null && socio.latitude_2 !== null ? 'mapa-datos-personales-2' : '']"
        v-if="mostrarMapa1"
      >
        <gmap-map
          v-if="mostrarMapa1"
          :center="center"
          :zoom="mapZoom"
          map-type-id="roadmap"
          :options="{
            mapTypeControl: false,
            keyboardShortcuts: false,
            // mapData: false,
            streetViewControl: false,
            disableDefaultUI: true,
            styles: styles['hide'] // Saco todos los iconos de los comercios
          }"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="false"
            :draggable="false"
            :icon="require('@/assets/pin.png')"
          ></gmap-marker>
        </gmap-map>
        <div class="text_direccion" v-if="mostrarDireccionTexto"><b v-if="socio.perfil_id == 1">Domicilio #1:</b><b v-else>Domicilio:</b> {{ direccion.direccion }}, {{ direccion.ciudad }}, {{ direccion.provincia }}, {{ direccion.pais }}</div>
      </div>
      <div
        class="mapa-datos-personales-1"
        :class="[socio.latitude_1 !== null && socio.latitude_2 == null ? 'mapa-datos-personales-3' : socio.latitude_1 !== null && socio.latitude_2 !== null ? 'mapa-datos-personales-2' : '']"
        v-if="mostrarMapa2"
      >
        <gmap-map
            :center="center2"
            :zoom="mapZoom"
            map-type-id="roadmap"
            :options="{
              mapTypeControl: false,
              keyboardShortcuts: false,
              // mapData: false,
              streetViewControl: false,
              disableDefaultUI: true,
              styles: styles['hide'] // Saco todos los iconos de los comercios
            }"
          >
            <gmap-marker
              :key="index"
              v-for="(m, index) in markers2"
              :position="m.position"
              :clickable="false"
              :draggable="false"
              :icon="require('@/assets/pin.png')"
            ></gmap-marker>
          </gmap-map>
          <div class="text_direccion" v-if="mostrarDireccionTexto"><b>Domicilio #2:</b> {{ direccion_2.direccion }}, {{ direccion_2.ciudad }}, {{ direccion_2.provincia }}, {{ direccion_2.pais }}</div>
        </div>
    </div>
  </section>
</template>

<script src="https://unpkg.com/vue@2"></script>
<script src="https://unpkg.com/vue-select@latest"></script>
<script>
import 'vue-select/dist/vue-select.css';
import asociadoService from '../services/asociado'
import personasService from '../services/personas'
import paisesService from '../services/paises'
import provinciasService from '../services/provincias'
import { PATH_IMG } from '../constants'
import BaseLoader from '../components/BaseLoader.vue'
import store from './../store'
import Cropper from 'cropperjs'
export default {
  name: 'EditarSocio',
  components: {
    BaseLoader
    // Seminarios
  },
  data: function () {
    return {
      cropper: {},
      destination: {},
      socio: [],
      profesores: [],
      direccionNumero: null,
      direccion: {
        direccion: null,
        ciudad: null,
        provincia: null,
        pais: null
      },
      direccion_2: {
        direccion: null,
        ciudad: null,
        provincia: null,
        pais: null
      },
      direccionSinEditar_1: {
        latitude_1: null,
        longitude_1: null,
        direccion: null,
        ciudad: null,
        provincia: null,
        pais: null
      },
      direccionSinEditar_2: {
        latitude_2: null,
        longitude_2: null,
        direccion: null,
        ciudad: null,
        provincia: null,
        pais: null
      },
      password: {
        password_nueva: null,
        password_nueva_confirmacion: null
      },
      user: {
        tipo_id: JSON.parse(localStorage.getItem('userData')).tipo_id,
        persona_id: JSON.parse(localStorage.getItem('userData')).persona_id,
        nombre: JSON.parse(localStorage.getItem('userData')).nombre,
        apellido: JSON.parse(localStorage.getItem('userData')).apellido,
        dni: JSON.parse(localStorage.getItem('userData')).dni,
        direccion: JSON.parse(localStorage.getItem('userData')).direccion_1,
        provincia: JSON.parse(localStorage.getItem('userData')).provincia_id_1
      },
      paises: [],
      provincias: [],
      mostrarContenedorDireccion1: true,
      mostrarContenedorDireccion2: false,
      mostrarAutocompleteDireccion1: false,
      mostrarAutocompleteDireccion2: false,
      mostrarCamposDireccion1: false,
      mostrarCamposDireccion2: false,
      mostrarTextoDireccion1: false,
      mostrarTextoDireccion2: false,
      mostrarCambiarPassword: false,
      mostrarEditarImagen: false,
      mostrarOversizeImagen: false,
      mostrarBorrarDireccion: false,
      mostrarDireccionTexto: true,
      mostrarBorrarDireccionUno: false,
      mostrarBorrarDireccionDos: false,
      mostrarConfirmacionDireccion1: false,
      mostrarConfirmacionDireccionMapa1: false,
      mostrarConfirmacionDireccionMapa2: false,
      mostrarConfirmacionDireccion2: false,
      mostrarBotonEditarDatosPersonales: true,
      mostrarBotonDireccion2: false,
      mostrarMapa1: false,
      mostrarMapa2: false,
      errores: {
        nombre: null,
        apellido: null,
        estudio_nombre_1: null,
        estudio_nombre_2: null,
        email: null,
        dni: null,
        website: null,
        telefono: null,
        celular: null,
        ciudad_1: null,
        direccion_1: null,
        latitude_1: null,
        longitude_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        ciudad_2: null,
        direccion_2: null,
        latitude_2: null,
        longitude_2: null,
        provincia_id_2: null,
        pais_id_2: null,
        facebook: null,
        twitter: null,
        instagram: null
      },
      errores2: {
        password_nueva: null,
        password_nueva_confirmacion: null
      },
      store,
      cuotasPagas: [],
      cuotasImpagas: [],
      regaliasImpagas: [],
      cargando: false,
      loader: false,
      center: {
        lat: null,
        lng: null
      },
      center2: {
        lat: null,
        lng: null
      },
      center3: {
        lat: null,
        lng: null
      },
      center4: {
        lat: null,
        lng: null
      },
      provisorioDireccion1: {
        lat: null,
        lng: null,
        direccion_1: null,
        ciudad_1: null
      },
      provisorioDireccion2: {
        lat: null,
        lng: null
      },
      mapZoom: 15,
      markers: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      markers2: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      markers3: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      markers4: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      autocompleteOptions: {
        componentRestrictions: {
          country: [
            'ar', 'uy'
          ]
        }
      },
      styles: {
        hide: [
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ],
        retro: [
          { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#c9b2a6' }]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#dcd2be' }]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#ae9e90' }]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#93817c' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#a5b076' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#447530' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#f5f1e6' }]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#fdfcf8' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#f8c967' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#e9bc62' }]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{ color: '#e98d58' }]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#db8555' }]
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#806b63' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8f7d77' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#ebe3cd' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ color: '#b9d3c2' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#92998d' }]
          }
        ]
      }
    }
  },
  methods: {
    // window.onkeydown = function(e) {
    //   if(e.keyCode == 27) {
    //     // d.body.removeChild(div);
    //     console.log('qqqsasasa')
    //   }
    // },
    keyEvent () {
      console.log('qqqsasasa')
      // this.cerrarModalEdiraPassword()
    },
    encodeImage () {
      const extensiones = this.$refs.imagen.value.substring(this.$refs.imagen.value.lastIndexOf('.'), this.$refs.imagen.value.lenght)
      if (this.$refs.imagen.getAttribute('accept').split(',').indexOf(extensiones) < 0) {
        console.log('Formato incorrecto')
      } else if (this.$refs.imagen.files[0].size > 1200000) {
        console.log('Pesadaaaaao')
        this.mostrarOversizeImagen = true
        document.addEventListener('mouseup', this.closeModalOnClickOutside3)
      } else {
        this.mostrarEditarImagen = true
        // const file = this.$refs.imagen.files[0]
        console.log(document.getElementById('img-cropper'))
        // Pongo el body sin scroll
        const body = document.body
        body.style.overflow = 'hidden'
        this.esperar()
        // const reader = new FileReader()
        // reader.addEventListener('load', () => {
        //   this.socio.imagen_perfil = reader.result
        // })
        // reader.readAsDataURL(file)
        // setTimeout(function () { this.mostrarEditarImagen = true }, 5000)
        // const image = document.getElementById('img-cropper')
        // const imagenUrl = URL.createObjectURL(file)
        // image.src = imagenUrl
        // // let cropper = null
        // this.cropper = new Cropper(image, {
        //   aspectRatio: 7 / 9, // es la proporción en la que queremos que recorte en este caso 1:1
        //   preview: '.img-sample', // contenedor donde se va a ir viendo en tiempo real la imagen cortada
        //   zoomable: false, // Para que no haga zoom
        //   scalable: false, // Para que no haga zoom
        //   viewMode: 1, // Para que no estire la imagen al contenedor
        //   responsive: false, // Para que no reacomode con zoom la imagen al contenedor
        //   dragMode: 'none', // Para que al arrastrar no haga nada
        //   maxCropBoxWidth: 466,
        //   minCropBoxWidth: 50,
        //   maxCroppedHeight: 600,
        //   minCropBoxHeight: 64.29,
        //   autoCropArea: 0.5,
        //   crop: () => {
        //     const canvas = this.cropper.getCroppedCanvas()
        //     this.destination = canvas.toDataURL('image/jpg')
        //   },
        //   ready () { // metodo cuando cropper ya este activo, le ponemos el alto y el ancho del contenedor de cropper al 100%
        //     // const canvas = this.cropper.getCroppedCanvas()
        //     // this.destination = canvas.toDataURL('image/jpg')
        //     // document.querySelector('.cropper-container').style.width = '100%'
        //     // document.querySelector('.cropper-container').style.height = '100%'
        //   }
        // })
      }
    },
    esperar () {
      setTimeout(() => {
        const file = this.$refs.imagen.files[0]
        const image = document.getElementById('img-cropper')
        const imagenUrl = URL.createObjectURL(file)
        image.src = imagenUrl
        // let cropper = null
        this.cropper = new Cropper(image, {
          aspectRatio: 7 / 9, // es la proporción en la que queremos que recorte en este caso 1:1
          preview: '.img-sample', // contenedor donde se va a ir viendo en tiempo real la imagen cortada
          zoomable: false, // Para que no haga zoom
          scalable: false, // Para que no haga zoom
          viewMode: 1, // Para que no estire la imagen al contenedor
          responsive: false, // Para que no reacomode con zoom la imagen al contenedor
          dragMode: 'none', // Para que al arrastrar no haga nada
          maxCropBoxWidth: 466,
          minCropBoxWidth: 50,
          maxCroppedHeight: 600,
          minCropBoxHeight: 64.29,
          autoCropArea: 0.5,
          crop: () => {
            const canvas = this.cropper.getCroppedCanvas()
            this.destination = canvas.toDataURL('image/jpg')
          },
          ready () { // metodo cuando cropper ya este activo, le ponemos el alto y el ancho del contenedor de cropper al 100%
            // const canvas = this.cropper.getCroppedCanvas()
            // this.destination = canvas.toDataURL('image/jpg')
            // document.querySelector('.cropper-container').style.width = '100%'
            // document.querySelector('.cropper-container').style.height = '100%'
          }
        })
      }, 50)
    },
    recortar () {
      console.log('Tamaño Original IMG ' + this.$refs.imagen.files[0].size)
      console.log('Tamaño Recortado IMG ' + this.cropper.getCroppedSize)
      this.socio.imagen_perfil = this.destination
      this.cropper.destroy()
      this.cancelarFoto()
      // const file = this.cropper.getCroppedCanvas()
      // console.log(file)
      // file.toBlob(function (blob) {
      //   const urlCut = URL.createObjectURL(blob)
      //   console.log(urlCut)
      //   // this.socio.imagen_perfil = urlCut
      // })
      // const reader = new FileReader()
      // reader.addEventListener('load', () => {
      //   this.socio.imagen_perfil = reader.result
      // })
      // reader.readAsDataURL(file)
    },
    cancelarFoto () {
      this.cropper.destroy()
      this.destination = ''
      this.$refs.imagen.value = ''
      document.getElementById('img-cropper').src = ''
      // saco el overflow del body
      const body = document.body
      body.style.overflow = 'auto'
      this.mostrarEditarImagen = false
    },
    editarSocio (socio, id) {
      console.log(id)
      console.log('voy a editar: ', socio)
      if (socio.longitude_1 !== null && socio.longitude_2 == null) {
        this.socio.direccion = '1'
      }
      if (socio.longitude_1 !== null && socio.longitude_2 !== null) {
        this.socio.direccion = '1-2'
      }
      if (socio.longitude_1 == null && socio.longitude_2 == null) {
        this.socio.direccion = 'no'
      }
      personasService
        .editarSocioPorId(socio, id)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            // console.log(localStorage.getItem('userData'))
            // console.log(JSON.parse(localStorage.getItem('userData')).dni)
            localStorage.removeItem('userData')
            localStorage.setItem('userData', JSON.stringify(socio))
            this.socio = {
              nombre: null,
              apellido: null,
              estudio_nombre_1: null,
              estudio_nombre_2: null,
              email: null,
              dni: null,
              toma_clase: null,
              website: null,
              telefono: null,
              celular: null,
              direccion_1: null,
              ciudad_1: null,
              latitude_1: null,
              longitude_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              ciudad_2: null,
              direccion_2: null,
              latitude_2: null,
              longitude_2: null,
              provincia_id_2: null,
              pais_id_2: null,
              imagen_perfil: null,
              facebook: null,
              twitter: null,
              instagram: null
            }
            this.store.setStatus({
              msg: 'Los datos personales se editaron exitosamente',
              type: 'success'
            })
            this.$router.push('/datos-personales')
          } else {
            this.errores = {
              nombre: null,
              apellido: null,
              estudio_nombre_1: null,
              estudio_nombre_2: null,
              email: null,
              dni: null,
              website: null,
              telefono: null,
              celular: null,
              direccion_1: null,
              ciudad_1: null,
              latitude_1: null,
              longitude_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              ciudad_2: null,
              direccion_2: null,
              latitude_2: null,
              longitude_2: null,
              provincia_id_2: null,
              pais_id_2: null,
              facebook: null,
              twitter: null,
              instagram: null,
              ...respuesta.errors
            }
          }
        })
    },
    borrarNuevaDireccion (id) {
      if (id === 1) {
        console.log(id)
        this.mostrarCamposDireccion1 = false
        this.mostrarConfirmacionDireccionMapa1 = false
        this.mostrarConfirmacionDireccion1 = false
        this.mostrarAutocompleteDireccion1 = true
        // Borro los campos
        document.getElementById('direccion_1').value = ''
        document.getElementById('ciudad_1').value = ''
        document.getElementById('provincia_id_1').value = ''
        document.getElementById('pais_id_1').value = ''
        // Vuelvo a poner los datos originales
        this.socio.latitude_1 = this.direccionSinEditar_1.latitude_1
        this.socio.longitude_1 = this.direccionSinEditar_1.longitude_1
        this.socio.direccion_1 = this.direccionSinEditar_1.direccion_1
        this.socio.ciudad_1 = this.direccionSinEditar_1.ciudad_1
        this.socio.provincia_id_1 = this.direccionSinEditar_1.provincia_id_1
        this.socio.pais_id_1 = this.direccionSinEditar_1.pais_id_1
      } else {
        this.mostrarContenedorDireccion2 = false
        this.mostrarCamposDireccion2 = false
        this.mostrarConfirmacionDireccionMapa2 = false
        this.mostrarConfirmacionDireccion2 = false
        this.mostrarAutocompleteDireccion2 = false
        // Borro los campos
        document.getElementById('direccion_2').value = ''
        document.getElementById('ciudad_2').value = ''
        document.getElementById('provincia_id_2').value = ''
        document.getElementById('pais_id_2').value = ''
        // Vuelvo a poner los datos originales
        this.socio.latitude_2 = this.direccionSinEditar_2.latitude_2
        this.socio.longitude_2 = this.direccionSinEditar_2.longitude_2
        this.socio.direccion_2 = this.direccionSinEditar_2.direccion_2
        this.socio.ciudad_2 = this.direccionSinEditar_2.ciudad_2
        this.socio.provincia_id_2 = this.direccionSinEditar_2.provincia_id_2
        this.socio.pais_id_2 = this.direccionSinEditar_2.pais_id_2
        if (this.socio.perfil_id == 1) {
          this.mostrarBotonDireccion2 = true
        }
      }
    },
    agregarDireccion (id) {
      if (id === 1) {
        this.mostrarAutocompleteDireccion1 = true
      } else {
        this.mostrarAutocompleteDireccion2 = true
      }
      this.mostrarConfirmacionDireccion1 = false
      this.mostrarConfirmacionDireccion2 = false
    },
    mostrarModalEdiraPassword () {
      this.mostrarCambiarPassword = true
      document.addEventListener('mouseup', this.closeModalOnClickOutside)
      // Pongo el body sin scroll
      const body = document.body
      body.style.overflow = 'hidden'
    },
    mostrarModalBorrarDireccion (id) {
      this.mostrarAutocompleteDireccion1 = false
      this.mostrarBorrarDireccion = true
      document.addEventListener('mouseup', this.closeModalOnClickOutside2)
      if (id === 1) {
        this.mostrarBorrarDireccionUno = true
        this.direccionNumero = 1
      } else {
        this.mostrarBorrarDireccionDos = true
        this.direccionNumero = 2
      }
      // Pongo el body sin scroll
      const body = document.body
      body.style.overflow = 'hidden'
    },
    cerrarModalEdiraPassword () {
      console.log('222222')
      document.removeEventListener('mouseup', this.closeModalOnClickOutside)
      this.mostrarCambiarPassword = false
      this.errores2 = {
        password_nueva: null,
        password_nueva_confirmacion: null
      }
      this.password = {
        password_nueva: null,
        password_nueva_confirmacion: null
      }
      // saco el overflow del body
      const body = document.body
      body.style.overflow = 'auto'
    },
    cerrarModalOversizeImagen () {
      document.removeEventListener('mouseup', this.closeModalOnClickOutside3)
      this.mostrarOversizeImagen = false
      // saco el overflow del body
      const body = document.body
      body.style.overflow = 'auto'
    },
    cerrarModalBorrarDireccion () {
      console.log('222222')
      this.mostrarBorrarDireccionUno = false
      this.mostrarBorrarDireccionDos = false
      document.removeEventListener('mouseup', this.closeModalOnClickOutside2)
      this.mostrarBorrarDireccion = false
      // this.errores2 = {
      //   password_nueva: null,
      //   password_nueva_confirmacion: null
      // }
      // this.password = {
      //   password_nueva: null,
      //   password_nueva_confirmacion: null
      // }
      // saco el overflow del body
      const body = document.body
      body.style.overflow = 'auto'
    },
    closeModalOnClickOutside (event) {
      const modal = this.$refs.modalRef
      console.log('aaaaaaa11111')
      console.log(modal)
      if (!modal.contains(event.target)) {
        console.log('wewewwewew')
        this.cerrarModalEdiraPassword()
      }
    },
    closeModalOnClickOutside2 (event) {
      const modal = this.$refs.modalRef2
      console.log('wwwww')
      console.log(modal)
      if (!modal.contains(event.target)) {
        console.log('wewewwewew')
        this.cerrarModalBorrarDireccion()
      }
    },
    closeModalOnClickOutside3 (event) {
      const modal = this.$refs.modalRef3
      console.log('wwwww')
      console.log(modal)
      if (!modal.contains(event.target)) {
        console.log('wewewwewew')
        this.cerrarModalOversizeImagen()
      }
    },
    agregarDireccion2 () {
      this.mostrarBotonDireccion2 = false
      this.mostrarContenedorDireccion2 = true
      this.mostrarAutocompleteDireccion2 = true
    },
    direccionIncorrecta (id) {
      if (id === 1) {
        this.mostrarConfirmacionDireccion1 = false
        this.mostrarConfirmacionDireccionMapa1 = false
        // // Borro el input de la dirección
        document.getElementById('autocomplete').value = ''
        // if (document.getElementById('autocomplete') != null) {
        //   document.getElementById('autocomplete').value = ''
        // }
      } else {
        this.mostrarConfirmacionDireccion2 = false
        this.mostrarConfirmacionDireccionMapa2 = false
        document.getElementById('autocomplete2').value = ''
        // if (document.getElementById('autocomplete2') != null) {
        //   document.getElementById('autocomplete2').value = ''
        // }
      }
      this.mostrarBotonEditarDatosPersonales = true
    },
    direccionCorrecta (id) {
      if (id === 1) {
        this.mostrarCamposDireccion1 = true
        this.mostrarConfirmacionDireccion1 = false
        this.socio.latitude_1 = this.provisorioDireccion1.lat
        this.socio.longitude_1 = this.provisorioDireccion1.lng
        // if (this.socio.direccion_2 == null) {
        //   console.log('qqqwwweeeerrrr')
        //   this.mostrarBotonDireccion2 = true
        // }
      } else {
        this.mostrarCamposDireccion2 = true
        this.mostrarConfirmacionDireccion2 = false
        this.socio.latitude_2 = this.provisorioDireccion2.lat
        this.socio.longitude_2 = this.provisorioDireccion2.lng
      }
      console.log('fffftttt')
      this.mostrarBotonEditarDatosPersonales = true
      // // Borro el input de la dirección
      if (document.getElementById('autocomplete2') != null) {
        document.getElementById('autocomplete2').value = ''
      }
      if (document.getElementById('autocomplete') != null) {
        document.getElementById('autocomplete').value = ''
      }
    },
    borrarDireccion (direccionNumero, id) {
      console.log('Boton borrar direccion')
      console.log(direccionNumero)
      console.log(id)
      personasService
        .borrarDireccion(direccionNumero, id)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            this.socio = {
              nombre: null,
              apellido: null,
              estudio_nombre_1: null,
              estudio_nombre_2: null,
              email: null,
              dni: null,
              website: null,
              telefono: null,
              celular: null,
              direccion_1: null,
              ciudad_1: null,
              latitude_1: null,
              longitude_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              ciudad_2: null,
              direccion_2: null,
              latitude_2: null,
              longitude_2: null,
              provincia_id_2: null,
              pais_id_2: null,
              imagen_perfil: null,
              facebook: null,
              twitter: null,
              instagram: null
            }
            this.cerrarModalBorrarDireccion()
            this.store.setStatus({
              msg: 'La dirección fue borrada exitosamente',
              type: 'success'
            })
            this.$router.push('/datos-personales')
          } else {
            this.errores = {
              nombre: null,
              apellido: null,
              estudio_nombre_1: null,
              estudio_nombre_2: null,
              email: null,
              dni: null,
              website: null,
              telefono: null,
              celular: null,
              direccion_1: null,
              ciudad_1: null,
              latitude_1: null,
              longitude_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              ciudad_2: null,
              direccion_2: null,
              latitude_2: null,
              longitude_2: null,
              provincia_id_2: null,
              pais_id_2: null,
              facebook: null,
              twitter: null,
              instagram: null,
              ...respuesta.errors
            }
          }
        })
    },
    setPlace (place) {
      this.errores = {
        nombre: null,
        apellido: null,
        estudio_nombre_1: null,
        estudio_nombre_2: null,
        email: null,
        dni: null,
        website: null,
        telefono: null,
        celular: null,
        direccion_1: null,
        ciudad_1: null,
        latitude_1: null,
        longitude_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        ciudad_2: null,
        direccion_2: null,
        latitude_2: null,
        longitude_2: null,
        provincia_id_2: null,
        pais_id_2: null,
        facebook: null,
        twitter: null,
        instagram: null
      }
      this.currentPlace = place
      this.mostrarAutocompleteDireccion1 = true
      this.mostrarCamposDireccion1 = false
      // this.mostrarDireccionTexto = false
      this.mostrarConfirmacionDireccion1 = true
      this.mostrarConfirmacionDireccionMapa1 = true
      this.mostrarBotonEditarDatosPersonales = false
      console.log('Esto es lo que trae: ' + this.currentPlace.formatted_address)
      // console.log('Numero: ' + this.currentPlace.address_components[0].long_name)
      // console.log('Calle: ' + this.currentPlace.address_components[1].long_name)
      // console.log('Localidad: ' + this.currentPlace.address_components[2].long_name)
      // console.log('Localidad 2: ' + this.currentPlace.address_components[3].long_name)
      // console.log('Comuna: ' + this.currentPlace.address_components[4].long_name)
      // console.log('Provincia: ' + this.currentPlace.address_components[5].long_name)
      // console.log('Pais: ' + this.currentPlace.address_components[6].long_name)
      if (this.currentPlace.address_components[6] !== undefined) {
        const pais = this.currentPlace.address_components[6].long_name
        paisesService
          .traerPaisId(pais)
          .then(respuesta => {
            // this.cargando = true
            // this.socio = respuesta
            // this.center.lat = parseFloat(respuesta.latitude_1)
            // this.center.lng = parseFloat(respuesta.longitude_1)
            // this.markers[0].position.lat = parseFloat(respuesta.latitude_1)
            // this.markers[0].position.lng = parseFloat(respuesta.longitude_1)
            console.log('IdPais:', respuesta[0].pais_id)
            this.socio.pais_id_1 = respuesta[0].pais_id
            // console.log('LAT:', respuesta.latitude_1
          })
      }
      this.socio.direccion_1 = this.currentPlace.address_components[1].long_name + ' ' + this.currentPlace.address_components[0].long_name
      this.socio.ciudad_1 = this.currentPlace.address_components[2].long_name
      this.socio.provincia_id = null
      // this.socio.latitude_1 = this.currentPlace.geometry.location.lat()
      // this.socio.longitude_1 = this.currentPlace.geometry.location.lng()
      // Agrego lat y lng al provisorio
      this.provisorioDireccion1.lat = this.currentPlace.geometry.location.lat()
      this.provisorioDireccion1.lng = this.currentPlace.geometry.location.lng()
      // Centro el mapa
      this.center3.lat = this.currentPlace.geometry.location.lat()
      this.center3.lng = this.currentPlace.geometry.location.lng()
      // Marker
      this.markers3[0].position.lat = this.currentPlace.geometry.location.lat()
      this.markers3[0].position.lng = this.currentPlace.geometry.location.lng()
    },
    setPlace2 (place) {
      this.socio.direccion = 'si'
      this.errores = {
        nombre: null,
        apellido: null,
        estudio_nombre_1: null,
        estudio_nombre_2: null,
        email: null,
        dni: null,
        website: null,
        telefono: null,
        celular: null,
        direccion_1: null,
        ciudad_1: null,
        latitude_1: null,
        longitude_1: null,
        provincia_id_1: null,
        pais_id_1: null,
        ciudad_2: null,
        direccion_2: null,
        latitude_2: null,
        longitude_2: null,
        provincia_id_2: null,
        pais_id_2: null,
        facebook: null,
        twitter: null,
        instagram: null
      }
      this.currentPlace = place
      this.mostrarAutocompleteDireccion2 = true
      this.mostrarCamposDireccion2 = false
      this.mostrarConfirmacionDireccionMapa2 = true
      // this.mostrarDireccionTexto = false
      this.mostrarConfirmacionDireccion2 = true
      this.mostrarBotonEditarDatosPersonales = false
      console.log('Esto es lo que trae: ' + this.currentPlace.formatted_address)
      // console.log('Numero: ' + this.currentPlace.address_components[0].long_name)
      // console.log('Calle: ' + this.currentPlace.address_components[1].long_name)
      // console.log('Localidad: ' + this.currentPlace.address_components[2].long_name)
      // console.log('Localidad 2: ' + this.currentPlace.address_components[3].long_name)
      // console.log('Comuna: ' + this.currentPlace.address_components[4].long_name)
      // console.log('Provincia: ' + this.currentPlace.address_components[5].long_name)
      // console.log('Pais: ' + this.currentPlace.address_components[6].long_name)
      if (this.currentPlace.address_components[6] !== undefined) {
        const pais = this.currentPlace.address_components[6].long_name
        paisesService
          .traerPaisId(pais)
          .then(respuesta => {
            // this.cargando = true
            // this.socio = respuesta
            // this.center.lat = parseFloat(respuesta.latitude_1)
            // this.center.lng = parseFloat(respuesta.longitude_1)
            // this.markers[0].position.lat = parseFloat(respuesta.latitude_1)
            // this.markers[0].position.lng = parseFloat(respuesta.longitude_1)
            console.log('IdPais:', respuesta[0].pais_id)
            this.socio.pais_id_2 = respuesta[0].pais_id
            // console.log('LAT:', respuesta.latitude_1
          })
      }
      this.socio.direccion_2 = this.currentPlace.address_components[1].long_name + ' ' + this.currentPlace.address_components[0].long_name
      this.socio.ciudad_2 = this.currentPlace.address_components[2].long_name
      this.socio.provincia_id = null
      // this.socio.latitude_2 = this.currentPlace.geometry.location.lat()
      // this.socio.longitude_2 = this.currentPlace.geometry.location.lng()
      // Agrego lat y lng al provisorio
      this.provisorioDireccion2.lat = this.currentPlace.geometry.location.lat()
      this.provisorioDireccion2.lng = this.currentPlace.geometry.location.lng()
      // Centro el mapa
      this.center4.lat = this.currentPlace.geometry.location.lat()
      this.center4.lng = this.currentPlace.geometry.location.lng()
      // Marker
      this.markers4[0].position.lat = this.currentPlace.geometry.location.lat()
      this.markers4[0].position.lng = this.currentPlace.geometry.location.lng()
    },
    editarPassword (password, id) {
      console.log(password)
      console.log(id)
      personasService
        .editarPassword(password, id)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            // this.comercio = {
            //   nombre: null,
            //   descripcion: null,
            //   telefono: null,
            //   website: null,
            //   direccion: null,
            //   localidad: null,
            //   provincia_id: null,
            //   imagen: null,
            //   tipo_id: null,
            //   usuario_id: 1, // OJO CON ESTO!!!!!!!!!!!!! ESTA HARDCODEADO A PROPOSITO HASTA QUE HAGA EL LOGIN
            //   pais_id: null
            // }
            this.mostrarCambiarPassword = false
            this.errores2 = {
              password_nueva: null,
              password_nueva_confirmacion: null
            }
            this.store.setStatus({
              msg: 'La contraseña se modificó exitosamente',
              type: 'success'
            })
            // saco el position fixed del body
            const body = document.body
            const scrollY = body.style.top
            body.style.position = ''
            body.style.top = ''
            window.scrollTo(0, parseInt(scrollY || '0') * -1)
            this.$router.push('/datos-personales')
          } else {
            this.errores2 = {
              password_nueva: null,
              password_nueva_confirmacion: ['Las contraseñas ingresadas no coinciden'],
              ...respuesta.errors
            }
          }
        })
    },
    mostrarPassword (id) {
      const ojo = document.querySelectorAll('.ojo')
      const input = document.querySelectorAll('.campo-ojo')
      if (ojo[id].getAttribute('class') === 'icon icon-eye-slash ojo') {
        ojo[id].removeAttribute('class')
        ojo[id].setAttribute('class', 'icon icon-eye ojo ojo-naranja')
        input[id].setAttribute('type', 'text')
      } else {
        ojo[id].removeAttribute('class')
        ojo[id].setAttribute('class', 'icon icon-eye-slash ojo')
        input[id].setAttribute('type', 'password')
      }
    }
  },
  computed: {
    imagenRuta: function () {
      return `${PATH_IMG}/${this.socio.imagen_perfil}`
    }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      // this.mostrarCamposDireccion1 = false
      // this.mostrarConfirmacionDireccionMapa1 = false
      // this.mostrarConfirmacionDireccion1 = false
      const campoDireccion1 = document.getElementById('autocomplete')
      if (campoDireccion1 != null && document.activeElement === campoDireccion1) {
        this.mostrarCamposDireccion1 = false
        this.mostrarConfirmacionDireccionMapa1 = false
        this.mostrarConfirmacionDireccion1 = false
      }
      const campoDireccion2 = document.getElementById('autocomplete2')
      if (campoDireccion2 != null && document.activeElement === campoDireccion2) {
        this.mostrarCamposDireccion2 = false
        this.mostrarConfirmacionDireccionMapa2 = false
        this.mostrarConfirmacionDireccion2 = false
      }
      if (e.keyCode === 27) {
        this.cerrarModalEdiraPassword()
        this.cerrarModalBorrarDireccion()
      }
    })
    // document.getElementById('autocomplete').addEventListener('keydown', (e) => {
    //   this.mostrarCamposDireccion1 = false
    //   this.mostrarConfirmacionDireccionMapa1 = false
    //   this.mostrarConfirmacionDireccion1 = false
    // })
    // document.getElementById('autocomplete2').addEventListener('keydown', (e) => {
    //   this.mostrarCamposDireccion2 = false
    //   this.mostrarConfirmacionDireccionMapa2 = false
    //   this.mostrarConfirmacionDireccion2 = false
    // })
    // this.cargando = true
    this.loader = true
    asociadoService
      .traerSocioPorId(this.user.persona_id)
      .then(respuesta => {
        this.loader = false
        this.socio = respuesta
        this.direccionSinEditar_1.latitude_1 = respuesta.latitude_1
        this.direccionSinEditar_1.longitude_1 = respuesta.longitude_1
        this.direccionSinEditar_1.direccion_1 = respuesta.direccion_1
        this.direccionSinEditar_1.ciudad_1 = respuesta.ciudad_1
        this.direccionSinEditar_1.provincia_id_1 = respuesta.provincia_id_1
        this.direccionSinEditar_1.pais_id_1 = respuesta.pais_id_1
        this.direccionSinEditar_2.latitude_2 = respuesta.latitude_2
        this.direccionSinEditar_2.longitude_2 = respuesta.longitude_2
        this.direccionSinEditar_2.direccion_2 = respuesta.direccion_2
        this.direccionSinEditar_2.ciudad_2 = respuesta.ciudad_2
        this.direccionSinEditar_2.provincia_id_2 = respuesta.provincia_id_2
        this.direccionSinEditar_2.pais_id_2 = respuesta.pais_id_2
        this.socio.direccion = 'no'
        this.direccion.direccion = respuesta.direccion_1
        this.direccion.ciudad = respuesta.ciudad_1
        console.log(respuesta.provincia1)
        if (respuesta.provincia1 == null) {
          this.mostrarAutocompleteDireccion1 = true
        }
        if (respuesta.provincia1 != null) {
          this.direccion.provincia = respuesta.provincia1.nombre
          this.mostrarTextoDireccion1 = true
        }
        if (respuesta.pais1 != null) {
          this.direccion.pais = respuesta.pais1.nombre
          this.mostrarMapa1 = true
        }
        this.direccion_2.direccion = respuesta.direccion_2
        this.direccion_2.ciudad = respuesta.ciudad_2
        if (respuesta.provincia2 != null) {
          this.mostrarTextoDireccion2 = true
          this.direccion_2.provincia = respuesta.provincia2.nombre
        }
        if (respuesta.pais2 != null) {
          this.direccion_2.pais = respuesta.pais2.nombre
          this.mostrarMapa2 = true
        }
        this.center.lat = parseFloat(respuesta.latitude_1)
        this.center.lng = parseFloat(respuesta.longitude_1)
        this.center2.lat = parseFloat(respuesta.latitude_2)
        this.center2.lng = parseFloat(respuesta.longitude_2)
        this.markers[0].position.lat = parseFloat(respuesta.latitude_1)
        this.markers[0].position.lng = parseFloat(respuesta.longitude_1)
        this.markers2[0].position.lat = parseFloat(respuesta.latitude_2)
        this.markers2[0].position.lng = parseFloat(respuesta.longitude_2)
        console.log('Socio:', respuesta)
        console.log('Sociorrrr:', respuesta.direccion_1)
        if (respuesta.direccion_1 == null) {
          this.mostrarContenedorDireccion1 = true
        }
        if (respuesta.direccion_2 == null) {
          this.mostrarContenedorDireccion2 = false
        } else {
          this.mostrarContenedorDireccion2 = true
        }
        if (respuesta.direccion_2 == null && respuesta.direccion_1 !== null) {
          if (this.socio.perfil_id == 1) {
            this.mostrarBotonDireccion2 = true
          }
        }
      })
    paisesService
      .traerPaisesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.paises = respuesta
        console.log('Paises:', respuesta)
      })
    provinciasService
      .traerProvinciasTodas(this.user.persona_id)
      .then(respuesta => {
        // this.cargando = false
        this.provincias = respuesta
        console.log('Provincias:', respuesta)
      })
    asociadoService
      .traerProfesoresFormadores(this.user.persona_id)
      .then(respuesta => {
        // this.cargando = false
        this.profesores = respuesta
        console.log('Profesores:', respuesta)
        this.profesores.map(function (x) {
          return x.item_data = x.nombre + ' ' + x.apellido
        })
      })
  }
}
</script>
<style>
/*#autocomplete {
  width: 500px;
}*/

select.minimal {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: -o-linear-gradient(45deg, transparent 50%, gray 50%),
    -o-linear-gradient(315deg, gray 50%, transparent 50%),
    -o-linear-gradient(left, #ccc, #ccc);
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

/*.cropper {
  height: 600px;
  width: 600px;
  background: #DDD;
}

.modal-body .content-imagen-cropper {
    width: 80%;
    height: 400px;
    padding: 5px;
    outline: 1px solid var(--thir-color);
}*/

/*.modal-body .content-imagen-sample .img-sample{
    height: 100px;
    width: 100px;
}*/

.contenedor_editar_password {
/*  display: none;*/
}
.select_buscador .vs__search::placeholder,
.select_buscador .vs__dropdown-toggle,
.select_buscador .vs__dropdown-menu {
/*  background: red;*/
  background: #ffffff;
  border: 1px solid #ccc;
  color: black;
/*  text-transform: lowercase;*/
/*  font-variant: small-caps;*/
  font-size: 16px;
  font-family: arial;
}

.select_buscador .vs__dropdown-menu {
/*  background: red;*/
  padding: 0;
}

.select_buscador .vs__clear,
.select_buscador .vs__open-indicator {
  fill: #394066;
}

.vs__clear {
  margin-right: 4px;
}

/*.select_buscador .vs__dropdown-menu::hover {
  background: red!important;
}*/
/*--vs-dropdown-option--active-bg: red;
  --vs-dropdown-option--active-color: #eeeeee;*/

</style>

<style scoped>
>>> {
/*  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;
  --vs-dropdown-option-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;*/

  --vs-dropdown-option--active-bg: rgb(126,220,217);
  --vs-dropdown-option--active-color: #2c3e50;
  --vs-dropdown-option--active-fontSize: 23px;

  select.minimal {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: -o-linear-gradient(45deg, transparent 50%, gray 50%),
    -o-linear-gradient(315deg, gray 50%, transparent 50%),
    -o-linear-gradient(left, #ccc, #ccc);
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

/*.cropper {
  height: 600px;
  width: 600px;
  background: #DDD;
}

.modal-body .content-imagen-cropper {
    width: 80%;
    height: 400px;
    padding: 5px;
    outline: 1px solid var(--thir-color);
}*/

/*.modal-body .content-imagen-sample .img-sample{
    height: 100px;
    width: 100px;
}*/

.contenedor_editar_password {
/*  display: none;*/
}
.select_buscador .vs__search::placeholder,
.select_buscador .vs__dropdown-toggle,
.select_buscador .vs__dropdown-menu {
/*  background: red;*/
  background: #ffffff;
  border: 1px solid #ccc;
  color: black;
/*  text-transform: lowercase;*/
/*  font-variant: small-caps;*/
  font-size: 16px;
  font-family: arial;
}

.select_buscador .vs__dropdown-menu {
/*  background: red;*/
  padding: 0;
}

.select_buscador .vs__clear,
.select_buscador .vs__open-indicator {
  fill: #394066;
}

.vs__clear {
  margin-right: 4px;
}
}
</style>
