import { API } from './../constants'
import authService from './auth.js'
let productos = null
let punto = null

const productosService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerProductosTodos () {
    const fetchResponse = await fetch(`${API}/productos`)
    const respuesta = await fetchResponse.json()
    productos = respuesta.data
    return [...productos]
  },
  async traerProductosTodosConStock () {
    const fetchResponse = await fetch(`${API}/productos-puntos-de-venta`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    productos = respuesta.data
    return [...productos]
  },
  async traerPuntoDeEntregaPorId (id) {
    const fetchResponse = await fetch(`${API}/productos-punto-de-entrega/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    punto = respuesta.data[0]
    return { ...punto }
  },
  async traerProductosFaltantesPuntoDeEntregaPorId (id) {
    const fetchResponse = await fetch(`${API}/productos-faltantes-puntos-de-venta/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    productos = respuesta.data
    return [...productos]
  },
  editarStockPorPuntoDeEntregaId (data) {
    return fetch(`${API}/editar-stock-punto-de-entrega`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  agregarProducto (data) {
    return fetch(`${API}/agregar-producto`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(respuesta => {
        return { ...respuesta }
      })
  },
  borrarProductoConStockPorId (id) {
    return fetch(`${API}/eliminar-producto/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
      .then(rta => rta.json())
      .then(res => {
        return { ...res }
      })
  }
}

export default productosService
