<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <h1>Listado de Ventas</h1>
      <VentasLista
      ></VentasLista>
    </section>
  </div>
</template>

<script>
import VentasLista from '../components/VentasLista.vue'
// import Header2 from '../components/Header2.vue'
export default {
  name: 'Ventas',
  components: {
    VentasLista
    // Header2
  },
  data: function () {
    return {
    }
  }
}
</script>

<style>

</style>
