<template>
  <div class="login_form">
    <div class="contenedor-titulo">
      <h1>Crear/Editar Contraseña</h1>
      <div class="barra"></div>
    </div>
    <router-link :to="`/login`" class="boton_volver_login"><span>&#8592;</span> Atrás</router-link>
    <section class="contenedor-login">
      <div class="contenedor-login-img">
        <img src="@/assets/geetaji.jpg" alt="Geeta Iyengar">
      </div>
      <div class="baseloader" v-if="cargando">
        <BaseLoader/>
      </div>
      <div class="contenedor-login-form" v-else>
        <form
          action="#"
          @submit.prevent="recuperarPassword()"
        >
          <p class="token_texto">Te enviaremos un token a tu email para que puedas recuperar tu contraseña</p>
          <BaseNotification
            v-if="status.msg != null"
            :type="status.type"
            :text="status.msg"
            :closable="status.closable"
            @close="borrarNotificacion()"
          />
          <div class="form_section">
            <label for="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              v-model="credenciales.email"
            ><div
            class="form_mensaje_error_login"
              v-if="errores.email !== null"
            >
              {{ errores.email[0] }}
            </div>
          </div>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
// import store from './../store'
import personasService from '../services/personas'
import BaseNotification from './../components/BaseNotification.vue'
import BaseLoader from '../components/BaseLoader.vue'

export default {
  name: 'RecuperarPassword',
  components: {
    BaseNotification,
    BaseLoader
  },
  props: {},
  data: function () {
    return {
      cargando: false,
      credenciales: {
        email: null
      },
      status: {
        msg: null,
        type: 'success',
        closable: true
      },
      errores: {
        email: null
      }
      // store
    }
  },
  computed: {},
  methods: {
    borrarNotificacion () {
      this.status = {
        msg: null,
        type: 'success',
        closable: true
      }
    },
    recuperarPassword () {
      this.cargando = true
      personasService
        .recuperarPassword(this.credenciales)
        .then(res => {
          this.cargando = false
          this.borrarNotificacion()
          if (res.errors) {
            this.errores = {
              email: null,
              ...res.errors
            }
          } else if (res.success !== false) {
            this.$router.push('/generar-password/' + res.user)
          } else {
            this.status.msg = 'El email ingresado no figura en nuestros registros'
            this.status.type = 'error'
            this.errores = {
              email: null,
              ...res.errores
            }
          }
        })
    }
  }
}
</script>

<style>

</style>
