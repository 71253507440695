const store = {
  debug: true,
  token: null,
  status: {
    msg: null,
    type: 'success',
    closable: true
  },
  user: {
    id: null,
    condicion: null,
    email: null,
    telefono: null,
    nombre: null,
    apellido: null,
    imagen: null,
    tipo_id: null
  },
  setStatus (newStatus) {
    if (this.debug) {
      console.log('Store :: setStatus con la data: ', newStatus)
    }
    this.status = {
      ...this.status,
      ...newStatus
    }
  }
}

export default store
