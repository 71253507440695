<template>
  <div class="contenedor-difusion">
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Crear Pago</h1>
        <div class="barra"></div>
      </div>
      <section class="contenedor-login">
        <div class="contenedor-difusion-imagen">
          <img src="@/assets/geetaji.jpg" alt="Geeta Iyengar">
        </div>
        <div class="contenedor-difusion-text">
          <form
            action="#"
            method="post"
            @submit.prevent="crearPago(pago)"
            class="form_presupuesto_nuevo"
          >
            <div class="form_section">
              <label for="fecha_de_pago">Fecha de Pago</label>
              <input
                type="date"
                name="fecha_de_pago"
                id="fecha_de_pago"
                v-model="pago.fecha_de_pago"
                :disabled="cargando"
              >
              <div
              class="form_mensaje_error_crear_pago"
                v-if="errores.fecha_de_pago !== null"
              >
                {{ errores.fecha_de_pago[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="precio_total">Monto</label>
              <input
                type="text"
                name="precio_total"
                id="precio_total"
                v-model="pago.precio_total"
                :disabled="cargando"
              >
              <div
              class="form_mensaje_error_crear_pago"
                v-if="errores.precio_total !== null"
              >
                {{ errores.precio_total[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="moneda_id">Moneda</label>
              <select
                id="moneda_id"
                name="moneda_id"
                class="form minimal"
                v-model.number="pago.moneda_id"
                :disabled="cargando"
              >
                <option
                  v-for="concepto in monedas"
                  :value="concepto.moneda_id"
                  :key="concepto.moneda_id"
                >
                  {{ concepto.nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error_crear_pago"
                v-if="errores.moneda_id !== null"
              >
                {{ errores.moneda_id[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="servicio_id">Servicio</label>
              <select
                id="servicio_id"
                name="servicio_id"
                class="form minimal"
                v-model.number="pago.servicio_id"
                :disabled="cargando"
                @change="onChange($event)"
              >
                <option
                  v-for="servicio in servicios"
                  :value="servicio.servicio_id"
                  :key="servicio.servicio_id"
                >
                  {{ servicio.nombre }}
                </option>
              </select>
              <div
                class="form_mensaje_error_crear_pago"
                  v-if="errores.servicio_id !== null"
              >
                {{ errores.servicio_id[0] }}
              </div>
            </div>
            <div class="form_section" id="cuota">
              <label for="servicio_id">Cuota</label>
              <select
                id="cuota_id"
                name="cuota_id"
                class="form minimal"
                v-model.number="pago.cuota_id"
                :disabled="cargando"
              >
                <option
                  v-for="cuota in cuotasSelect"
                  :value="cuota.cuota_id"
                  :key="cuota.cuota_id"
                >
                  {{ cuota.descripcion }}
                </option>
              </select>
              <div
                class="form_mensaje_error_crear_pago"
                  v-if="errores.cuota_id !== null"
              >
                {{ errores.cuota_id[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="concepto_id">Concepto</label>
              <select
                id="concepto_id"
                name="concepto_id"
                class="form minimal"
                v-model.number="pago.concepto_id"
                :disabled="cargando"
              >
                <option
                  v-for="concepto in conceptos"
                  :value="concepto.concepto_id"
                  :key="concepto.concepto_id"
                >
                  {{ concepto.nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error_crear_pago"
                v-if="errores.concepto_id !== null"
              >
                {{ errores.concepto_id[0] }}
              </div>
            </div>
            <div class="form_section">
              <label for="forma_de_pago_id">Forma de Pago</label>
              <select
                id="forma_de_pago_id"
                name="forma_de_pago_id"
                class="form minimal"
                v-model.number="pago.forma_de_pago_id"
                :disabled="cargando"
              >
                <option
                  v-for="concepto in formasDePago"
                  :value="concepto.forma_de_pago_id"
                  :key="concepto.forma_de_pago_id"
                >
                  {{ concepto.nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error_crear_pago"
                v-if="errores.forma_de_pago_id !== null"
              >
                {{ errores.forma_de_pago_id[0] }}
              </div>
            </div>
            <!-- <div class="form_section">
              <label for="moneda_id">Moneda</label>
              <select
                id="moneda_id"
                name="moneda_id"
                class="form minimal"
                v-model.number="pago.moneda_id"
                :disabled="cargando"
              >
                <option
                  v-for="concepto in monedas"
                  :value="concepto.moneda_id"
                  :key="concepto.moneda_id"
                >
                  {{ concepto.nombre }}
                </option>
              </select>
              <div
              class="form_mensaje_error_crear_pago"
                v-if="errores.moneda_id !== null"
              >
                {{ errores.moneda_id[0] }}
              </div>
            </div> -->
            <div class="form_section">
              <label for="persona_id">Persona</label>
              <v-select
                class="contenedor_select_buscador"
                v-model="pago.persona_id"
                label="item_data"
                :reduce="socios => socios.persona_id"
                :options="socios"
                :disabled="cargando"
              >
                <span slot="no-options" @click="$refs.select.open = false" class="select_buscador_warning">
                  <p>La persona ingresada no figura en nuestros registros.</p>
                </span>
              </v-select>
              <div
              class="form_mensaje_error_crear_pago"
                v-if="errores.persona_id !== null"
              >
                {{ errores.persona_id[0] }}
              </div>
            </div>
<!--             <v-select
              class="select_buscador"
              v-model="pago.persona_id"
              label="item_data"
              :reduce="personas => personas.persona_id"
              :options="personas"
            >
            </v-select> -->
            <div class="form_section">
              <label for="descripcion">Descripción</label>
              <textarea
                name="descripcion"
                id="descripcion"
                v-model.number="pago.descripcion"
              ></textarea>
            </div>
            <button type="submit" class="boton_guardar">Crear Pago</button>
          </form>
        </div>
      </section>
    </section>
  </div>
</template>
<script src="https://unpkg.com/vue@2"></script>
<script src="https://unpkg.com/vue-select@latest"></script>
<script>
import 'vue-select/dist/vue-select.css';
import pagosService from '../services/pagos'
import serviciosService from '../services/servicios'
import asociadoService from '../services/asociado'
import store from './../store'

export default {
  name: 'crearPago',
  components: {
  },
  data: function () {
    return {
      options: [
        'foo',
        'bar',
        'baz'
      ],
      count: 1,
      conceptos: [],
      cuotasSelect: [],
      socios: [],
      servicios: [],
      formasDePago: [],
      monedas: [],
      years: [],
      personas: [],
      cargando: false,
      pago: {
        fecha_de_pago: null,
        persona_id: null,
        precio_total: null,
        concepto_id: null,
        cuota_id: null,
        servicio_id: null,
        forma_de_pago_id: null,
        moneda_id: null,
        descripcion: null
      },
      errores: {
        fecha_de_pago: null,
        persona_id: null,
        precio_total: null,
        concepto_id: null,
        cuota_id: null,
        forma_de_pago_id: null,
        moneda_id: null,
        servicio_id: null,
        descripcion: null
      },
      store
    }
  },
  methods: {
    // addComponent () {
    //   this.count += 1
    //   console.log(this.servicio.productos.push({
    //     servicio_id: null,
    //     precio: null,
    //     cantidad: null
    //   }))
    // },
    onChange (event) {
      // console.log(numero)
      // console.log(event.target.value)
      // console.log(event.target.name)
      // console.log(document.getElementsByClassName('year'))
      // if (event.target.value === '1' || event.target.value === '2' || event.target.value === '3' || event.target.value === '4') {
      //   console.log(event.target.value)
      //   document.getElementById('cuota').style.display = 'flex'
      // } else {
      //   document.getElementById('cuota').style.display = 'none'
      // }
      if (event.target.value != null) {
        console.log(event.target.value)

        document.getElementById('cuota').style.display = 'flex'
        serviciosService
          .listarServicioConCuotasPorId(event.target.value)
          .then(respuesta => {
            // this.cargando = false
            this.cuotasSelect = respuesta
            console.log('ServicioConInfo: ', respuesta)
          })

      }
      // console.log(this.productos[event.target.value - 1].precio)
      // // document.getElementsByName('precio-' + numero)[0].value = this.productos[event.target.value - 1].precio
      // this.servicio.productos[numero - 1].precio = this.productos[event.target.value - 1].precio
    },
    crearPago (pago) {
      console.log('qqqq')
      console.log(pago)
      if (this.cargando) {
        return null
      }
      // this.errores = {
      //   nombre: null,
      //   descripcion: null,
      //   precio: null,
      //   stock: null,
      //   categoria_id: null
      // }
      this.cargando = true
      pagosService
        .crearPago(pago)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.pago = {
              fecha_de_pago: null,
              persona_id: null,
              precio_total: null,
              servicio_id: null,
              cuota_id: null,
              concepto_id: null,
              forma_de_pago_id: null,
              moneda_id: null,
              descripcion: null
            }
            this.errores = {
              fecha_de_pago: null,
              persona_id: null,
              precio_total: null,
              concepto_id: null,
              forma_de_pago_id: null,
              moneda_id: null,
              cuota_id: null,
              servicio_id: null,
              descripcion: null
            }
            this.store.setStatus({
              msg: 'El pago se creo exitosamente',
              type: 'success'
            })
            this.$router.push('/panel-de-control')
          } else {
            this.errores = {
              fecha_de_pago: null,
              persona_id: null,
              precio_total: null,
              concepto_id: null,
              forma_de_pago_id: null,
              moneda_id: null,
              cuota_id: null,
              servicio_id: null,
              descripcion: null,
              ...respuesta.errors
            }
          }
        })
    }
  },
  mounted: function () {
    serviciosService
      .traerServiciosTodos()
      .then(respuesta => {
        // this.cargando = false
        this.servicios = respuesta
        console.log('Servicios: ', respuesta)
      })
    // pagosService
    //   .traerPersonas()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.personas = respuesta
    //     console.log('Personas: ', respuesta)
    //     this.personas.map(function (x){
    //       return x.item_data = x.nombre + ' ' + x.apellido;
    //     })
    //     console.log('Personaseeeee: ', respuesta)
    //   })
    asociadoService
      .listadoSociosSelect()
      .then(respuesta => {
        // this.cargando = false
        this.socios = respuesta
        console.log('listadoSociosSelect:', respuesta)
        this.socios.map(function (x) {
          // const pepe = x.nombre + ' ' + x.apellido
          // console.log(pepe)
          x.item_data = x.nombre + ' ' + x.apellido
          // return x.item_data = pepe
        })
      })
    pagosService
      .traerConceptosDePago()
      .then(respuesta => {
        // this.cargando = false
        this.conceptos = respuesta
        console.log('Conceptos: ', respuesta)
      })
    pagosService
      .traerFormasDePago()
      .then(respuesta => {
        // this.cargando = false
        this.formasDePago = respuesta
        console.log('Formas de Pago: ', respuesta)
      })
    pagosService
      .traerMonedas()
      .then(respuesta => {
        // this.cargando = false
        this.monedas = respuesta
        console.log('Monedas: ', respuesta)
      })
    pagosService
      .traerYearsDePago()
      .then(respuesta => {
        // this.cargando = false
        this.years = respuesta
        console.log('Years: ', respuesta)
      })
    // personasService
    //   .traerCertificacionesTodas()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.certificaciones = respuesta
    //   })
    // pagosService
    //   .traerFormasDePago()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.formasDePago = respuesta
    //   })
    // pagosService
    //   .traerMonedas()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.monedas = respuesta
    //   })
    // pagosService
    //   .traerPersonas()
    //   .then(respuesta => {
    //     // this.cargando = false
    //     this.personas = respuesta
    //     console.log('Personas: ', respuesta)
    //   })
  }
}
</script>
<style>
#cuota {
  display: none;
}
.select_buscador {
  flex: 1;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: black;
  margin: 10px 0;
}

.vs__selected-options {
/*  padding: 0 12px !important;*/
  position: relative;
}
.vs__search {
  border: none !important;
/*  border: 2px solid red !important;*/
  margin: 0 !important;
  height: 45px !important;
}
.vs--single .vs__selected {
  width: 100% !important;
  font-size: 1em;
}
.vs__selected {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 12px !important;
  color: black !important;
  margin: 12px 0 7px !important;
}
.vs__dropdown-toggle {
  padding: 0 !important;
}

.vs__clear {
/*  display: none;*/
}

.vs__open-indicator {
  display: none;
}

</style>
