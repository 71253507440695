<template>
  <tr>
    <td>{{ venta.fecha }}</td>
    <td>{{ venta.nombre }} {{ venta.apellido }}</td>
    <td>{{ venta.email }}</td>
    <td>{{ venta.descripcion }}</td>
    <td>{{ venta.mercadopago_id }}</td>
    <td>$ {{ dotsCadaTres(venta.precio) }}</td>
  </tr>
</template>

<script>
export default {
  name: 'VentasMercadoPagoNoIngresadasItem',
  components: {
  },
  props: ['venta'],
  data: function () {
    return {
      mostrar: true
    }
  },
  methods: {
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    }
  }
}
</script>

<style>

</style>
