<template>
  <div class="container">
    <!-- <BaseLoader
      v-if="cargando"
    /> -->
    <!-- <table v-else> -->
    <table id="tabla-stock">
      <thead>
        <th>Nombre</th>
        <th>Ubicación</th>
        <th class="no-borde">Distribución</th>
        <th class="nofuente gradient_2">ACCIONES</th>
      </thead>
      <tbody>
        <PuntosDeEntregaListaItem
          v-for="punto in puntos"
          :punto="punto"
          :key="punto.persona_id"
          @refrescar="refrescarStock"
        ></PuntosDeEntregaListaItem>
      </tbody>
    </table>
  </div>
</template>

<script>
import PuntosDeEntregaListaItem from '../components/PuntosDeEntregaListaItem.vue'
// import Header2 from '../components/Header2.vue'
import productosService from '../services/productos'
export default {
  name: 'PuntosDeEntregaLista',
  components: {
    PuntosDeEntregaListaItem
    // Header2
  },
  data: function () {
    return {
      puntos: []
    }
  },
  methods: {
    refrescarStock () {
      productosService
        .traerProductosTodosConStock()
        .then(respuesta => {
          // this.cargando = false
          this.puntos = respuesta
        })
    }
  },
  mounted: function () {
    // this.cargando = true
    productosService
      .traerProductosTodosConStock()
      .then(respuesta => {
        // this.cargando = false
        this.puntos = respuesta
        console.log('Todas los puntos de entrega con stock', respuesta)
      })
  }
}
</script>

<style>

#tabla-stock {
/*  font-family: Arial, Helvetica, sans-serif;*/
  border-collapse: collapse;
  width: 100%;
}

#tabla-stock td, #tabla-stock th {
  border: 1px solid #ddd;
  padding: 8px;
}

/*#tabla-stock tr:nth-child(even){background-color: #f2f2f2;}*/

/*#tabla-stock tr:hover {background-color: #ddd;}*/

#tabla-stock th {
  padding-top: 12px;
  padding-bottom: 12px;
/*  text-align: left;*/
  background-color: #04AA6D;
  color: white;
}

#tabla-stock td table {
  border-collapse: collapse;
  width: 100%;
}

.no-borde {
  border-bottom: 0 !important;
}
</style>
