<template>
  <tr>
    <!-- <td>
      <img v-if="comercio.imagen != null" :src="imagenRuta" :alt="`${comercio.nombre}`">
      <img v-else src="@/assets/image-not-available.jpg" :alt="`${comercio.nombre}`">
    </td> -->
    <td>{{ socio.nombre }}</td>
    <td>{{ socio.apellido }}</td>
    <td>{{ socio.perfil }}</td>
    <td v-if="socio.perfil.nombre != 'Practicante'">{{ socio.certificacion }}</td>
    <td v-else></td>
    <!-- <td v-if="socio.fecha_ingreso != null">{{ fecha(socio.fecha_ingreso) }}</td>
    <td v-else></td>
    <td>{{ socio.tipo }}</td> -->
    <td>{{ dotsCadaTres(socio.dni) }}</td>
    <!-- <td>{{ socio.email }}</td> -->
    <td>{{ socio.celular }}</td>
    <td>{{ socio.direccion }}</td>
    <td>{{ socio.toma_clase }}</td>
    <td>{{ socio.condicion }}</td>
    <td>
      <div v-if="socio.estudio_nombre_2 == null">
        {{ socio.estudio_nombre_1 }}
      </div>
      <div v-else>
        {{ socio.estudio_nombre_1 }} <br> {{ socio.estudio_nombre_2 }}
      </div>
    </td>
    <!-- <td  v-if="socio.mentorias.length != 0">
      <div class="socio_certificacion">
        <span>Comienzo: {{ socio.mentorias[0].comienzo }}</span>
        <span>Certificación: {{ socio.certificacion }}</span>
        <span>Profe: {{ socio.mentorias[0].mentor_nombre }} {{ socio.mentorias[0].mentor_apellido }}</span>
      </div>
    </td>
    <td v-else></td> -->
    <td><router-link :to="`/socio/${socio.persona_id}`" class="boton_direccion boton_editar social-icon icon-pencil-square-o tabla-link"></router-link></td>
  </tr>
</template>

<script>
// import comerciosService from '../services/comercios'
// import store from './../store'
// import { PATH_IMG } from '../constants'
export default {
  name: 'SociosListaItem',
  props: ['socio'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    },
    dotsCadaTres (dni) {
      if (dni == null) {
        return null
      }
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    abrirModal (id) {
      console.log(id)
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'flex'
    },
    cerrarModal (id) {
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'none'
    }
    // tipo (tipo) {
    //   switch (tipo) {
    //     case 1:
    //       return 'comercio_tipo_restaurant'
    //     case 2:
    //       return 'comercio_tipo_hotel'
    //     case 3:
    //       return 'comercio_tipo_bar'
    //   }
    //   return 'comercio_tipo_restaurant'
    // }
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  }
}
</script>

<style>

</style>
