<template>
  <div class="login_form">
    <div class="contenedor-titulo">
      <h1>Iniciar Sesión</h1>
      <div class="barra"></div>
    </div>
    <section class="contenedor-login">
      <div class="contenedor-login-img">
        <img src="@/assets/patanjali.jpg" alt="Patanjali">
      </div>
      <div class="contenedor-login-form">
        <form
          action="#"
          @submit.prevent="login()"
        >
          <BaseNotification
            v-if="status.msg != null"
            :type="status.type"
            :text="status.msg"
            :closable="status.closable"
            @close="borrarNotificacion()"
          />
          <div class="form_section">
            <label for="dni">DNI</label>
            <input
              type="dni"
              name="dni"
              id="dni"
              v-model="credenciales.dni"
              placeholder="Ej: 12765345"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error_login"
              v-if="errores.dni !== null"
            >
              {{ errores.dni[0] }}
            </div>
          </div>
          <div class="form_section contenedor-ojo">
            <label for="password">Contraseña</label>
            <input
              type="password"
              name="password"
              id="password"
              v-model="credenciales.password"
              :disabled="cargando"
              class="campo-ojo"
            ><div
            class="form_mensaje_error_login"
              v-if="errores.password !== null"
            >
              {{ errores.password[0] }}
            </div>
            <span class="icon icon-eye-slash ojo" id="ojo_login" @click="mostrarPassword(0)"></span>
          </div>
          <button type="submit">
            <span
              v-if="!cargando"
            >
              Ingresar
            </span>
            <BaseLoader3 v-else
            />
          </button>
        </form>
        <p class="register_link">¿Olvidaste la contraseña? <router-link to="/recuperar-password">Crear una nueva</router-link></p>
        <p class="register_link">¿Todavía no sos socio? <router-link to="/asociarse">Asociate</router-link></p>
        <p class="register_link">¿Necesitas ayuda? <a href="https://asociacioniyengar.com.ar/contacto.php" target="_blank">Contáctanos</a></p>
      </div>
    </section>
  </div>
</template>

<script>
import store from './../store'
import authService from './../services/auth'
import BaseNotification from '../components/BaseNotification'
import BaseLoader3 from './BaseLoader3.vue'

export default {
  name: 'LoginForm',
  components: {
    BaseNotification,
    BaseLoader3
  },
  data: function () {
    return {
      cargando: false,
      credenciales: {
        dni: null,
        password: null
      },
      status: {
        msg: null,
        type: 'success',
        closable: true
      },
      errores: {
        dni: null,
        password: null
      },
      store
    }
  },
  computed: {},
  methods: {
    borrarNotificacion () {
      this.status = {
        msg: null,
        type: 'success',
        closable: true
      }
      this.store.setStatus({
        msg: null,
        type: null,
        title: null
      })
    },
    login () {
      if (this.cargando) {
        return null
      }
      this.errores = {
        dni: null,
        password: null
      }
      this.cargando = true
      authService
        .login(this.credenciales.dni, this.credenciales.password)
        .then(respuesta => {
          this.cargando = false
          this.borrarNotificacion()
          if (respuesta.errors) {
            this.errores = {
              password: null,
              dni: null,
              ...respuesta.errors
            }
          } else if (respuesta.success === true) {
            console.log('ID Usuario: ', respuesta)
            // Guardo el usuario autenticado en el store
            this.store.user = respuesta.data.user
            this.$router.push('/datos-personales')
            // if (respuesta.data.user.socio_id === 1) {
            //   // Redireccion si es Administrador
            //   this.$router.push('/datos-personales')
            // }
          } else if (respuesta.success === false && respuesta.nota != null) {
            console.log(respuesta)
            this.status.msg = 'El usuario está dado de baja. Comunicate con la AAYI'
            this.status.type = 'error'
            this.errores = {
              dni: null,
              password: null,
              ...respuesta.errores
            }
          } else {
            console.log(respuesta)
            this.status.msg = 'Los datos ingresados son incorrectos'
            this.status.type = 'error'
            this.errores = {
              dni: null,
              password: null,
              ...respuesta.errores
            }
          }
        })
    },
    mostrarPassword (id) {
      const ojo = document.querySelectorAll('.ojo')
      const input = document.querySelectorAll('.campo-ojo')
      if (ojo[id].getAttribute('class') === 'icon icon-eye-slash ojo') {
        ojo[id].removeAttribute('class')
        ojo[id].setAttribute('class', 'icon icon-eye ojo ojo-naranja')
        input[id].setAttribute('type', 'text')
      } else {
        ojo[id].removeAttribute('class')
        ojo[id].setAttribute('class', 'icon icon-eye-slash ojo')
        input[id].setAttribute('type', 'password')
      }
    }
  }
}
</script>

<style>

</style>
