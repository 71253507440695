<template>
  <tr>
    <td>{{ fecha(pago.fecha) }}</td>
    <td class="contenedor_concepto_hitorial_pagos"><span>{{ pago.servicio }}</span><span class="cuota_numero">({{pago.cuota}}/{{pago.cantidad_cuotas}})</span></td>
    <td>
      <span v-if="pago.moneda != 2">$</span>
      <span v-else >U$D</span>
        <!-- {{ parseFloat(pago.monto).toFixed(2).replace(/\.00$/, '') }} -->
        {{ dotsCadaTres(parseFloat(pago.monto).toFixed(2).replace(/\.00$/, '')) }}
    </td>
    <!-- <td><a :href="`${pago.factura_url}`" class="icon icon-book-dowload-streamline icono-descarga" target="_blank" v-if="this.mostrar"></a></td> -->
  </tr>
</template>

<script>
export default {
  name: 'HistorialPagoListaItem',
  components: {
  },
  props: ['pago'],
  data: function () {
    return {
      mostrar: true
    }
  },
  methods: {
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    }
  },
  mounted: function () {
    if (this.pago.factura_url === null) {
      this.mostrar = false
    }
  }
}
</script>

<style>
.icono-descarga {
  text-decoration: none;
  color: rgb(126,220,217);
  border: none;
  padding: 2px 5px;
  font: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 27px;
  line-height: 100%;
/*  margin: 0 12px;
  display: flex;*/
}
</style>
