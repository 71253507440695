<template>
  <section class="contenedor-datos-personales">
    <BaseNotification2
      v-if="store.status.msg != null"
      :type="store.status.type"
      :text="store.status.msg"
      :closable="store.status.closable"
      @close="borrarNotificacion()"
    />
    <div class="contenedor-titulo">
      <h1>Panel del Socio</h1>
      <div class="barra"></div>
    </div>
    <!-- <router-link :to="`/socios`" v-if="this.socio.tipo_id == 1">IR A CPANEL</router-link> -->
    <div class="contenedor_datos_personales">
      <BaseLoader
        v-if="cargando"
      />
      <div class="contenedor_datos_personales_izquierda" v-else>
        <router-link :to="`/editar-socio`" class="boton-editar-socio">Editar</router-link>
        <div class="imagen-editar-datos-socio">
          <img v-if="socio.imagen_perfil != null" :src="imagenRuta" :alt="`${socio.nombre} ${socio.apellido}`">
          <img v-else src="@/assets/image-not-available.jpg" :alt="`${socio.nombre}`">
        </div>
        <h2>Datos Personales</h2>
        <div class="contenedor_redes">
          <a class="social-icon icon-instagrem instagram" :href="`${socio.instagram}`" target="_blank" v-bind:class = "(socio.instagram === null)?'disabled':'else_class'"></a>
          <a class="social-icon icon-social-twitter twitter" :href="`${socio.twitter}`" target="_blank" v-bind:class = "(socio.twitter === null)?'disabled':'else_class'"></a>
          <a class="social-icon icon-facebook facebook" :href="`${socio.facebook}`" target="_blank" v-bind:class = "(socio.facebook === null)?'disabled':'else_class'"></a>
          <a class="social-icon icon-web web" :href="`${socio.website}`" target="_blank" v-bind:class = "(socio.website === null)?'disabled':'else_class'"></a>
        </div>
        <dl class="contenedor_datos_personales_socio">
          <dt>Nombre</dt>
          <dd>{{socio.nombre}}</dd>
          <dt>Apellido</dt>
          <dd>{{socio.apellido}}</dd>
          <dt>Email</dt>
          <dd>{{socio.email}}</dd>
          <dt>Teléfono</dt>
          <dd>{{socio.telefono}}</dd>
          <dt>Celular</dt>
          <dd>{{socio.celular}}</dd>
          <dt>DNI</dt>
          <dd>{{dotsCadaTres(socio.dni)}}</dd>
          <dt>Mi profe es</dt>
          <dd v-if="this.socio.es_alumno_de != null">{{socio.es_alumno_de.nombre}} {{socio.es_alumno_de.apellido}}</dd>
          <dd v-else></dd>
          <dt v-if="this.socio.latitude_1 == null">Domicilio: </dt>
          <dd v-if="this.socio.latitude_1 == null"></dd>
        </dl>
        <div v-if="this.socio.latitude_1 != null" class="contenedor_datos_direccion">
          <dl>
            <dt v-if="this.socio.perfil_id == 1">Nombre del Estudio</dt>
            <dd v-if="this.socio.perfil_id == 1">{{socio.estudio_nombre_1}}</dd>
            <dt>Domicilio</dt>
            <dd>{{socio.direccion_1}}</dd>
            <dt class="ciudad_localidad_barrio_1">Ciudad / Localidad / Barrio</dt>
            <dt class="ciudad_localidad_barrio_2">Ciudad <br> Localidad <br> Barrio</dt>
            <dd class="centrar_text">{{socio.ciudad_1}}</dd>
            <dt>Provincia</dt>
            <dd v-if="this.socio.provincia_id_1 != null">{{socio.provincia1.nombre}}</dd>
            <dd v-else></dd>
            <dt>País</dt>
            <dd v-if="this.socio.pais_id_1 != null">{{socio.pais1.nombre}}</dd>
            <dd v-else></dd>
          </dl>
          <gmap-map
            v-if="this.socio.latitude_1 != null"
            :center="center"
            :zoom="mapZoom"
            map-type-id="roadmap"
            :options="{
              mapTypeControl: false,
              keyboardShortcuts: false,
              // mapData: false,
              streetViewControl: false,
              disableDefaultUI: true,
              styles: styles['hide'] // Saco todos los iconos de los comercios
            }"
            class="mapa-datos-personales"
          >
            <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              :icon="require('@/assets/pin.png')"
              @click="center=m.position"
            ></gmap-marker>
          </gmap-map>
        </div>
        <div v-if="this.socio.latitude_2 != null" class="contenedor_datos_direccion">
          <dl>
            <dt>Nombre del Estudio</dt>
            <dd>{{socio.estudio_nombre_2}}</dd>
            <dt>Domicilio</dt>
            <dd>{{socio.direccion_2}}</dd>
            <dt>Ciudad <br> Localidad <br> Barrio</dt>
            <dd class="centrar_text">{{socio.ciudad_2}}</dd>
            <dt>Provincia</dt>
            <dd>{{socio.provincia2.nombre}}</dd>
            <dt>País</dt>
            <dd>{{socio.pais2.nombre}}</dd>
          </dl>
          <gmap-map
            :center="center2"
            :zoom="mapZoom"
            map-type-id="roadmap"
            :options="{
              mapTypeControl: false,
              keyboardShortcuts: false,
              // mapData: false,
              streetViewControl: false,
              disableDefaultUI: true,
              styles: styles['hide'] // Saco todos los iconos de los comercios
            }"
            class="mapa-datos-personales"
          >
            <gmap-marker
              :key="index"
              v-for="(m, index) in markers2"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              :icon="require('@/assets/pin.png')"
              @click="center=m.position"
            ></gmap-marker>
          </gmap-map>
        </div>
      </div>
      <div class="contenedor_datos_personales_centro">
        <section class="datos-del-socio">
          <h2>Datos del Socio</h2>
          <BaseLoader
            v-if="cargando"
          />
          <dl v-else>
            <dt>Fecha de Ingreso</dt>
            <dd v-if="socio.fecha_ingreso != null">{{ fecha(socio.fecha_ingreso) }}</dd>
            <dd v-else></dd>
            <!-- <dt>Condición del Socio</dt>
            <dd>{{socio.condicion.nombre}}</dd> -->
            <dt>Perfil</dt>
            <dd>{{socio.perfil.nombre}}</dd>
          </dl>
          <img src="@/assets/logo-aayi-redondo.png" alt="Logo AAYI" class="logo-aayi-redondo">
          <button
              v-if="socio.perfil_id == 1"
              @click="abrirLogo"
              class="boton-logo"
            >
              Logo Profesores Certificados
            </button>
        </section>
        <section class="cuota-social cuota-social-linea">
          <h2>Cuota Social</h2>

          <!-- <div v-if="false">  -->
          <div v-if="botonMercadoPago">
            <p class="cuota-social-adeudadas">Estas adeudando:</p>
            <ul class="contenedor_lista_cuotas_no_pagas">
              <li v-for="item in cuotasImpagas" :key="item.cuota_id" class="no-pago">
                <table v-if="item.cuotas_juntas == null">
                  <tr>
                    <!-- <th>Concepto</th> -->
                    <td>{{ item.nombre }} - Cuota # {{ item.cuota_numero }}/{{ item.cantidad_cuotas }}</td>
                  </tr>
                  <!-- <tr>
                    <th>Período de Pago</th>
                    <td>
                      <span>Desde: {{ fecha(item.fecha_inicio)}}</span>
                      <span>Hasta: {{ fecha(item.fecha_finalizacion)}}</span>
                    </td>
                  </tr> -->
                </table>
              </li>
            </ul>
            <p class="total_adeudado">Total adeudado: ${{ dotsCadaTres(parseFloat(cuotasImpagas[cuotasImpagas.length - 1].precio_total).toFixed(2).replace(/\.00$/, '')) }}</p>
            <button
              v-if="esArgento"
              @click="checkDni"
              class="boton-pagar-cuota-social"
            >
              Pagar
            </button>
            <P v-else class="warning_no_profe">No se pueden hacer pagos desde el extranjero por la web. Por favor envianos un email a <br> <a href="mailto:tesoreria@asociacioniyengar.com.ar" target="_blank">tesoreria@asociacioniyengar.com.ar</a> </P>
            <p v-if="mostrar" class="warning_no_profe">Por favor, completa el DNI y el domicilio para poder realizar tu pago</p>
            <P v-if="noTieneProfe" class="warning_no_profe">Por favor, completa quien es tu profe regular para poder realizar tu pago</P>
          </div>
              <div v-if="cuotasPorVencer.length != 0">
                <p class="cuota-social-adeudadas">Cuotas por vencer:</p>
                <ul class="contenedor_lista_cuotas_no_pagas">
                  <li v-for="item in cuotasPorVencer" :key="item.cuota_id" class="no-pago">
                    <!-- <div v-if="item.cuotas_juntas == null"><span>&#8226;</span> {{ item.nombre }} - Cuota # {{ item.cuota_numero }} - ${{ item.precio }}</div>
                    <div>Fecha de pago: Desde: {{ fecha(item.fecha_inicio)}} Hasta: {{ fecha(item.fecha_finalizacion)}}</div> -->
                    <table v-if="item.cuotas_juntas == null">
                      <tr>
                        <th>Concepto</th>
                        <td>{{ item.nombre }} - Cuota # {{ item.cuota_numero }}</td>
                      </tr>
                      <tr>
                        <th>Período de Pago</th>
                        <td>
                          <span>Desde: {{ fecha(item.fecha_inicio)}}</span>
                          <span>Hasta: {{ fecha(item.fecha_finalizacion)}}</span>
                        </td>
                      </tr>
                    </table>
                  </li>
                </ul>
              </div>
         <!--  </div>
          <p v-else >No hay cuotas impagas</p> -->
        </section>
        <section class="cuota-social regalias" v-if="socio.perfil_id == 1">
          <h2>Regalías</h2>
          <div v-if="regaliasImpagas.length != 0">
            <p class="cuota-social-adeudadas">Estas adeudando:</p>
            <ul class="contenedor_lista_cuotas_no_pagas">
              <li v-for="item in regaliasImpagas" :key="item.id" class="no-pago">
                <table v-if="new Date().getTime() > new Date(item.fecha_inicio).getTime()">
                  <tr>
                    <th>Concepto</th>
                    <td>{{ item.nombre }}</td>
                  </tr>
                  <tr>
                    <th>Monto</th>
                    <td>
                      U$D {{ parseFloat(item.precio).toFixed(2).replace(/\.00$/, '') }}
                    </td>
                  </tr>
                  <tr>
                    <th>Período de Pago</th>
                    <td>
                      <span>Desde: {{ fecha(item.fecha_inicio)}}</span>
                      <span>Hasta: {{ fecha(item.fecha_finalizacion)}}</span>
                    </td>
                  </tr>
                </table>
                <div v-if="new Date(item.fecha_finalizacion).getTime() < new Date().getTime()" class="warning_regalias_vecidas"><span>Las {{ item.nombre }} están vencidas</span></div>
              </li>
            </ul>
          </div>
          <p v-else >No hay regalías impagas</p>
        </section>
      </div>
      <div class="contenedor_datos_personales_derecha">
        <!-- <h2>Seminarios AAYI</h2>
        <Seminarios
        ></Seminarios> -->
        <h2>Historial de Pagos</h2>
        <HistorialPagoLista
        ></HistorialPagoLista>
      </div>
    </div>
    <div class="cho-container"></div>
  </section>
</template>

<script>
import store from '../store'
import BaseNotification2 from '../components/BaseNotification2'
// import Seminarios from '../components/Seminarios.vue'
import HistorialPagoLista from '../components/HistorialPagoLista.vue'
import BaseLoader from '../components/BaseLoader.vue'
// import Header2 from '../components/Header2.vue'
import asociadoService from '../services/asociado'
import pagosService from '../services/pagos'
import { PATH_IMG } from '../constants'
const mercadopago = require('mercadopago')
export default {
  name: 'DatosPersonales',
  components: {
    HistorialPagoLista,
    // Seminarios,
    BaseLoader,
    BaseNotification2
  },
  data: function () {
    return {
      mostrar: false,
      noTieneProfe: false,
      esArgento: true,
      linkMercadoPago: {
        link: null
      },
      pepe: 45,
      cuotaSocial: {},
      socio: [],
      cuotasPagas: [],
      cuotasPorVencer: [],
      cuotasImpagas: [],
      regaliasImpagas: [],
      user: {
        tipo_id: JSON.parse(localStorage.getItem('userData')).tipo_id,
        persona_id: JSON.parse(localStorage.getItem('userData')).persona_id,
        nombre: JSON.parse(localStorage.getItem('userData')).nombre,
        apellido: JSON.parse(localStorage.getItem('userData')).apellido,
        dni: JSON.parse(localStorage.getItem('userData')).dni,
        email: JSON.parse(localStorage.getItem('userData')).email,
        direccion_1: JSON.parse(localStorage.getItem('userData')).direccion_1,
        provincia_id_1: JSON.parse(localStorage.getItem('userData')).provincia_id_1
      },
      cargando: false,
      botonMercadoPago: false,
      center: {
        lat: null,
        lng: null
      },
      center2: {
        lat: null,
        lng: null
      },
      mapZoom: 15,
      markers: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      markers2: [{
        position: { lat: -34.580578, lng: -58.440914 }
      }],
      styles: {
        hide: [
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ],
        retro: [
          { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#c9b2a6' }]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#dcd2be' }]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#ae9e90' }]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#93817c' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#a5b076' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#447530' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#f5f1e6' }]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#fdfcf8' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#f8c967' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#e9bc62' }]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{ color: '#e98d58' }]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#db8555' }]
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#806b63' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8f7d77' }]
          },
          {
            featureType: 'transit.line',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#ebe3cd' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: '#dfd2ae' }]
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ color: '#b9d3c2' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#92998d' }]
          }
        ]
      },
      store
    }
  },
  methods: {
    abrirLogo: function () {
      window.open('https://asociacioniyengar.com.ar/pdf/Logo_Profesores_Certificados.pdf', '_blank')
    },
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    checkDni () {
      if (this.socio.dni == null || this.socio.direccion_1 == null || this.socio.ciudad_1 == null || this.socio.provincia_id_1 == null || this.socio.pais_id_1 == null) {
        this.mostrar = true
      } else if (this.socio.toma_clase == null && this.socio.perfil_id === 1) {
        this.noTieneProfe = true
      } else {
        console.log('QQQQQQQQ7777777777')
        window.location.href = this.linkMercadoPago.link
      }
    },
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    },
    refrescarProfesores () {
      console.log('qwqwwqwqwqwqw454545454545')
    },
    borrarNotificacion () {
      this.store.setStatus({
        msg: null,
        type: null,
        title: null
      })
    }
  },
  computed: {
    imagenRuta: function () {
      return `${PATH_IMG}/${this.socio.imagen_perfil}`
    }
  },
  mounted: function () {
    const date = new Date()
    const dateWithoutTime = date.toLocaleDateString()
    console.log(dateWithoutTime)
    console.log(new Date().getTime())
    const date1 = new Date('2024-01-01').getTime()
    const date2 = new Date('2024-01-15').getTime()
    if (date1 < date2) {
      console.log('date1 is earlier than date2')
    }
    setTimeout(() => {
      this.borrarNotificacion()
    }, 4000)
    this.cargando = true
    asociadoService
      .traerSocioPorId(this.user.persona_id)
      .then(respuesta => {
        this.cargando = false
        this.socio = respuesta
        this.center.lat = parseFloat(respuesta.latitude_1)
        this.center.lng = parseFloat(respuesta.longitude_1)
        this.center2.lat = parseFloat(respuesta.latitude_2)
        this.center2.lng = parseFloat(respuesta.longitude_2)
        this.markers[0].position.lat = parseFloat(respuesta.latitude_1)
        this.markers[0].position.lng = parseFloat(respuesta.longitude_1)
        this.markers2[0].position.lat = parseFloat(respuesta.latitude_2)
        this.markers2[0].position.lng = parseFloat(respuesta.longitude_2)
        console.log('Socio:', respuesta)
        if (this.socio.pais_id_1 === 2) {
          this.esArgento = false
          console.log('qqqwwweeerrrrrtttyyyyy')
        }
      })
    pagosService
      .traerCuotasPagasPorAsociadoId(this.user.persona_id)
      .then(respuesta => {
        // this.cargando = false
        this.cuotasPagas = respuesta
        console.log('Cuotas Pagas:', respuesta)
      })
    pagosService
      .traerCuotasPagasPorVencer()
      .then(respuesta => {
        // this.cargando = false
        this.cuotasPorVencer = respuesta
        console.log('Cuotas Por Vencer:', respuesta)
      })
    pagosService
      .traerCuotasImpagasPorAsociadoId(this.user.persona_id)
      .then(respuesta => {
        // this.cargando = false
        this.cuotasImpagas = respuesta
        console.log('Cuotas Impagas:', respuesta)
        if (respuesta[0].length !== 0) {
          this.botonMercadoPago = true
        }
        const preference = {
          // trackingId: 77,
          back_urls: {
            failure: 'https://portal.asociacioniyengar.com.ar/#/pago-rechazado',
            success: 'https://portal.asociacioniyengar.com.ar/#/pago-exitoso'
          },
          items: [
            {
              // id: respuesta.concepto_id,
              title: String(respuesta[respuesta.length - 1].descripcion),
              // unit_price: 44,
              unit_price: Number(respuesta[respuesta.length - 1].precio_total),
              quantity: 1
            }
          ],
          metadata: {
            persona_id: this.user.persona_id,
            nombre: this.user.nombre,
            email: this.user.email,
            apellido: this.user.apellido,
            direccion: this.user.direccion_1,
            provincia: this.user.provincia_id_1,
            dni: this.user.dni,
            concepto_id: 1,
            forma_de_pago_id: 3,
            moneda_id: 1,
            pago_concepto: 'Cuota Social',
            descripcion: String(respuesta[respuesta.length - 1].descripcion),
            precio_individual: Number(respuesta[respuesta.length - 1].precio_individual),
            precio_total: Number(respuesta[respuesta.length - 1].precio_total),
            cuotas_adeudadas: Array(respuesta[respuesta.length - 1].cuotas_adeudadas)
            // descripcion: 'Pago Cuota Social ' + new Date().getFullYear(),
            // year: new Date().getFullYear()
          },
          payer: {
            name: this.user.nombre,
            surname: this.user.apellido
          },
          notification_url: 'https://api.asociacioniyengar.com.ar/api/webhooks',
          // notification_url: 'https://c74e-190-194-214-138.ngrok-free.app/AAYI_API/api/public/api/webhooks-test',
          binary_mode: true,
          auto_return: 'approved',
          external_reference: this.user.nombre + ' ' + this.user.apellido + ' - ' + new Date().getTime().toString(),
          statement_descriptor: 'Asociación Argentina de Yoga Iyengar'
        }
        mercadopago.configure({
          access_token: 'APP_USR-6447832264591121-032711-ad6690b0c38d8e10440c46daab8b7204-1323684790'
          // Credenciales de Prueba
          // access_token: 'APP_USR-2339860730955398-032713-925884898e35a8f3be76c84328e45b4f-1339687465'
        })
        mercadopago.preferences
          .create(preference)
          .then(response => {
            console.log('mercadopago: ', response.body)
            console.log(new Date().getTime())
            // console.log(this.linkMercadoPago.link)
            this.linkMercadoPago.link = response.body.init_point
            // En esta instancia deberás asignar el valor dentro de response.body.id por el ID de preferencia solicitado en el siguiente paso
          })
          .catch(function (error) {
            console.log('MP ERROR!!!!!')
            console.log(error)
          })
      })
    pagosService
      .traerRegaliasImpagasPorAsociadoId(this.user.persona_id)
      .then(respuesta => {
        // this.cargando = false
        this.regaliasImpagas = respuesta
        console.log('Regalias Impagas:', respuesta)
      })
    pagosService
      .traerConceptoCuotaSocial()
      .then(respuesta => {
        // this.cargando = false
        this.cuotaSocial = respuesta
        console.log('Cuota Social:', respuesta)
      })
  }
}

</script>

<!-- <script src="https://sdk.mercadopago.com/js/v2"></script> -->

<!-- <script>
  const mp = new MercadoPago('PUBLIC_KEY', {
    locale: 'es-AR'
  });

  mp.checkout({
    preference: {
      id: 'YOUR_PREFERENCE_ID'
    },
    render: {
      container: '.cho-container',
      label: 'Pagar',
    }
  });
</script> -->
