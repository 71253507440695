<template>
  <div>
    <h1>Editar Stock</h1>
    <h2>{{ servicio.servicio_nombre}}</h2>
    <div>
      <form
        action="#"
        method="post"
        @submit.prevent="editarStock(servicio, $route.params.id)"
        class="form_presupuesto_nuevo"
      >
        <p>Stock Total {{servicio.stock_total}}</p>
          <!-- <div class="form_section">
            <label for="stock">Stock Total</label>
            <input
              type="number"
              name="stock"
              id="stock"
              v-model="servicio.stock_total"
              :disabled="cargando"
            >
            <div
            class="form_mensaje_error"
              v-if="errores.stock !== null"
            >
              {{ errores.stock[0] }}
            </div>
          </div> -->
        <EditarStockFormItem
          v-for="persona in servicio.personas"
          :persona="persona"
          :key="persona.persona_id"
          @refrescar="refrescarPersonas"
        ></EditarStockFormItem>
        <!-- <p>Depósito AAYI: {{servicio.stock_deposito_aayi}} unidades</p> -->
        <button type="submit" class="boton_guardar">EDITAR STOCK</button>
      </form>
    </div>
    <div>
      <h2>Agregar Persona</h2>
      <form
        action="#"
        method="post"
        @submit.prevent="agregarPersona(persona)"
        class="form_presupuesto_nuevo"
      >
        <div class="form_section">
          <label for="persona_id">Persona</label>
          <select
            id="persona_id"
            name="persona_id"
            class="form minimal"
            v-model.number="persona.persona_id"
            :disabled="cargando"
          >
            <option
              v-for="persona in personas"
              :value="persona.persona_id"
              :key="persona.persona_id"
            >
              {{ persona.nombre }} {{ persona.apellido }}
            </option>
          </select>
          <div
          class="form_mensaje_error"
            v-if="errores.persona_id !== null"
          >
            {{ errores.persona_id[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="stock">Stock</label>
          <input
            type="number"
            name="stock"
            id="stock"
            v-model="persona.stock"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.stock !== null"
          >
            {{ errores.stock[0] }}
          </div>
        </div>
        <div class="form_section">
          <label for="fecha">Fecha del Ingreso</label>
          <input
            type="date"
            name="fecha"
            id="fecha"
            v-model="persona.fecha"
            :disabled="cargando"
          >
          <div
          class="form_mensaje_error"
            v-if="errores.fecha !== null"
          >
            {{ errores.fecha[0] }}
          </div>
        </div>
        <button type="submit" class="boton_guardar">Agregar persona</button>
      </form>
    </div>
  </div>
</template>

<script>
import entregasService from '../services/entregas'
import serviciosService from '../services/servicios'
import EditarStockFormItem from '../components/EditarStockFormItem.vue'
// import Footer from '../components/Footer.vue'

export default {
  name: 'EditarStock',
  components: {
    EditarStockFormItem
  },
  data: function () {
    return {
      servicio: {
        stock: null
      },
      personas: [],
      persona: {
        persona_id: null,
        fecha: null,
        stock: null,
        servicio_id: this.$route.params.id
      },
      errores: {
        stock: null,
        fecha: null,
        persona_id: null
      },
      cargando: false
    }
  },
  methods: {
    agregarPersona (persona) {
      console.log(persona)
      serviciosService
        .agregarPersona(persona)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.refrescarPersonas()
            // this.pago = {
            //   precio_total: null,
            //   fecha_de_pago: null,
            //   concepto_id: null,
            //   forma_de_pago_id: null,
            //   persona_id: null,
            //   year_id: null,
            //   moneda_id: null,
            //   descripcion: null
            // }
            // this.errores = {
            //   nombre: null,
            //   precio_total: null,
            //   fecha_de_pago: null,
            //   year_id: null,
            //   concepto_id: null,
            //   forma_de_pago_id: null,
            //   persona_id: null,
            //   moneda_id: null,
            //   descripcion: null
            // }
            // this.$router.push('/presupuesto')
          } else {
            // console.log(respuesta.errores[0])
            this.errores = {
              stock: null,
              persona_id: respuesta.errores
              // ...respuesta.errors
            }
          }
        })
    },
    editarStock (servicio, id) {
      console.log(id)
      console.log(servicio)
      if (this.cargando) {
        return null
      }
      this.errores = {
        stock: null,
        fecha: null,
        persona_id: null
      }
      this.cargando = true
      serviciosService
        .editarStockPorId(servicio, id)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SIIIIII')
            this.pago = {
              precio_total: null,
              fecha_de_pago: null,
              concepto_id: null,
              forma_de_pago_id: null,
              persona_id: null,
              year_id: null,
              moneda_id: null,
              descripcion: null
            }
            this.errores = {
              stock: null,
              fecha: null,
              persona_id: null
            }
            this.$router.push('/stock')
          } else {
            this.errores = {
              nombre: null,
              precio_total: null,
              fecha_de_pago: null,
              year_id: null,
              concepto_id: null,
              forma_de_pago_id: null,
              persona_id: null,
              moneda_id: null,
              descripcion: null,
              ...respuesta.errors
            }
          }
        })
    },
    refrescarPersonas () {
      serviciosService
        .traerServicioConStockPorId(this.$route.params.id)
        .then(respuesta => {
          // this.cargando = false
          this.servicio = respuesta
          console.log('Servicio', respuesta)
        })
    }
  },
  mounted: function () {
    // this.cargando = true
    serviciosService
      .traerServicioConStockPorId(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.servicio = respuesta
        console.log('Servicio', respuesta)
      })
    entregasService
      .traerPersonasSinStock(this.$route.params.id)
      .then(respuesta => {
        // this.cargando = false
        this.personas = respuesta
        console.log('Personas: ', respuesta)
      })
  },
  // mounted: function () {
  //   pagosService
  //     .traerPago(this.$route.params.id)
  //     .then(respuesta => {
  //       console.log(respuesta)
  //       console.log(respuesta.years.length)
  //       // this.cargando = false
  //       this.pago = respuesta
  //       if (respuesta.years.length !== 0) {
  //         this.pago.year_id = respuesta.years[0].year_id
  //       }
  //     })
  //   pagosService
  //     .traerConceptosDePago()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.conceptos = respuesta
  //     })
  //   pagosService
  //     .traerYearsDePago()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.years = respuesta
  //     })
  //   pagosService
  //     .traerFormasDePago()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.formasDePago = respuesta
  //     })
  //   pagosService
  //     .traerMonedas()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.monedas = respuesta
  //     })
  //   pagosService
  //     .traerPersonas()
  //     .then(respuesta => {
  //       // this.cargando = false
  //       this.personas = respuesta
  //       console.log('Personas: ', respuesta)
  //     })
  // },
  computed: {
    mostrarCampoYear: function () {
      // if (this.pago.concepto_id === 1 ) {
      //   return true
      // }
      // return false
      switch (this.pago.concepto_id) {
        case 1:
          return true
        case 2:
          return true
        case 3:
          return true
        default:
          return false
      }
    }
  }
}
</script>
