import { API } from './../constants'
import authService from './auth.js'

let movimientos = null

const movimientosService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerMovimientosPorServicioId (id) {
    const fetchResponse = await fetch(`${API}/movimientos-servicio/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    movimientos = respuesta.data
    return [...movimientos]
  }
}

export default movimientosService
