<template>
  <div class="pago_exitoso">
    <div class="contenedor-titulo">
      <h1>Pago Rechazado</h1>
      <div class="barra"></div>
    </div>
    <section class="contenedor-pago-exitoso-asociarse">
      <div class="contenedor-pago-exitoso-asociarse-img">
        <img src="@/assets/guruji.jpg" alt="BKS Iyengar">
      </div>
      <div class="pago_exitoso-form">
        <p class="pago-exitoso">El pago fue rechazado</p>
        <img src="@/assets/pago-rechazado.png" alt="Pago Rechazado">
        <!-- <p>Ya podes loguearte. Tenes que generar una contraseña</p>
        <router-link :to="`/recuperar-password`" class="boton_volver_login boton_generar_password">Generar Contraseña</router-link> -->
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'PagoRechazado',
  components: {
  },
  data: function () {
    return {
    }
  },
  methods: {
  },
  mounted: function () {
  }
}
</script>
